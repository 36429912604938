import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS, PLAN_TYPE_SUBSCRIPTION_OPTIONS, TYPE_SUBSCRIPTION_OPTIONS } from "helpers/constans";
import { formatNumberWithCommas } from 'helpers/format';
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "ui/components/buttons/ButtonPrimary";

const MyPage = () => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state: any) => state?.Login);
  const { myTicket } = useSelector((state: any) => state?.Voting);
  const navigate = useNavigate();

  const TYPE_SUBSCRIPTION_OPTIONS_LANG = TYPE_SUBSCRIPTION_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
  const PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG = PLAN_TYPE_SUBSCRIPTION_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
  const TAB_ACTIVE_MY_PAGE = localStorage.getItem('TAB_ACTIVE_MY_PAGE') || 'INFO_SUBSCRIPTION';
  const [isActive, setIsActive] = useState(TAB_ACTIVE_MY_PAGE); // INFO_ACCOUNT, INFO_SUBSCRIPTION

  useEffect(() => {
    document.title = t("My Page");
    return () => {
      localStorage.setItem('TAB_ACTIVE_MY_PAGE', 'INFO_SUBSCRIPTION');
    }
  }, [i18n?.language]);

  useEffect(() => {
    if (!user?.user?.id) {
      navigate('/');
    }
  }, [user?.user]);


  return (
    <>
      <div>
        <Row >
          <Col sm={0} md={3}>

          </Col>
          <Col sm={12} md={6}>
            <h3 className="text-start mb-0">{t('My Page')}</h3>
            <div className="d-flex mt-2 mb-3">
              <ButtonPrimary
                onClick={() => setIsActive('INFO_ACCOUNT')}
                variant={isActive === 'INFO_ACCOUNT' ? "contained" : "outlined"}
                text={t('Account Information')}
                className="btn-login-on-header"
                style={{
                  maxWidth: `${i18n?.language === 'en' ? 180 : 90}px`,
                }}
              />
              <ButtonPrimary
                onClick={() => setIsActive('INFO_SUBSCRIPTION')}
                variant={isActive === 'INFO_SUBSCRIPTION' ? "contained" : "outlined"}
                text={t('Membership')}
                className="btn-login-on-header ms-3"
                style={{
                  maxWidth: `${i18n?.language === 'en' ? 120 : 90}px`,
                }}
              />
            </div>
            <div>
              <div className="mb-5">
                <div className="card" style={{ minWidth: i18n?.language === 'en' ? '300px' : '250px', boxShadow: '0 4px 14px rgba(194, 199, 226, .2)' }}>
                  <div className="card-body">
                    <table id="accountInfo" summary="회원의 이름,이메일,비밀번호 병경, 가입일 정보" data-tab-content="" className="my-profile active w-100">
                      {isActive === 'INFO_ACCOUNT' ? (<tbody>
                        <tr>
                          <th className="th-bg-full px-2 ps-3 px-sm-4">{t('Name')}</th>
                          <td className="right px-2 px-sm-4">{user?.user?.name || '-'}</td>
                        </tr>
                        <tr>
                          <th className="th-bg-full px-2 ps-3 px-sm-4">{t('Email')}</th>
                          <td className="right px-2 px-sm-4">{user?.user?.email || '-'}</td>
                        </tr>
                        <tr>
                          <th className="th-bg-full px-2 ps-3 px-sm-4">{t('My Vote')}</th>
                          <td className="right px-2 px-sm-4"><strong className="me-1">{formatNumberWithCommas(myTicket?.ticket_amount || 0)}</strong>개</td>
                        </tr>
                        <tr>
                          <th className="th-bg-full px-2 ps-3 px-sm-4">{t('Registration Date')}</th>
                          <td className="right px-2 px-sm-4">{user?.user?.created_at || '-'}</td>
                        </tr>
                      </tbody>) : (
                        <tbody>
                          <tr>
                            <th className="th-bg-full px-2 ps-3 px-sm-4">{t('Membership')}</th>
                            <td className="w-100 right d-flex flex-column flex-sm-row align-items-start align-sm-items-center justify-content-between px-2 px-sm-4">
                              <span className={`text-${COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS[Number(user?.user?.user_subscription?.subscription?.plan_type)]}`}>
                                {TYPE_SUBSCRIPTION_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(user?.user?.user_subscription?.subscription?.type))?.label}
                                {` - `}
                                {PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(user?.user?.user_subscription?.subscription?.plan_type))?.label}{' '}
                                {!!user?.user?.user_subscription?.subscription?.plan_type ? t('Plan') : ''}
                              </span>
                              <Link
                                to={"/subscription-introduction"}
                                className="d-flex align-items-center vertical-align-middle  justify-content-end w-sm-100 fs-14 mt-2 mt-sm-0 text-decoration-underline"
                              >
                                <div>{t('Subscription')}</div>
                                <div>
                                  <ArrowForwardIosOutlinedIcon
                                    sx={{
                                      width: '16px',
                                      height: '16px',
                                    }}
                                  />
                                </div>
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <th className="th-bg-full px-2 ps-3 px-sm-4">{t('Start Date')}</th>
                            <td className="right px-2 px-sm-4">{user?.user?.user_subscription?.start_date || '-'}</td>
                          </tr>
                          <tr>
                            <th className="th-bg-full px-2 ps-3 px-sm-4">{t('Expiration Date')}</th>
                            <td className="right px-2 px-sm-4">{user?.user?.user_subscription?.end_date || '-'}</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={0} md={3}>

          </Col>
        </Row>
      </div>
    </>
  );
};

export default MyPage;