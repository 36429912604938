import masterApi from 'api/masterApi';
import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ButtonPrimary from 'ui/components/buttons/ButtonPrimary';
import RadioPrimary from 'ui/components/buttons/RadioPrimary';
import useToastNotifications from 'ui/components/Hooks/useToastNotifications';

const ModalTheme = ({ parentCategoryId, themeCategoryId, isShow = false, searchByTheme, onClose }: any) => {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const { notify } = useToastNotifications();

    const [isLoading, setIsLoading] = useState(false);

    const [themes, setThemes] = useState([]);

    const [valueTheme, setValueTheme] = useState(String(themeCategoryId) || '');

    const [searchTheme, setSearchTheme] = useState('');

    const onHide = () => {
        onClose && onClose();
    };

    const handleSearchByTheme = () => {
        searchByTheme && searchByTheme(valueTheme);
    }

    useEffect(() => {
        const getThemes = async (parent_id: string) => {
            try {
                setIsLoading((_prev) => true);
                const res: any = await masterApi.allCategoryMenu({ parent_id: parent_id, sort_by: 'name', order_by: 'ASC' });
                setIsLoading((_prev) => false);
                if (res?.code === 200) {
                    setThemes((_prev) => (res?.data || []))
                }
            } catch (error) {
                setIsLoading((_prev) => false)
                // console.log('error');
            }
        }
        if (parentCategoryId) {
            getThemes(parentCategoryId);
        }

    }, [parentCategoryId]);

    useEffect(() => {
        setValueTheme((_prev) => (String(themeCategoryId) || ''));
        return () => {
            setValueTheme((_prev) => '');
            setSearchTheme((_prev) => '');
        }
    }, [isShow, themeCategoryId]);

    return (
        <>
            <Modal id="modal-theme" className="fade bd-example-modal-lg" show={!!isShow} centered>
                <Modal.Header className="border-0 pt-4">
                    <Modal.Title>{t('Theme List')}</Modal.Title>
                    <svg width="24" height="24" viewBox="0 0 24 24" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onHide}>
                        <path d="M18 18L6 6M18 6L6 18" stroke="#47494E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Modal.Header>
                <Modal.Body className="py-0 pt-2">
                    <Container className="px-0">
                        <Row>
                            <Col sm="12">
                                <div className="card-body p-0">
                                    <div className="basic-form">
                                        <div className="mb-3 mb-0">
                                            <div className="form-label mb-1" style={{ fontSize: '16px' }}>
                                                <input type="text" className="form-control mb-3" placeholder={t('Theme Name')}
                                                    onChange={(e) => setSearchTheme((_prev) => e.target.value || '')}
                                                />
                                            </div>
                                            <div className="p-1 overflow-y-auto" style={{ height: '60dvh', maxHeight: '400px' }}>
                                                {themes?.map((theme: any) => (
                                                    (String(theme?.name).toUpperCase()).includes(String(searchTheme).toUpperCase()) ?
                                                        <div className="mb-1" key={theme?.id} >
                                                            <RadioPrimary
                                                                text={theme?.name || ''}
                                                                checked={String(theme?.id) === String(valueTheme)}
                                                                fontSize="14px"
                                                                onClick={() => setValueTheme((_prev) => String(theme?.id))}
                                                            />
                                                        </div>
                                                        :
                                                        <></>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="text-center border-0 pb-4 pt-0">
                    <ButtonPrimary
                        disabled={!valueTheme}
                        isLoading={isLoading}
                        onClick={handleSearchByTheme}
                        variant="contained"
                        text={t('Filter')}
                        className="py-3 m-0 fs-16"
                    />
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default React.memo(ModalTheme);