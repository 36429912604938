import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IVote, ITicket } from "api/types/_vote";
import { myTicket } from "./thunk";
export interface IState {
  myTicket: ResponseData<ITicket> | null,
  isMyTicketLoading: boolean,
  isMyTicketSuccess: boolean,

  votes: ResponseData<IVote[]> & PaginationResponse | null,
  isVoteLoading: boolean,
  isVoteSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  myTicket: null,
  isMyTicketLoading: false,
  isMyTicketSuccess: false,

  votes: null,
  isVoteLoading: false,
  isVoteSuccess: false,

  error: {},
};

const VoteSlice = createSlice({
  name: "Vote",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    //my ticket
    builder.addCase(myTicket.pending, (state: IState, action: any) => {
      state.isMyTicketLoading = true
    });
    builder.addCase(myTicket.fulfilled, (state: IState, action: any) => {
      state.myTicket = action.payload.data;
      state.isMyTicketSuccess = true;
      state.isMyTicketLoading = false;
    });
    builder.addCase(myTicket.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMyTicketSuccess = false;
      state.isMyTicketLoading = false;
    });
  },
});

export const { } = VoteSlice.actions;

export default VoteSlice.reducer;