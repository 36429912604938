//Include Both Helper File with needed methods
import authApi from 'api/authApi';
import { apiError, loginSuccess, logoutUserSuccess, reset_login_flag } from './reducer';
import { push } from 'helpers/navigation';
import { removeAccessToken, removeRefreshToken } from 'helpers/cookie';
import { myTicket as getOnMyTicket } from "store/voting/thunk";
import { LOGIN_TYPE } from 'helpers/constans';

export const loginUser = (user: any, history: any, href: string) => async (dispatch: any) => {
  try {
    let response = await authApi.login({
      email: user.email,
      password: user.password,
      // device_id: user.device_id,
      provider_type: LOGIN_TYPE.DEFAULT
    });
    if (response) {
      let finalLogin: any = response?.data;
      localStorage.setItem("authUser", JSON.stringify(finalLogin));
      dispatch(loginSuccess(finalLogin));
      dispatch(getOnMyTicket({}));
      history(href);
    }
  } catch (error) {
    dispatch(apiError(error));
    return error;
  }
};

export const loginSocial = (user: any, history: any, href: string) => async (dispatch: any) => {
  try {
    let response = await authApi.loginSocial({
      provider_token: user.token,
      provider_type: user.platform,
      type_token: user.type_token,
      // device_id: user.device_id,
      member_type: "regular"
    });
    if (response?.data) {
      let finallogin: any = response?.data
      localStorage.setItem("authUser", JSON.stringify(finallogin));
      dispatch(loginSuccess(finallogin));
      dispatch(getOnMyTicket({}));
      window.close();
      window.opener.postMessage({ type: 'LOGIN_SOCIAL_SUCCESS', message: 'Login success.' }, '*');
      // window.opener.location.reload();
    } else {
      window.close();
      window.opener.postMessage({ type: 'LOGIN_SOCIAL_ERROR', message: 'Login failed. Please try again.' }, '*');
    }
  } catch (errors: any) {
    const mess = typeof errors === 'string' ? errors : Object.entries(errors)?.map(([key, value]) => {
      return (value as any || []).join('');
    }).join('');
    dispatch(apiError(errors));
    window.close();
    window.opener.postMessage({ type: 'LOGIN_SOCIAL_ERROR', message: mess || 'Login failed. Please try again.' }, '*');
  }
};

export const loginUserWithSSO =
  (user: any, sso_callback: any) => async (dispatch: any) => {
    try {
      let response = await authApi.login({
        email: user.email,
        password: user.password,
        // device_id: user.device_id,
      });
      if (response) {
        let finallogin: any = response?.data;
        const {
          meta: {
            access_token: { token },
          },
        } = finallogin;
        localStorage.setItem("authUser", JSON.stringify(finallogin));
        dispatch(loginSuccess(finallogin));
        window.location.href = `${sso_callback}?sso_token=${token}`;
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };

export const signUpUser = (user: any, history: any, href: string) => async (dispatch: any) => {
  try {
    let response = await authApi.signUp({
      email: user.email,
      password: user.password,
      name: user.name,
      member_type: "regular"
    });
    if (response) {
      let finalLogin: any = response?.data;
      localStorage.setItem("authUser", JSON.stringify(finalLogin));
      dispatch(loginSuccess(finalLogin));
      dispatch(getOnMyTicket({}));
      history(href);
    }
  } catch (error) {
    dispatch(apiError(error));
    return error;
  }
};

export const logoutUser = () => async (dispatch: any) => {
  try {
    // push("/login");
    localStorage.removeItem("authUser");
    removeAccessToken();
    removeRefreshToken();
    // await authApi.logout();
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    // push("/login");
    localStorage.removeItem("authUser");
    removeAccessToken();
    removeRefreshToken();
    // dispatch(apiError(error));
  } finally {
    // push("/login");
    localStorage.removeItem("authUser");
    removeAccessToken();
    removeRefreshToken();
  }
};


export const resetLoginFlag = () => async (dispatch: any) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const checkAutoLogin = (dispatch: any, navigate: any) => {

  const detailUser = getLoggedInUserInfo();
  if (!detailUser) {
    // localStorage.removeItem('authUser');
    navigate('/login');
  } else {
    navigate('/');
    // return detailUser;
  }

}

const getLoggedInUserInfo = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};