import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useProfile } from "../components/Hooks/UserHooks";

import authApi from "api/authApi";
import { loginSuccess } from "store/auth/login/reducer";
import { useDispatch } from "react-redux";
import { myTicket as getOnMyTicket } from "store/voting/thunk";


const AuthProtected = (props: any) => {
  const { userProfile, loading } = useProfile();
  const dispatch: any = useDispatch();
  /*
    Navigate is un-auth access protected routes via url
    */

  // if (!userProfile) {
  //   return (
  //     <Navigate to={{ pathname: "/login" }} />
  //   );
  // }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const resProfile = async () => {
      try {
        if (!userProfile?.id) {
          return;
        }
        const res = await authApi.profile();
        if (res?.data) {
          const finalLogin = {
            ...res?.data,
          };
          localStorage.setItem("authUser", JSON.stringify(finalLogin));
          dispatch(loginSuccess(finalLogin));
          dispatch(getOnMyTicket({}));
        }
      } catch (error: any) {
        return error;
      }
    };
    resProfile();
    if (window?.opener) {
      window.opener.postMessage({ type: 'CLOSE_WINDOW_VERIFY_EMAIL', message: 'Close window verify email.' }, '*');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{props.children}</>;
};


export default AuthProtected;