import { lazy, Suspense } from 'react';
/// Components
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import Index from './ui/router/index';
// action

/// Style
import 'rsuite/dist/rsuite-no-reset.min.css';
import AuthProtected from 'ui/router/AuthProtected';
import "./assets/css/style.css";
import "./assets/css/custom.scss";
import Login from 'ui/pages/authentication/Login';
import SignUp from 'ui/pages/authentication/SignUp';
import KakaoCallBack from 'ui/pages/authentication/KakaoCallBack';
import NaverCallBack from 'ui/pages/authentication/NaverCallBack';
import GoogleCallBack from 'ui/pages/authentication/GoogleCallBack';
import useToastNotifications from 'ui/components/Hooks/useToastNotifications';
import VerifyEmail from 'ui/pages/authentication/VerifyEmail';
import Payment from 'ui/pages/payment/Payment';
import useConnectSocket from 'hooks/socket/useConnectSocket';

function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

function App(props: any) {
    // const _socket = useConnectSocket();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ToastContainerComponent } = useToastNotifications();
    return (<>
        <div className="dvh-100">
            <Suspense fallback={
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
            }
            >
                <Routes>
                    <Route>
                        <Route path='/login' element={<Login />} />
                        <Route path='/sign-up' element={<SignUp />} />
                        <Route path='/verify-email' element={<VerifyEmail />} />
                        <Route path='/payment/:type' element={<Payment />} />
                        <Route path='/kakaocallback' element={<KakaoCallBack />} />
                        <Route path='/navercallback' element={<NaverCallBack />} />
                        <Route path='/googlecallback' element={<GoogleCallBack />} />
                    </Route>
                    <Route>
                        <Route path='/*' element={
                            <AuthProtected>
                                <Index />
                            </AuthProtected>} />
                    </Route>
                </Routes>
            </Suspense>
            <ToastContainerComponent />
        </div>
    </>);
};

export default App; 