import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ArrowAnimation from './ArrowAnimation';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number | string) {
  return {
    id: `${index}`,
    'aria-controls': `${index}`,
  };
}

export default function BasicTabs({ tabs = [], tabActive = 0, handleChangeTab }: any) {

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    handleChangeTab && handleChangeTab(tabs[newValue]);
  };

  React.useEffect(() => {
    const titleTab = tabs.find((p: any) => String(p?.value) === String(tabActive))?.text || '';
    document.title = titleTab;
  }, [tabActive, tabs]);

  return (
    <Box sx={{ width: '100%' }} className="position-relative">
      <Box sx={{
        borderBottom: {
          xs: '0px solid var(--point)',
          lg: '1px solid var(--point)',
        },
        borderColor: 'var(--point)',
        borderBottomLeftRadius: '20px'
      }}>
        <Tabs
          value={tabs.findIndex((p: any) => String(p?.value) === String(tabActive))}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="basic tabs example"
          sx={{
            minHeight: '39px',
            borderRadius: {
              xs: '20px',
              lg: '20px',
            },
            border: {
              xs: '1px solid var(--point)',
              lg: '0px solid var(--point)',
            },
            '& .MuiTabs-flexContainer': {
              borderBottom: 'none',
              '& .MuiButtonBase-root:first-child': {
                borderTopLeftRadius: {
                  xs: '0px',
                  lg: '20px',
                },
                borderBottomLeftRadius: {
                  xs: '0px',
                  lg: '20px',
                }
              },
              '& .MuiButtonBase-root:last-child': {
                borderTopRightRadius: {
                  xs: '0px',
                  lg: '20px',
                }
              }
            },
            '& .MuiTab-root': {
              color: 'var(--point)',
              fontSize: '14px',
              fontWeight: 400,
              borderLeft: '1px solid var(--point)',
              borderRight: {
                xs: '0px solid var(--point)',
                lg: '0px solid var(--point)',
              },
              borderTop: {
                xs: '0px solid var(--point)',
                lg: '1px solid var(--point)',
              },
              borderBottom: {
                xs: '0px solid var(--point)',
                lg: '0px solid var(--point)',
              },
              padding: '0px 16px',
              height: '39px',
              minHeight: '39px',
              minWidth: '130px',
              marginBottom: '-1px',
              display: 'flex',
              alignItems: 'center',
              lineHeight: '39px',
            },
            '& .MuiTab-root:first-of-type': {
              borderLeft: {
                xs: '0px solid var(--point)',
                lg: '1px solid var(--point)',
              }
            },
            '& .MuiTab-root:last-of-type': {
              borderRight: {
                xs: '0px solid var(--point)',
                lg: '1px solid var(--point)',
              },
            },
            '& .MuiTab-root.Mui-selected': {
              color: 'white',
              fontWeight: 600,
              backgroundColor: 'var(--point)',
              borderBottom: '1px solid transparent',
            },
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          }}
        >
          {
            tabs?.map((tab: any, ind: number) => (<Tab key={ind} label={tab?.text} {...a11yProps(tab?.id)} />))
          }
          {/* <Tab label="Item One" {...a11yProps(0)} />
          <Tab label="Item Two" {...a11yProps(1)} />
          <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      {/* <CustomTabPanel value={value} index={0}>
        Item One
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel> */}
      <ArrowAnimation />
    </Box>
  );
}
