import React, { useContext } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { IMAGES } from "ui/constant/theme";
import { toggleMenuLayout } from "store/layouts/thunk";

const NavHader = () => {
  const { openMenuToggle } = useContext(
    ThemeContext
  );
  const dispatch = useDispatch();
  const { sideMenu } = useSelector(state => state.Layout);
  const isDark = document.body.getAttribute('data-theme-version') === 'dark';
  const handleToggle = () => {
    dispatch(toggleMenuLayout());
  };
  return (
    <div className="nav-header">
      <div
        className="nav-control justify-content-start justify-content-lg-center"
        onClick={(e) => {
          e.stopPropagation();
          handleToggle();
          openMenuToggle();
        }}
      >
        <div className={`hamburger  ${sideMenu ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
      <Link to="/" className="brand-logo">
        {isDark ? <svg width="120" height="34" viewBox="0 0 160 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.000171661 13.4146H32.3734V31.4843C32.3734 33.8337 31.9148 36.1608 31.0229 38.337C29.0394 43.1768 25.0545 46.9359 20.0842 48.6559L16.5189 49.8897C16.1007 50.0344 15.6458 50.0367 15.2262 49.8961L11.8628 48.7696C6.5344 46.9848 2.40006 42.7588 0.758922 37.4195C0.255644 35.7822 -0.000171661 34.0797 -0.000171661 32.3679V13.4146ZM3.98426 17.3697V32.3679C3.98426 33.6886 4.18163 35.002 4.56991 36.2653C5.83607 40.3846 9.02577 43.645 13.1367 45.022L15.8526 45.9317L18.7726 44.9212C22.6619 43.5753 25.7801 40.6338 27.3322 36.8466C28.0301 35.1437 28.3889 33.3227 28.3889 31.4843V17.3697H3.98426Z" fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.71566 21.3413H17.9532C20.9362 21.3413 23.3544 23.76 23.3544 26.7436C23.3544 29.7272 20.9362 32.1459 17.9532 32.1459H8.71566V21.3413ZM12.7539 25.3804V28.1068H17.9532C18.7059 28.1068 19.3161 27.4965 19.3161 26.7436C19.3161 25.9907 18.7059 25.3804 17.9532 25.3804H12.7539Z" fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.71566 28.1069H14.5712C19.5893 28.1069 23.6573 32.1758 23.6573 37.1949H19.619C19.619 34.4065 17.359 32.146 14.5712 32.146H12.7539V37.1949H8.71566V28.1069Z" fill="white" />
          <path d="M23.2532 3.29944V6.05845H18.7867L19.4597 2.84434L16.1863 0L13.0352 2.84434L13.7082 6.08689H9.24164V3.32788L3.73489 0.99552L4.71387 9.7561H16.2474H27.781L28.6376 0.967077L23.2532 3.29944Z" fill="#F6B40C" />
          <path d="M154.072 22.9966H160L153.271 37.9792C151.108 42.7992 149.151 44.5123 145.002 44.5123H143.105V39.8956H144.646C146.75 39.8956 147.373 39.3439 148.114 37.3985L141.504 22.9966H147.491L150.9 31.6493L154.072 22.9966Z" fill="white" />
          <path d="M141.072 23.2578V27.497H137.486V38.6759H131.973V27.497H129.335L129.305 23.2578H132.032V21.7769C132.032 18.612 133.81 16.7827 137.189 16.7827H141.043V20.9349H138.612C137.782 20.9349 137.308 21.3995 137.308 22.2125V23.2578H141.072Z" fill="white" />
          <path d="M121.428 21.0511V16.4634H126.882V21.0511H121.428ZM121.399 38.676V22.9965H126.912V38.676H121.399Z" fill="white" />
          <path d="M119.985 38.676H113.464L109.463 32.0558V38.676H103.949V17.3345H109.463V29.1522L113.286 22.9965H119.481L114.472 30.4878L119.985 38.676Z" fill="white" />
          <path d="M94.2712 22.561C97.9763 22.561 100.436 25.1453 100.436 29.1232V38.6761H94.9233V29.8781C94.9233 28.0489 94.2119 27.1487 92.8188 27.1487C91.0996 27.1487 90.1215 28.3102 90.1215 30.3137V38.6761H84.6083V22.9966H88.4912L89.2322 24.6226C90.4179 23.316 92.1963 22.561 94.2712 22.561Z" fill="white" />
          <path d="M77.0088 22.9963H81.1289V38.6758H76.8606L76.3863 37.5725C75.0821 38.6178 73.4222 39.1985 71.5549 39.1985C66.6641 39.1985 63.1665 35.7141 63.1665 30.8361C63.1665 25.987 66.6641 22.5317 71.5549 22.5317C73.4519 22.5317 75.1414 23.1415 76.4456 24.2158L77.0088 22.9963ZM72.2959 34.4365C74.3708 34.4365 75.8824 32.8976 75.8824 30.8361C75.8824 28.8035 74.3708 27.2937 72.2959 27.2937C70.221 27.2937 68.7094 28.8035 68.7094 30.8361C68.7094 32.8976 70.221 34.4365 72.2959 34.4365Z" fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M61.2836 25.4709C61.2836 21.7164 58.1766 18.6729 54.3439 18.6729H42.3344V27.4295V32.269V38.998H47.2745V32.2689H49.8744C53.6682 32.2689 56.7437 35.2816 56.7437 38.998H61.6838C61.6838 35.89 60.4327 33.0683 58.3968 30.9898C60.1453 29.7554 61.2836 27.7434 61.2836 25.4709ZM56.3434 25.471C56.3434 26.5528 55.4482 27.4297 54.3439 27.4297H50.8092H47.2745V23.5123H54.3439C55.4482 23.5123 56.3434 24.3892 56.3434 25.471Z" fill="white" />
        </svg> :
          <>
            <svg width="120" height="34" viewBox="0 0 160 50" className="d-none d-sm-block" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.4146H31.1595V31.4844C31.1595 33.8338 30.7181 36.1609 29.8597 38.3371C27.9506 43.1769 24.1152 46.936 19.3312 48.656L15.8996 49.8898C15.4971 50.0345 15.0593 50.0368 14.6553 49.8962L11.4181 48.7696C6.28952 46.9849 2.31022 42.7589 0.730628 37.4196C0.246222 35.7822 0 34.0798 0 32.3679V13.4146ZM3.83502 17.3698V32.3679C3.83502 33.6887 4.02498 35.0021 4.39871 36.2654C5.61739 40.3847 8.68747 43.6451 12.6443 45.0221L15.2583 45.9317L18.0688 44.9213C21.8123 43.5754 24.8135 40.6338 26.3074 36.8466C26.9791 35.1438 27.3245 33.3228 27.3245 31.4844V17.3698H3.83502Z" fill="#1A1A1C" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.38818 21.3415H17.2793C20.1505 21.3415 22.478 23.7602 22.478 26.7438C22.478 29.7274 20.1505 32.1461 17.2793 32.1461H8.38818V21.3415ZM12.275 25.3806V28.107H17.2793C18.0038 28.107 18.5911 27.4967 18.5911 26.7438C18.5911 25.9909 18.0038 25.3806 17.2793 25.3806H12.275Z" fill="#1A1A1C" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.38818 28.1071H14.0241C18.854 28.1071 22.7695 32.176 22.7695 37.1951H18.8826C18.8826 34.4067 16.7074 32.1462 14.0241 32.1462H12.275V37.1951H8.38818V28.1071Z" fill="#1A1A1C" />
              <path d="M22.3811 3.29944V6.05845H18.0821L18.7299 2.84434L15.5792 0L12.5462 2.84434L13.1941 6.08689H8.89497V3.32788L3.59473 0.99552L4.53699 9.7561H15.6381H26.7391L27.5636 0.967077L22.3811 3.29944Z" fill="#F6B40C" />
              <path d="M148.295 22.9965H154.001L147.524 37.9791C145.442 42.7991 143.559 44.5122 139.565 44.5122H137.739V39.8955H139.222C141.248 39.8955 141.847 39.3438 142.56 37.3984L136.198 22.9965H141.961L145.242 31.6492L148.295 22.9965Z" fill="#1A1A1C" />
              <path d="M135.782 23.258V27.4973H132.33V38.6762H127.024V27.4973H124.485L124.456 23.258H127.081V21.7771C127.081 18.6122 128.793 16.7829 132.045 16.7829H135.754V20.9351H133.414C132.615 20.9351 132.159 21.3997 132.159 22.2127V23.258H135.782Z" fill="#1A1A1C" />
              <path d="M116.876 21.0511V16.4634H122.125V21.0511H116.876ZM116.847 38.6761V22.9966H122.154V38.6761H116.847Z" fill="#1A1A1C" />
              <path d="M115.487 38.6762H109.21L105.359 32.056V38.6762H100.052V17.3347H105.359V29.1524L109.039 22.9967H115.002L110.18 30.488L115.487 38.6762Z" fill="#1A1A1C" />
              <path d="M90.7366 22.5609C94.3027 22.5609 96.6707 25.1451 96.6707 29.123V38.6759H91.3642V29.878C91.3642 28.0487 90.6795 27.1486 89.3387 27.1486C87.684 27.1486 86.7425 28.31 86.7425 30.3135V38.6759H81.436V22.9964H85.1734L85.8866 24.6224C87.0278 23.3158 88.7395 22.5609 90.7366 22.5609Z" fill="#1A1A1C" />
              <path d="M74.1215 22.9965H78.0871V38.676H73.9789L73.5224 37.5726C72.2671 38.6179 70.6695 39.1987 68.8721 39.1987C64.1648 39.1987 60.7983 35.7143 60.7983 30.8363C60.7983 25.9872 64.1648 22.5319 68.8721 22.5319C70.698 22.5319 72.3242 23.1417 73.5795 24.216L74.1215 22.9965ZM69.5854 34.4367C71.5824 34.4367 73.0374 32.8978 73.0374 30.8363C73.0374 28.8037 71.5824 27.2938 69.5854 27.2938C67.5883 27.2938 66.1333 28.8037 66.1333 30.8363C66.1333 32.8978 67.5883 34.4367 69.5854 34.4367Z" fill="#1A1A1C" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M58.9862 25.4712C58.9862 21.7167 55.9957 18.6731 52.3068 18.6731H40.7476V27.4298V32.2693V38.9983H45.5024V32.2692H48.0048C51.6564 32.2692 54.6165 35.2819 54.6165 38.9983H59.3714C59.3714 35.8903 58.1672 33.0686 56.2077 30.9901C57.8906 29.7557 58.9862 27.7437 58.9862 25.4712ZM54.2309 25.4712C54.2309 26.553 53.3693 27.4299 52.3064 27.4299H48.9042H45.502V23.5125H52.3064C53.3693 23.5125 54.2309 24.3894 54.2309 25.4712Z" fill="#1A1A1C" />
            </svg>
            <svg width="34" height="32" viewBox="0 0 32 50" className="d-block d-sm-none" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.4147H31.1595V31.4844C31.1595 33.8338 30.7181 36.1609 29.8597 38.3371C27.9506 43.177 24.1152 46.9361 19.3312 48.656L15.8996 49.8898C15.4971 50.0345 15.0593 50.0368 14.6553 49.8962L11.4181 48.7697C6.28952 46.9849 2.31022 42.7589 0.730628 37.4197C0.246222 35.7823 0 34.0799 0 32.368V13.4147ZM3.83502 17.3698V32.368C3.83502 33.6887 4.02498 35.0022 4.39871 36.2654C5.61739 40.3847 8.68747 43.6452 12.6443 45.0221L15.2583 45.9318L18.0688 44.9213C21.8123 43.5754 24.8135 40.6339 26.3074 36.8467C26.9791 35.1438 27.3245 33.3228 27.3245 31.4844V17.3698H3.83502Z" fill="#1A1A1C" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.38818 21.3414H17.2793C20.1505 21.3414 22.478 23.7601 22.478 26.7437C22.478 29.7274 20.1505 32.1461 17.2793 32.1461H8.38818V21.3414ZM12.275 25.3805V28.1069H17.2793C18.0038 28.1069 18.5911 27.4966 18.5911 26.7437C18.5911 25.9909 18.0038 25.3805 17.2793 25.3805H12.275Z" fill="#1A1A1C" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.38818 28.1071H14.0241C18.854 28.1071 22.7695 32.1759 22.7695 37.1951H18.8826C18.8826 34.4066 16.7074 32.1462 14.0241 32.1462H12.275V37.1951H8.38818V28.1071Z" fill="#1A1A1C" />
              <path d="M22.3811 3.29944V6.05845H18.0821L18.7299 2.84434L15.5792 0L12.5462 2.84434L13.1941 6.08689H8.89497V3.32788L3.59473 0.99552L4.53699 9.7561H15.6381H26.7391L27.5636 0.967077L22.3811 3.29944Z" fill="#F6B40C" />
            </svg>

          </>
        }
      </Link>
    </div>
  );
};

export default NavHader;
