import React from "react";
import Row from "./Row";

const Body = ({ isCollapse = true, columnTableRanking = [], data = [], frequencyType, intervalTimeSearch, categoryType, categoryGroupType, typeShowParticipation = false, handleAction, handleShowMore, onSetKeywordInfo, onSetKeywordDetail, widthChart }: any) => {

    const handleActionRow = (row: any) => {
        handleAction && handleAction(row);
    }
    const showMore = (keywords: string[] = [], level_sub: number = 1) => {
        handleShowMore && handleShowMore(keywords, level_sub);
    }
    const onSetDetail = (detail: any) => {
        onSetKeywordDetail && onSetKeywordDetail(detail);
    }
    const onKeywordInfo = (detail: any) => {
        onSetKeywordInfo && onSetKeywordInfo(detail);
    };

    return (
        <>
            <tbody>
                {data?.map((item: any, ind: number) => (
                    <Row stt={ind + 1} isCollapse={isCollapse} columnTableRanking={columnTableRanking} data={item} widthChart={widthChart} frequencyType={frequencyType} intervalTimeSearch={intervalTimeSearch} categoryType={categoryType} categoryGroupType={categoryGroupType} key={ind} typeShowParticipation={typeShowParticipation} handleAction={handleActionRow} handleShowMore={showMore} handleSetDetail={onSetDetail} handleKeywordInfo={onKeywordInfo} />
                ))}
            </tbody>
        </>
    );
};

export default React.memo(Body);
