import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const CustomizedCheckbox = styled(Checkbox)({
  fontSize: '0.813rem',
  padding: '5px',
  fontFamily: [
    'NanumSquare',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
});

export default function CheckBoxPrimary({ text, isLoading, fontSize = '', ...props }: any) {
  return (
    <FormControlLabel
      {...props}
      sx={{
        marginRight: '5px',
        '& .MuiFormControlLabel-label': {
          fontSize: fontSize ? fontSize : '0.813rem',
          fontFamily: [
            'NanumSquare',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
        },
      }}
      control={
        <CustomizedCheckbox
          sx={{
            color: 'rgba(0, 0, 0, .54)',
            '&.Mui-checked': {
              color: 'var(--point)',
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.26)'
            }
          }}
        />
      }
      label={text}
    />
  );
}
