import { useEffect, useRef, useState } from "react";
// import styles
import { COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS, PLAN_TYPE_SUBSCRIPTION_OPTIONS, PRODUCT_TYPE, SUBSCRIPTION_PERIOD_TYPE, TYPE_MESSAGES, TYPE_SUBSCRIPTION_OPTIONS } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import { allSubscription as onGetAllSubscription } from 'store/thunks';
import { myTicket as getOnMyTicket, postPaymentProcess } from "store/voting/thunk";
import ButtonPrimary from "ui/components/buttons/ButtonPrimary";
import RadioPrimary from "ui/components/buttons/RadioPrimary";
import useToastNotifications from "ui/components/Hooks/useToastNotifications";
import PaymentNotification from "ui/pages/payment/components/PaymentNotification";
import authApi from "api/authApi";
import { loginSuccess } from "store/auth/login/reducer";
import CustomSkeleton from "ui/components/loadings/CustomSkeleton";

export const SUBSCRIPTION_DEFAULT: any = {
  "1": [
    {
      "id": 1,
      "plan_type": 1,
      "type": 1,
      "monthly_price": 0,
      "yearly_price": 0,
      "number_of_accounts": 1,
      "top_limit": 20,
      "user_stats_flg": 0,
      "realtime_price_flg": 0,
      "stock_index_flg": 0,
      "comment_analysis_flg": 0,
      "export_flg": 0,
      "period_month": 1,
      "sort_no": 1,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:29:22",
      "updated_at": "2024-08-22 16:19:18"
    },
    {
      "id": 2,
      "plan_type": 2,
      "type": 1,
      "monthly_price": 500000,
      "yearly_price": 4800000,
      "number_of_accounts": 1,
      "top_limit": 50,
      "user_stats_flg": 1,
      "realtime_price_flg": 0,
      "stock_index_flg": 0,
      "comment_analysis_flg": 0,
      "export_flg": 0,
      "period_month": 6,
      "sort_no": 2,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:29:55",
      "updated_at": "2024-08-22 16:19:35"
    },
    {
      "id": 3,
      "plan_type": 3,
      "type": 1,
      "monthly_price": 1000000,
      "yearly_price": 9600000,
      "number_of_accounts": 3,
      "top_limit": 100,
      "user_stats_flg": 1,
      "realtime_price_flg": 1,
      "stock_index_flg": 1,
      "comment_analysis_flg": 1,
      "export_flg": 1,
      "period_month": 12,
      "sort_no": 3,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:30:49",
      "updated_at": "2024-08-22 16:19:48"
    },
    {
      "id": 4,
      "plan_type": 4,
      "type": 1,
      "monthly_price": 2000000000,
      "yearly_price": 19200000,
      "number_of_accounts": 10,
      "top_limit": 100,
      "user_stats_flg": 0,
      "realtime_price_flg": 0,
      "stock_index_flg": 1,
      "comment_analysis_flg": 1,
      "export_flg": 1,
      "period_month": 24,
      "sort_no": 4,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:31:32",
      "updated_at": "2024-08-22 16:20:03"
    },
    {
      "id": 5,
      "plan_type": 5,
      "type": 1,
      "monthly_price": -1,
      "yearly_price": -1,
      "number_of_accounts": -1,
      "top_limit": -1,
      "user_stats_flg": 1,
      "realtime_price_flg": 1,
      "stock_index_flg": 1,
      "comment_analysis_flg": 1,
      "export_flg": 1,
      "period_month": 36,
      "sort_no": 5,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:32:00",
      "updated_at": "2024-07-09 19:32:00"
    }
  ],
  "2": [
    {
      "id": 6,
      "plan_type": 1,
      "type": 2,
      "monthly_price": 0,
      "yearly_price": 0,
      "number_of_accounts": 1,
      "top_limit": 20,
      "user_stats_flg": 0,
      "realtime_price_flg": 0,
      "stock_index_flg": 0,
      "comment_analysis_flg": 0,
      "export_flg": 0,
      "period_month": 1,
      "sort_no": 1,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:32:22",
      "updated_at": "2024-08-22 16:17:25"
    },
    {
      "id": 7,
      "plan_type": 2,
      "type": 2,
      "monthly_price": 300000,
      "yearly_price": 4800000,
      "number_of_accounts": 1,
      "top_limit": 50,
      "user_stats_flg": 1,
      "realtime_price_flg": 1,
      "stock_index_flg": 1,
      "comment_analysis_flg": 1,
      "export_flg": 0,
      "period_month": 6,
      "sort_no": 7,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:32:59",
      "updated_at": "2024-07-09 19:32:59"
    },
    {
      "id": 8,
      "plan_type": 3,
      "type": 2,
      "monthly_price": 600000,
      "yearly_price": 9600000,
      "number_of_accounts": 3,
      "top_limit": 100,
      "user_stats_flg": 1,
      "realtime_price_flg": 1,
      "stock_index_flg": 1,
      "comment_analysis_flg": 1,
      "export_flg": 1,
      "period_month": 12,
      "sort_no": 8,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:33:40",
      "updated_at": "2024-07-09 19:33:40"
    },
    {
      "id": 9,
      "plan_type": 4,
      "type": 2,
      "monthly_price": 1200000,
      "yearly_price": 19200000,
      "number_of_accounts": 10,
      "top_limit": 100,
      "user_stats_flg": 1,
      "realtime_price_flg": 1,
      "stock_index_flg": 1,
      "comment_analysis_flg": 1,
      "export_flg": 1,
      "period_month": 24,
      "sort_no": 9,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:34:10",
      "updated_at": "2024-07-09 19:34:10"
    },
    {
      "id": 10,
      "plan_type": 5,
      "type": 2,
      "monthly_price": -1,
      "yearly_price": -1,
      "number_of_accounts": -1,
      "top_limit": -1,
      "user_stats_flg": 1,
      "realtime_price_flg": 1,
      "stock_index_flg": 0,
      "comment_analysis_flg": 1,
      "export_flg": 1,
      "period_month": 36,
      "sort_no": 10,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:34:31",
      "updated_at": "2024-07-09 19:34:31"
    }
  ],
  "3": [
    {
      "id": 11,
      "plan_type": 1,
      "type": 3,
      "monthly_price": 0,
      "yearly_price": 0,
      "number_of_accounts": 1,
      "top_limit": 20,
      "user_stats_flg": 0,
      "realtime_price_flg": 0,
      "stock_index_flg": 0,
      "comment_analysis_flg": 0,
      "export_flg": 0,
      "period_month": 1,
      "sort_no": 11,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:34:53",
      "updated_at": "2024-07-09 19:34:53"
    },
    {
      "id": 12,
      "plan_type": 2,
      "type": 3,
      "monthly_price": 500000,
      "yearly_price": 2888000,
      "number_of_accounts": 1,
      "top_limit": 50,
      "user_stats_flg": 1,
      "realtime_price_flg": 1,
      "stock_index_flg": 1,
      "comment_analysis_flg": 0,
      "export_flg": 0,
      "period_month": 6,
      "sort_no": 12,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:35:47",
      "updated_at": "2024-07-09 19:35:47"
    },
    {
      "id": 13,
      "plan_type": 3,
      "type": 3,
      "monthly_price": 1000000,
      "yearly_price": 5760000,
      "number_of_accounts": 3,
      "top_limit": 100,
      "user_stats_flg": 1,
      "realtime_price_flg": 1,
      "stock_index_flg": 1,
      "comment_analysis_flg": 1,
      "export_flg": 1,
      "period_month": 12,
      "sort_no": 13,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:36:25",
      "updated_at": "2024-07-09 19:36:25"
    },
    {
      "id": 14,
      "plan_type": 4,
      "type": 3,
      "monthly_price": 2000000,
      "yearly_price": 11520000,
      "number_of_accounts": 10,
      "top_limit": 100,
      "user_stats_flg": 1,
      "realtime_price_flg": 1,
      "stock_index_flg": 1,
      "comment_analysis_flg": 1,
      "export_flg": 1,
      "period_month": 24,
      "sort_no": 14,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:37:04",
      "updated_at": "2024-07-09 19:37:04"
    },
    {
      "id": 15,
      "plan_type": 5,
      "type": 3,
      "monthly_price": -1,
      "yearly_price": -1,
      "number_of_accounts": -1,
      "top_limit": -1,
      "user_stats_flg": 1,
      "realtime_price_flg": 1,
      "stock_index_flg": 1,
      "comment_analysis_flg": 1,
      "export_flg": 1,
      "period_month": 36,
      "sort_no": 15,
      "is_active": 1,
      "is_deleted": 0,
      "is_loading": true,
      "created_at": "2024-07-09 19:37:25",
      "updated_at": "2024-07-09 19:37:25"
    }
  ]
};

const SubscriptionIntroduction = () => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state: any) => state?.Login);
  const { myTicket } = useSelector((state: any) => state?.Voting);
  const { notify } = useToastNotifications();
  const navigate = useNavigate();
  const paymentCurrentRef = useRef('');

  const TYPE_SUBSCRIPTION_OPTIONS_LANG = TYPE_SUBSCRIPTION_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
  const PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG = PLAN_TYPE_SUBSCRIPTION_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Master;

  const MasterProperties = createSelector(
    selectLayoutState,
    (state) => ({
      subscriptions: state.subscriptions,
      isSubscriptionSuccess: state.isSubscriptionSuccess,
      isSubscriptionLoading: state.isSubscriptionLoading,
      error: state.error,
    })
  );

  const { subscriptions, isSubscriptionLoading } = useSelector(MasterProperties);

  const [chargeInfo, setChargeInfo] = useState<any | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [confirmLogin, setConfirmLogin] = useState(false);

  useEffect(() => {
    dispatch(onGetAllSubscription({}));
  }, []);

  const handleSetChargeInfo = (info: any) => {
    if (!user?.user?.id) {
      setConfirmLogin(true);
      return;
    }
    if (String(user?.user?.user_subscription?.subscription?.plan_type) === String(info?.plan_type) && String(user?.user?.user_subscription?.subscription?.type) === String(info?.type)) {
      notify('info', `${t('This is your current subscription plan!')}`);
      return;
    }
    if (Number(info?.monthly_price) < 0) {
      notify('warning', `${t('This feature is currently under preparation!')}`);
      return;
    }
    setChargeInfo((_prev: any) => ({ ...info, subscription_period_type: SUBSCRIPTION_PERIOD_TYPE.MONTHLY }));
  }

  const handleSubscription = async () => {
    try {
      if (!user?.user?.id) {
        setConfirmLogin(true);
        return;
      }
      if (isLoading) {
        return;
      }
      setIsLoading((_prev) => true);
      const { protocol, hostname } = window.location;
      const domain = `${protocol}//${hostname}`;
      const data = {
        product_type: PRODUCT_TYPE.SUBSCRIPTION,
        product_id: chargeInfo?.id,
        subscription_period_type: chargeInfo.subscription_period_type,
        useragent: window.innerWidth < 600 ? 'WM' : 'WP', // WP | WM
        return_url: `${domain}/payment/success`,
        cancel_url: `${domain}/payment/error`
      };
      const response: any = await postPaymentProcess(data);
      setIsLoading((_prev) => false);
      if (response?.code === 200) {
        paymentCurrentRef.current = response?.data?.payment_id;
        const win = window.open('about:blank', '_blank');
        win?.document
          ?.write(`<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml" >
            <head>
            <meta http-equiv="Content-Type" content="text/html; charset=euc-kr">
            <link href="./css/style.css" type="text/css" rel="stylesheet"  media="all" />
            <title>*** �ٳ� �ſ�ī�� ���� ***</title>
            </head>
            <body>
                <form name="form" ACTION=${response?.data?.start_url} METHOD="POST" >
                <input TYPE="HIDDEN" NAME="STARTPARAMS" VALUE="${response?.data?.start_params}">
                </form>
                <script>
                    document.form.submit();
                </script>
            </body>`);
      } else {
        setIsLoading((_prev) => false);
        notify('error', `${response}`);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      notify('error', `${error?.message || ""}`);
      return error;
    }
  };

  const handleGetTicket = async () => {
    try {
      const res = await authApi.profile();
      if (res?.data) {
        const finalLogin = {
          ...res?.data,
        };
        localStorage.setItem("authUser", JSON.stringify(finalLogin));
        dispatch(loginSuccess(finalLogin));
      }
      dispatch(getOnMyTicket({}));

    } catch (error) {
      return error;
    }
  };

  const handleCloseChargeInfo = () => {
    if (!isLoading) {
      setChargeInfo((_prev: any) => null);
    }
  }

  const goToLogin = () => {
    const currentUrl = window.location.pathname + window.location.search; // Get the current URL
    localStorage.setItem('referralUrl', currentUrl);
    navigate('/login');
  };

  useEffect(() => {
    const channel = new BroadcastChannel('my_channel_payment');
    channel.onmessage = (event) => {
      const payment_id = paymentCurrentRef.current;
      if (event.data.type === TYPE_MESSAGES.PAYMENT_SUCCESS) {
        setIsLoading((prev) => false);
        handleGetTicket();
        if (String(payment_id) === String(event.data.payment_id)) {
          setChargeInfo((_prev: any) => ({ status: 'SUCCESS' }));
        }
        return;
      }
      if ([TYPE_MESSAGES.PAYMENT_CANCEL, TYPE_MESSAGES.PAYMENT_ERROR]?.includes(event.data.type) && String(payment_id) === String(event.data.payment_id)) {
        setIsLoading((prev) => false);
        setChargeInfo((_prev: any) => ({ status: 'ERROR' }));
        return;
      }
    };
    return () => {
      channel.close();
    };
  }, []);

  useEffect(() => {
    document.title = t("Subscription Introduction");
  }, [i18n?.language]);

  return (
    <>
      <div>
        {Object.entries(subscriptions || SUBSCRIPTION_DEFAULT)?.map(([key, value]) => (
          <div className="mb-5" translate="yes">
            <h3 className="text-start pb-2 mb-0" style={{ borderBottom: '2px solid var(--point)' }}>
              {TYPE_SUBSCRIPTION_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(key))?.label}
            </h3>
            <div className="row flex-nowrap overflow-x-auto pb-0 pt-4">
              {(value as any || [])?.map((sub: any) => (
                <div className="col" key={sub?.id}>
                  <div className="card subscription-card" style={{ minWidth: i18n?.language === 'en' ? '300px' : '250px', boxShadow: '0 4px 14px rgba(194, 199, 226, .2)' }}>
                    <div className="card-header">
                      <h4 className={`mb-0 text-${COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS[sub?.plan_type]}`}>
                        {PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(sub?.plan_type))?.label}
                      </h4>
                    </div>
                    <div className="card-body" >
                      <CustomSkeleton isLoading={!!sub?.is_loading} height={60}>
                        <div
                          style={{ height: 80 }}
                          className="d-flex align-items-center justify-content-center flex-column"
                        >
                          <div className="text-center">
                            {Number(sub?.monthly_price) <= 0 ? <span className="fw-bold text-dark fs-3">{Number(sub?.monthly_price) < 0 ? t('Negotiable') : t('Free')}</span> : <> <span className="fw-bold text-dark fs-3">{formatNumberWithCommas(sub?.monthly_price)}</span>{" "}{t("won/month")}</>}
                          </div>
                          <div className="text-center">
                            {Number(sub?.yearly_price) <= 0 ? <span className="fw-bold text-dark fs-5">{Number(sub?.yearly_price) < 0 ? t('Negotiable') : t('-')}</span> : <> <span className="fw-bold text-dark fs-5">{formatNumberWithCommas(sub?.yearly_price)}</span>{" "}{t("won/year")}</>}
                          </div>
                        </div>
                      </CustomSkeleton>
                      <div
                        className="mt-3"
                        style={{
                          backgroundColor: "#f7f9fb",
                          borderRadius: 8,
                          padding: "20px 10px",
                          display: "flex",
                          flexDirection: "column",
                          gap: 6,
                        }}
                      >
                        <CustomSkeleton isLoading={!!sub?.is_loading} height={20}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="fw-normal">{t('Account')}</div>
                            <div className="fw-bold text-end">
                              {Number(sub?.number_of_accounts) < 0 ? <span>{t('Negotiable')}</span> : <span>{formatNumberWithCommas(sub?.number_of_accounts)}</span>}
                            </div>
                          </div>
                        </CustomSkeleton>
                        <CustomSkeleton isLoading={!!sub?.is_loading} height={20}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="fw-normal">{t('Ranking')}</div>
                            <div className="fw-bold text-end">
                              {Number(sub?.top_limit) < 0 ? <span>{t('Unlimited')}</span> : <span>{formatNumberWithCommas(sub?.top_limit)}<sup>th</sup></span>}
                            </div>
                          </div>
                        </CustomSkeleton>
                        <CustomSkeleton isLoading={!!sub?.is_loading} height={20}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="fw-normal">{t('Gender/Age Analysis')}</div>
                            <div className="fw-bold text-end">{!!sub?.user_stats_flg ? t('Possible') : t('Not possible')}</div>
                          </div>
                        </CustomSkeleton>
                        <CustomSkeleton isLoading={!!sub?.is_loading} height={20}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="fw-normal" style={{ float: "left" }}>
                              {t('Report export')} <br /> (CSV, PDF)
                            </div>
                            <div className="fw-bold text-end">{!!sub?.export_flg ? t('Possible') : t('Not possible')}</div>
                          </div>
                        </CustomSkeleton>
                        <CustomSkeleton isLoading={!!sub?.is_loading} height={20}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="fw-normal">{t('Analysis Period')}</div>
                            <div className="fw-bold text-end">{sub?.period_month} {t('months')}</div>
                          </div>
                        </CustomSkeleton>
                      </div>
                      <button
                        onClick={(e: any) => { e?.preventDefault(); handleSetChargeInfo(sub); }}
                        className={`btn btn${(String(user?.user?.user_subscription?.subscription?.plan_type) === String(sub?.plan_type) && String(user?.user?.user_subscription?.subscription?.type) === String(sub?.type)) ? '-outline' : ''}-${COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS[sub?.plan_type]}  w-100 mt-4 py-3 fs-16 `}
                        disabled={(Number(sub?.monthly_price) === 0) || (String(user?.user?.user_subscription?.subscription?.plan_type) === String(sub?.plan_type) && String(user?.user?.user_subscription?.subscription?.type) === String(sub?.type))}
                      >
                        {(String(user?.user?.user_subscription?.subscription?.plan_type) === String(sub?.plan_type) && String(user?.user?.user_subscription?.subscription?.type) === String(sub?.type)) ? t('Subscribed') : ((Number(sub?.monthly_price) < 0) ? t('Contact us') : t("Subscribe"))}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <Modal className="fade bd-example-modal-lg" show={!!chargeInfo} centered onHide={handleCloseChargeInfo}>
        <Modal.Header className="border-0 pt-4 pb-0">
          <Modal.Title className="text-start">
            {t('Subscription Payment')}
          </Modal.Title>
          <svg width="24" height="24" viewBox="0 0 24 24" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleCloseChargeInfo}>
            <path d="M18 18L6 6M18 6L6 18" stroke="#47494E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </Modal.Header>
        <Modal.Body className="pb-2 pt-2">
          <Container className="px-0">
            {!!chargeInfo?.status ? <PaymentNotification
              type={chargeInfo?.status === 'SUCCESS' ? 'success' : 'error'}
              subMessageSuccess={t('Your subscription plan has been updated.')}
            /> : (
              <>
                <Row>
                  <Col sm="12">
                    <div className="bg-purple-light d-flex justify-content-between align-items-center flex-row" style={{ borderRadius: '10px', padding: '12px 12px 12px 20px' }}>
                      <div className="flex-grow-1 d-flex justify-content-between align-items-center">
                        <div className="">
                          <h4 className="mb-0 text-color-1" >{t('Voting rights in possession')}</h4>
                        </div>
                        <div className="text-center">
                          <h4 className="mb-0 text-color-2" >{formatNumberWithCommas(myTicket?.ticket_amount || 0)} <span className="ms-0 me-3 text-color-3 text-normal" style={{ fontWeight: '200' }}>개</span></h4>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="d-flex flex-column gap-2 justify-content-start align-items-center mt-4 mb-3">
                  <div className="d-flex justify-content-center cursor-pointer card-push-point w-100" style={{ padding: '10px 16px', borderRadius: '10px', border: '1px solid #E9EBF1' }} onClick={() => setChargeInfo((_prev: any) => ({ ..._prev, subscription_period_type: SUBSCRIPTION_PERIOD_TYPE.MONTHLY }))}>
                    <div className="form-check flex-grow-1 align-self-center ps-1 d-flex align-items-center">
                      <RadioPrimary text='' checked={SUBSCRIPTION_PERIOD_TYPE.MONTHLY === chargeInfo?.subscription_period_type} />
                      <label className="form-check-label fs-16 ms-0 mt-0" style={{ paddingTop: '3px' }}>
                        1 {t('month')}
                      </label>
                    </div>
                    <ButtonPrimary
                      variant="contained"
                      text={Number(chargeInfo?.monthly_price) <= 0 ? (Number(chargeInfo?.monthly_price) < 0 ? t('Negotiable') : t('Free')) : `${formatNumberWithCommas(chargeInfo?.monthly_price)}원`}
                      className="py-2"
                      style={{ maxWidth: '130px' }}
                    />
                  </div>
                  <div className="d-flex justify-content-center cursor-pointer card-push-point w-100" style={{ padding: '10px 16px', borderRadius: '10px', border: '1px solid #E9EBF1' }} onClick={() => setChargeInfo((_prev: any) => ({ ..._prev, subscription_period_type: SUBSCRIPTION_PERIOD_TYPE.YEARLY }))}>
                    <div className="form-check flex-grow-1 align-self-center ps-1 d-flex align-items-center">
                      <RadioPrimary text='' checked={SUBSCRIPTION_PERIOD_TYPE.YEARLY === chargeInfo?.subscription_period_type} />
                      <label className="form-check-label fs-16 ms-0 mt-0" style={{ paddingTop: '3px' }}>
                        1 {t('year')}
                      </label>
                    </div>
                    <ButtonPrimary
                      variant="contained"
                      text={Number(chargeInfo?.yearly_price) <= 0 ? t('Negotiable') : `${formatNumberWithCommas(chargeInfo?.yearly_price)}원`}
                      className="py-2"
                      style={{ maxWidth: '130px' }}
                    />
                  </div>
                </div>
                <Row>
                  <Col sm="12" classNam="mt-3">
                    <h5>
                      {t('Voting Rights Usage Guide')}
                    </h5>
                    <ul style={{ fontSize: '12px' }} className="ms-3">
                      <li className="" style={{ listStyleType: 'disc' }}>
                        {t('After purchasing voting rights, you can use them within the Rankify service.')}
                      </li>
                      <li className="" style={{ listStyleType: 'disc' }}>
                        {t('The price of the voting rights includes VAT.')}
                      </li>
                      <li className="" style={{ listStyleType: 'disc' }}>
                        {t('Refunds for voting rights are only processed for unused voting rights after confirmation.')}
                      </li>
                      <li className="" style={{ listStyleType: 'disc' }}>
                        {t('If you encounter any issues with the purchase or use of voting rights, please contact us with the relevant screen at the following email:')}
                      </li>
                      <li className="" style={{ listStyleType: 'disc' }}>
                        {t('Inquiry Email')}: <a href="mailto:info@rankify.best" className="text-decoration-underline">info@rankify.best</a>
                      </li>
                      <li className="" style={{ listStyleType: 'disc' }}>
                        {t('We are not responsible for the loss or incorrect use of voting rights due to member negligence.')}
                      </li>
                    </ul>
                  </Col>
                </Row>
              </>)}
          </Container>
        </Modal.Body>
        <Modal.Footer className="text-center border-0 pb-4">
          <ButtonPrimary
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => { !!chargeInfo?.status ? handleCloseChargeInfo() : handleSubscription() }}
            variant="contained"
            text={!!chargeInfo?.status ? t('Close') : t('Purchase')}
            className="py-3 m-0 fs-16"
          />
        </Modal.Footer>
      </Modal>
      <Modal className="fade bd-example-modal-lg" show={!!confirmLogin} centered >
        <Modal.Header className="border-0 pt-4">
          <Modal.Title className="text-center m-auto">
            <div className="text-center">
              {t('Would you like to log in?')}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-2 pt-2">
          <Container className="px-0">
            <Col sm="12" md="12" >
              <div className="d-flex justify-content-around align-items-center py-2">
                {t('You must log in to participate in the vote.')}
              </div>
            </Col>
          </Container>
        </Modal.Body>
        <Modal.Footer className="text-center border-0 pb-4 justify-content-center gap-3">
          <ButtonPrimary
            onClick={() => setConfirmLogin((_prev) => false)}
            variant="outlined"
            text={t('Cancel')}
            className=" m-0"
            style={{ maxWidth: '150px', padding: '0.57rem 1rem' }}
          />
          <ButtonPrimary
            onClick={goToLogin}
            variant="contained"
            text={t('Go to log in')}
            className=" m-0"
            style={{ maxWidth: '150px', padding: '0.57rem 1rem' }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubscriptionIntroduction;