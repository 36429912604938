import { useContext } from "react";
import { useSelector } from "react-redux";
import { Outlet, Route, Routes } from "react-router-dom";
/// Css
import "./../chart.css";
import "./../index.css";
import "./../step.css";

/// Layout
import Footer from "./../layouts/Footer";
import Nav from "./../layouts/nav";

import { ThemeContext } from "../../context/ThemeContext";
//Scroll To Top
import ScrollToTop from "./../layouts/ScrollToTop";

/// Dashboard
import RankingBoard from "./../pages/dashboard/RankingBoard";

import EmptyPage from "./../pages/dashboard/EmptyPage";

// category
import Category from "./../pages/category/Category";

import BackToTop from "ui/layouts/BackToTop";
import Error404 from "./../pages/error/Error404";

import SubscriptionIntroduction from "ui/pages/introduction/Subscription";
import Payment from "ui/pages/payment/Payment";
import AccountManagement from "ui/pages/user/AccountManagement";
import MyPage from "ui/pages/user/MyPage";
import ServiceIntroduction from "./../pages/introduction/ServiceIntroduction/index_v2";
import OfficialMediaIntroduce from "ui/pages/introduction/OfficialMediaIntroduce";

const Markup = () => {
  const isValidGroupType = (groupType) => {
    const allowedGroupTypes = ['people', 'business', 'corporation', 'content'];
    return allowedGroupTypes.includes(groupType);
  };

  const allRoutes = [

    { url: "category/:id", component: <Category /> },

    { url: "ranking-board", component: <Category /> },

    { url: "payment/:type", component: <Payment /> },

    { url: "my-page", component: <MyPage /> },

    { url: "account-management", component: <AccountManagement /> },

    /// pages
    { url: "empty-page", component: <EmptyPage /> },

    {
      url: ":group_type/:rank_type?",
      component: <Category />,
    },

  ];

  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<RankingBoard />} />
          <Route
            path="/subscription-introduction"
            element={<SubscriptionIntroduction />}
          />
          {allRoutes?.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route
          path="/service-introduction"
          element={<ServiceIntroduction />}
        />
        <Route
          path="/official-media"
          element={<OfficialMediaIntroduce />}
        />
        <Route path="/*" element={<Error404 />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};


function MainLayout() {
  const { sidebariconHover } = useContext(ThemeContext);
  const { sideMenu } = useSelector((state) => state.Layout);
  const pathUrl = window.location.pathname.split("/");
  const path = pathUrl[pathUrl.length - 1];
  return (
    <>
      <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${sideMenu ? "menu-toggle" : ""}`}
      >
        <Nav />
        <div className="content-body">
          <div className={`container-fluid ${(path === "service-introduction" || path === "official-media") ? "pt-0 pt-sm-3 pt-md-4" : ""}`}
          >
            <Outlet />
          </div>
        </div>
        <Footer />
        <BackToTop />
      </div>
    </>
  );
}

export default Markup;
