import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { logoutUser } from 'store/thunks';

function LogoutPage() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    function onLogout() {
        dispatch(logoutUser());
        navigate('/');
    }
    return (
        <>
            <button className="dropdown-item ai-icon" onClick={onLogout}>
                <svg
                    id="icon-logout" xmlns="http://www.w3.org/2000/svg"
                    className="text-danger logout" width={24} height={24} viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
                >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                    <polyline points="16 17 21 12 16 7" />
                    <line x1={21} y1={12} x2={9} y2={12} />
                </svg>
                <span className="ms-2 text-danger" style={{ fontSize: '13px' }}>{t('Logout')}</span>
            </button>
        </>
    )
}


export default LogoutPage;