import React from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ModalSubcategory = ({ categories = [], level_sub = 1, isShow = false, onClose }: any) => {
    const { t, i18n } = useTranslation();

    const onHide = () => {
        onClose && onClose();
    };

    return (
        <>
            <Modal id="modal-subcategory" className="fade bd-example-modal-lg" show={!!isShow} centered onHide={onHide}>
                <Modal.Header className="border-0 pt-4 pb-0">
                    <Modal.Title className="text-start">
                        {level_sub ? t('Category') : t('Subcategory')}
                    </Modal.Title>
                    <svg width="24" height="24" viewBox="0 0 24 24" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onHide}>
                        <path d="M18 18L6 6M18 6L6 18" stroke="#47494E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Modal.Header>
                <Modal.Body className="pb-2 pt-2">
                    <Container className="px-0">
                        <Row>
                            <Col sm="12" className="mb-3">
                                <ul style={{ fontSize: '14px' }} className="ms-3">
                                    {categories?.map((category: string) => (
                                        <li className="mb-1" style={{ listStyleType: 'disc' }} key={category}>
                                            {category}
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default React.memo(ModalSubcategory);