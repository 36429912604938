import React, { useState } from 'react';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

const BackToTop = () => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);
    return (
        <React.Fragment>
            <button className="btn btn-primary btn-sm btn-icon p-0" id="back-to-top" style={{ display: visible ? 'block' : 'none' }} onClick={scrollToTop} >
                <KeyboardArrowUpOutlinedIcon />
            </button>
        </React.Fragment>
    );
};

export default BackToTop;