import React from "react";

const EmptyPage = () => {
 
  return (
		<>	
			<p>this is example page</p>
		</>
	);
};

export default EmptyPage;
