import { formatNumberWithCommas } from 'helpers/format';
import React from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'ui/components/buttons/ButtonPrimary';

const ModalVoting = ({ isLoading, setChargeInfo, keywordVote = null, tickets = [], myTicket = null, ticketAmountVote, setTicketAmountVote, isShow = false, handleVote, handlePollVote, onClose }: any) => {
    const { t, i18n } = useTranslation();

    const onHide = () => {
        onClose && onClose();
    };

    const onAction = () => {
        handleVote && handleVote();
    }

    return (
        <>
            <Modal id="modal-voting" className="fade bd-example-modal-lg" show={!!isShow} centered onHide={onHide}>
                <Modal.Header className="border-0 pt-4">
                    <Modal.Title>{i18n?.language === 'en' ? t('Vote to') : ''} <span style={{ borderBottom: '2px solid var(--text-dark)' }}>{keywordVote?.keyword?.keyword}</span> {i18n?.language === 'en' ? '' : t('Vote to')}</Modal.Title>
                    <svg width="24" height="24" viewBox="0 0 24 24" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => { e.stopPropagation(); onHide() }}>
                        <path d="M18 18L6 6M18 6L6 18" stroke="#47494E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Modal.Header>
                <Modal.Body className="pb-2 pt-2">
                    <Container className="px-0">
                        <Row>
                            <Col sm="12">
                                <div className="bg-purple-light d-flex justify-content-between align-items-center flex-row" style={{ borderRadius: '10px', padding: '12px 12px 12px 20px' }}>
                                    <div className="flex-grow-1 d-flex justify-content-between align-items-center">
                                        <div className="">
                                            <h4 className="mb-0 text-color-1" >{t('My Vote')}</h4>
                                        </div>
                                        <div className="text-center">
                                            <h4 className="mb-0 text-color-2" >{formatNumberWithCommas(myTicket?.ticket_amount || 0)} <span className="ms-0 me-3 text-color-3 text-normal" style={{ fontWeight: '200' }}>개</span></h4>
                                        </div>
                                    </div>
                                    <ButtonPrimary
                                        onClick={() => { setChargeInfo({ ticket_id: tickets[0]?.id || '' }); }}
                                        variant="contained"
                                        text={t('Charge')}
                                        className="py-2"
                                        style={{ maxWidth: '90px' }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className="d-flex gap-2 justify-content-between align-items-center mt-4">
                            {[10, 100, 1000, 'all']?.map((num) => (
                                <div className="flex-grow-1 cursor-pointer card-push-point" style={{ padding: '12px 16px', borderRadius: '10px', border: '1px solid #E9EBF1' }} onClick={() => handlePollVote(num)}>
                                    <h3 className="text-color-2 text-center fs-16 fs-md-auto mb-0">{num === 'all' ? t('Use All') : <>+{num}</>}</h3>
                                </div>
                            ))}
                        </div>
                        <Row>
                            <Col sm="12" className="mt-3 d-flex gap-2">
                                <div className="cursor-pointer d-flex justify-content-center align-items-center text-center " style={{ padding: '8px', borderRadius: '12px', border: '1px solid #E9EBF1' }} onClick={() => setTicketAmountVote(0)} >
                                    <h3 className="text-color-5 text-center fs-16 fs-md-auto mb-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                            <path d="M27.1288 22.5C26.1109 25.6939 23.1954 28 19.7576 28C15.4732 28 12 24.4183 12 20C12 15.5817 15.4732 12 19.7576 12C22.2706 12 24.5045 13.2322 25.9221 15.1428M26.4773 16C26.3099 15.7015 26.1243 15.4153 25.9221 15.1428M25.9221 15.1428L24.1212 17H28V13L25.9221 15.1428ZM26.0606 16.6667L27.2727 15" stroke="#A7ABB5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </h3>
                                </div>
                                <div className="cursor-pointer d-flex justify-content-center align-items-center text-center flex-grow-1 " style={{ padding: '16px', borderRadius: '12px', border: '1px solid #E9EBF1' }}  >
                                    <h3 className="text-color-5 text-center fs-16 fs-md-auto mb-0">
                                        {formatNumberWithCommas(ticketAmountVote || 0)} <span className="ms-1">{t('use your right to vote')}</span>
                                    </h3>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="text-center border-0 pb-4">
                    <ButtonPrimary
                        disabled={isLoading || Number(ticketAmountVote) === 0}
                        isLoading={isLoading}
                        onClick={onAction}
                        variant="contained"
                        text={t('Vote')}
                        className="py-3 m-0 fs-16"
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default React.memo(ModalVoting);