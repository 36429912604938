import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Spinner } from 'react-bootstrap';

const CustomizedButtons = styled(Button)(({ variant }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 800,
  padding: '16px 32px',
  lineHeight: '150%',
  borderRadius: '50px',
  display: 'grid',
  alignItems: 'center',
  alignContent: 'center',
  width: 'auto',
  fontFamily: [
    'NanumSquare',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '@media (max-width: 1800px)': {
    fontSize: '16px',
    padding: '12px 24px',

  },
  '@media (max-width: 800px)': {
    fontSize: '15px',
    fontWeight: 800,
    padding: '16px 24px',
    width: '50%',
  },
  '@media (max-width: 500px)': {
    fontSize: '16px',
    fontWeight: 800,
    width: '100%',
  },
  ...(variant === 'contained' && {
    backgroundColor: '#3353FF',
    borderColor: '#3353FF',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: 'rgba(51, 83, 255, 0.8)',
      borderColor: 'rgba(51, 83, 255, 0.8)',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(51, 83, 255, 0.8)',
      borderColor: 'rgba(51, 83, 255, 0.8)',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(51, 83, 255, 0.8)',
    },
  }),
  ...(variant === 'outlined' && {
    backgroundColor: 'transparent',
    borderColor: '#3353FF',
    color: '#3353FF',
    '&:hover': {
      backgroundColor: 'rgba(51, 83, 255, 0.8)',
      borderColor: '#1c1c3c',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(20, 20, 43, 0.8)',
      borderColor: '#14142b',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(51, 83, 255, 0.8)',
    },
  }),
}));

export default function ButtonBlue({ text, isLoading, variant = 'contained', isIcon = false, Icon = undefined, iconError = true, ...props }: any) {
  return (
    <CustomizedButtons variant={variant} {...props}>
      {isLoading ? (
        <div style={{ transform: 'translateY(3px)' }}>
          <Spinner size="sm" />
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ lineHeight: '24px' }}
        >
          {isIcon ? (Icon ? Icon : <></>) : <></>} <div>{text}</div>
        </div>
      )}
    </CustomizedButtons>
  );
}
