import { createAsyncThunk } from "@reduxjs/toolkit";
import rankingApi from "api/rankingApi";
import { CATEGORY_RANK_TYPE } from "helpers/constans";
import { formatQueryParams } from "helpers/format";

export const rankingCategory = async (params: any = {}) => {
  try {
    const response = await rankingApi.rankingCategory(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};
export const rankingCategories = createAsyncThunk("Ranking Categories", async (params: any = {}) => {
  try {
    const response = await rankingApi.rankingCategory(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const rankingKeywords = createAsyncThunk("Ranking Keywords By Category", async (params: any = {}) => {
  try {
    const response = await rankingApi.rankingKeywordByCategory(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getRankingKeywordsByNormal = async (params: any = {}) => {
  try {
    const clone = structuredClone(params);

    if (!!clone?.sort_by && clone?.sort_by === 'trend_index') {
      clone.sort_by = 'index';
    }

    if (!!clone?.rank_type && ![String(CATEGORY_RANK_TYPE.POPULAR), String(CATEGORY_RANK_TYPE.ISSUE)]?.includes(String(clone?.rank_type))) {
      clone.rank_type = CATEGORY_RANK_TYPE.POPULAR;
    }

    // if (String(clone?.category_id) === '704' && String(clone?.sub_category_id) === '705') {
    //   clone.category_id = '1';
    //   clone.sub_category_id = '';
    // }
    // if (String(clone?.category_id) === '704' && String(clone?.sub_category_id) === '708') {
    //   clone.category_id = '1';
    //   clone.sub_category_id = '2';
    // }
    // if (String(clone?.category_id) === '704' && String(clone?.sub_category_id) === '709') {
    //   clone.category_id = '1';
    //   clone.sub_category_id = '47';
    // }
    // if (String(clone?.category_id) === '704' && (String(clone?.sub_category_id) === '711' || String(clone?.sub_category_id) === '707')) {
    //   clone.category_id = '1';
    //   clone.sub_category_id = '5';
    // }
    // if (String(clone?.category_id) === '704' && (String(clone?.category_level_1) !== '706') && (String(clone?.category_level_1) !== '707')) {
    //   clone.category_id = '1';
    // }

    const response = await rankingApi.rankingKeywordByCategory(formatQueryParams(clone))
    return response;
  } catch (error) {
    return error;
  }
};


export const getRankingKeywordByNormal = async (params: any = {}) => {
  try {
    const response = await rankingApi.rankingKeyword(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};