import { useTranslation } from 'react-i18next';

const PaymentNotification = (props: any) => {
    const { t, i18n } = useTranslation();
    const {
        type,
        mainMessageSuccess = t('Payment has been completed!'),
        mainMessageError = t('Payment has not been completed!'),
        subMessageSuccess = t('Your purchased voting tickets have been credited.'),
        subMessageError = t('Please check your payment information and try again.'),
    }: any = props;
    return (
        <>
            <div className="text-center">
                <div>
                    {
                        type === 'success' ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                width="250"
                                height="250"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="250"
                                    height="250"
                                    fillRule="evenodd"
                                    strokeLinecap="round"
                                    strokeMiterlimit="3"
                                    clipRule="evenodd"
                                    viewBox="0 0 1500 1500"
                                >
                                    <path fill="none" d="M0 0H1500V1500H0z"></path>
                                    <circle
                                        cx="6086.33"
                                        cy="924.633"
                                        r="645.233"
                                        fill="#e5ecef"
                                        className="colore5ecef svgShape"
                                        transform="translate(-5632.53 -219.432) scale(1.04845)"
                                    ></circle>
                                    <circle
                                        cx="6086.33"
                                        cy="924.633"
                                        r="645.233"
                                        fill="none"
                                        stroke="#72a3b8"
                                        strokeWidth="4.05"
                                        className="colorStroke72a3b8 svgStroke"
                                        transform="translate(-5202.04 -125.351) scale(.98852)"
                                    ></circle>
                                    <path
                                        fill="#39917d"
                                        d="M5634.22 960.273s-45.43-490.081-60.09-648.205c-2.51-27.119 16.84-51.384 43.84-54.972l1027.67-136.581c27.32-3.631 52.54 15.216 56.8 42.447 21.02 134.319 79.46 507.789 101.38 647.867a50.745 50.745 0 01-10.27 39.242 50.755 50.755 0 01-35.76 19.168l-1123.57 91.034z"
                                        className="color39917d svgShape"
                                        transform="translate(-4646.71 225.571) scale(.88424)"
                                    ></path>
                                    <path
                                        fill="#005759"
                                        d="M6625.98 514.787l90.53 525.083-172.21 13.95 81.68-539.033z"
                                        className="color005759 svgShape"
                                        transform="translate(-4620.13 75.494) scale(.88424)"
                                    ></path>
                                    <g transform="rotate(-2.189 4689.441 -633.366) scale(.88424)">
                                        <path
                                            fill="#48d085"
                                            d="M6691.16 460.831c0-36.898-29.91-66.81-66.81-66.81H5575.74c-36.9 0-66.81 29.912-66.81 66.81v633.789c0 36.9 29.91 66.81 66.81 66.81h1048.61c36.9 0 66.81-29.91 66.81-66.81V460.831z"
                                            className="color48d085 svgShape"
                                            transform="translate(-5337.54)"
                                        ></path>
                                        <circle
                                            cx="6425.7"
                                            cy="579.045"
                                            r="87.662"
                                            fill="#ffc039"
                                            className="colorffc039 svgShape"
                                            transform="translate(-5351.32 -28.462)"
                                        ></circle>
                                        <circle
                                            cx="6425.7"
                                            cy="579.045"
                                            r="87.662"
                                            fill="#a7efa4"
                                            className="colora7efa4 svgShape"
                                            transform="translate(-5245.47 -28.462)"
                                        ></circle>
                                        <path
                                            fill="#fc743e"
                                            d="M6454.86 471.603c21.1 16.015 34.74 41.361 34.74 69.868 0 28.506-13.64 53.853-34.74 69.868-21.1-16.015-34.74-41.362-34.74-69.868 0-28.507 13.64-53.853 34.74-69.868z"
                                            className="colorfc743e svgShape"
                                            transform="translate(-5327.55 9.112)"
                                        ></path>
                                        <path
                                            fill="#fff"
                                            d="M6087.53 986.721c0-9.348-7.57-16.925-16.92-16.925h-440.85c-9.35 0-16.92 7.577-16.92 16.925v.002c0 9.348 7.57 16.927 16.92 16.927h440.85c9.35 0 16.92-7.579 16.92-16.927v-.002z"
                                            className="colorfff svgShape"
                                            transform="translate(-5344.33 -.045)"
                                        ></path>
                                        <path
                                            fill="#fff"
                                            d="M6131.38 977.688c0-5.824-78.02-10.545-174.26-10.545h-213.86c-96.24 0-174.26 4.721-174.26 10.545v18.068c0 5.824 78.02 10.544 174.26 10.544h213.86c96.24 0 174.26-4.72 174.26-10.544v-18.068zm-87.69 0v18.068c0 2.894-38.76 5.244-86.57 5.244h-213.86c-47.82 0-86.58-2.35-86.58-5.244v-18.068c0-2.894 38.76-5.239 86.58-5.239h213.86c47.81 0 86.57 2.345 86.57 5.239z"
                                            className="colorfff svgShape"
                                            transform="matrix(.11405 0 0 1.88465 -364.83 -1009.65)"
                                        ></path>
                                        <path
                                            fill="#fff"
                                            d="M6131.38 977.688c0-5.824-78.02-10.545-174.26-10.545h-213.86c-96.24 0-174.26 4.721-174.26 10.545v18.068c0 5.824 78.02 10.544 174.26 10.544h213.86c96.24 0 174.26-4.72 174.26-10.544v-18.068zm-87.69 0v18.068c0 2.894-38.76 5.244-86.57 5.244h-213.86c-47.82 0-86.58-2.35-86.58-5.244v-18.068c0-2.894 38.76-5.239 86.58-5.239h213.86c47.81 0 86.57 2.345 86.57 5.239z"
                                            className="colorfff svgShape"
                                            transform="matrix(.11405 0 0 1.88465 -269.346 -1009.65)"
                                        ></path>
                                        <path
                                            fill="#fff"
                                            d="M6131.38 977.688c0-5.824-78.02-10.545-174.26-10.545h-213.86c-96.24 0-174.26 4.721-174.26 10.545v18.068c0 5.824 78.02 10.544 174.26 10.544h213.86c96.24 0 174.26-4.72 174.26-10.544v-18.068zm-87.69 0v18.068c0 2.894-38.76 5.244-86.57 5.244h-213.86c-47.82 0-86.58-2.35-86.58-5.244v-18.068c0-2.894 38.76-5.239 86.58-5.239h213.86c47.81 0 86.57 2.345 86.57 5.239z"
                                            className="colorfff svgShape"
                                            transform="matrix(.11405 0 0 1.88465 -173.861 -1009.65)"
                                        ></path>
                                        <path
                                            fill="#fff"
                                            d="M6131.38 977.688c0-5.824-78.02-10.545-174.26-10.545h-213.86c-96.24 0-174.26 4.721-174.26 10.545v18.068c0 5.824 78.02 10.544 174.26 10.544h213.86c96.24 0 174.26-4.72 174.26-10.544v-18.068zm-87.69 0v18.068c0 2.894-38.76 5.244-86.57 5.244h-213.86c-47.82 0-86.58-2.35-86.58-5.244v-18.068c0-2.894 38.76-5.239 86.58-5.239h213.86c47.81 0 86.57 2.345 86.57 5.239z"
                                            className="colorfff svgShape"
                                            transform="matrix(.11405 0 0 1.88465 -78.377 -1009.65)"
                                        ></path>
                                        <path
                                            fill="#fff"
                                            d="M6131.38 977.688c0-5.824-78.02-10.545-174.26-10.545h-213.86c-96.24 0-174.26 4.721-174.26 10.545v18.068c0 5.824 78.02 10.544 174.26 10.544h213.86c96.24 0 174.26-4.72 174.26-10.544v-18.068zm-87.69 0v18.068c0 2.894-38.76 5.244-86.57 5.244h-213.86c-47.82 0-86.58-2.35-86.58-5.244v-18.068c0-2.894 38.76-5.239 86.58-5.239h213.86c47.81 0 86.57 2.345 86.57 5.239z"
                                            className="colorfff svgShape"
                                            transform="matrix(.11405 0 0 1.88465 17.107 -1009.65)"
                                        ></path>
                                        <path
                                            fill="#fff"
                                            d="M6087.53 986.721c0-4.489-4.82-8.794-13.42-11.968-8.59-3.174-20.25-4.957-32.4-4.957h-383.05c-12.15 0-23.81 1.783-32.4 4.957-8.6 3.174-13.42 7.479-13.42 11.968v.002c0 4.489 4.82 8.794 13.42 11.968 8.59 3.179 20.25 4.959 32.4 4.959h383.05c12.15 0 23.81-1.78 32.4-4.959 8.6-3.174 13.42-7.479 13.42-11.968v-.002z"
                                            className="colorfff svgShape"
                                            transform="matrix(.36934 0 0 1 -1804.52 70.484)"
                                        ></path>
                                    </g>
                                    <path
                                        fill="#ffc039"
                                        d="M669.804 419.894V197.5c0-14.747 11.973-26.72 26.721-26.72 14.747 0 26.72 11.973 26.72 26.72v222.394c0 14.747-11.973 26.72-26.72 26.72-14.748 0-26.721-11.973-26.721-26.72z"
                                        className="colorffc039 svgShape"
                                        transform="matrix(-.62189 .22648 .22648 .62189 605.469 -12.214)"
                                    ></path>
                                    <path
                                        fill="#ffc039"
                                        d="M721.668 410.849l-40-111.197c-4.992-13.876-20.311-21.09-34.188-16.098-13.877 4.992-21.09 20.311-16.098 34.188l40 111.197c4.991 13.876 20.311 21.09 34.187 16.098 13.877-4.992 21.091-20.311 16.099-34.188z"
                                        className="colorffc039 svgShape"
                                        transform="matrix(.62189 -.22648 .22648 .62189 -346.59 382.62)"
                                    ></path>
                                    <path
                                        fill="#ffc039"
                                        d="M671.382 428.939l-40-111.197c-4.992-13.877 2.221-29.196 16.098-34.188 13.877-4.992 29.196 2.222 34.188 16.098l40 111.197c4.992 13.877-2.222 29.196-16.099 34.188-13.876 4.992-29.196-2.222-34.187-16.098z"
                                        className="colorffc039 svgShape"
                                        transform="matrix(-.62189 .22648 .22648 .62189 724.954 -28.338)"
                                    ></path>
                                </svg>
                            </svg>
                        )
                            :
                            (<svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="350"
                                height="250"
                                viewBox="0 0 500 375"
                            >
                                <g data-name="Payment Transaction">
                                    <circle
                                        cx="120.736"
                                        cy="105.859"
                                        r="7.212"
                                        fill="#6ca8f5"
                                        className="color6ca8f5 svgShape"
                                    ></circle>
                                    <circle
                                        cx="243.616"
                                        cy="294.693"
                                        r="5.1"
                                        fill="#6ca8f5"
                                        className="color6ca8f5 svgShape"
                                    ></circle>
                                    <circle
                                        cx="323.784"
                                        cy="85.073"
                                        r="3.399"
                                        fill="#6ca8f5"
                                        className="color6ca8f5 svgShape"
                                    ></circle>
                                    <path
                                        fill="#6ca8f5"
                                        d="M183.014 266.926c-3.617 1.442-7.447 3.216-9.714 6.535-2.426 3.55-1.832 8.13.543 11.545a15.263 15.263 0 0010.278 6.213c3.544.528 8.717.064 11.075-3.038a3.872 3.872 0 00-.123-4.886 6.108 6.108 0 00-5.775-1.86c-4.026.55-9.09 2.22-10.77 6.29-1.675 4.061.082 8.478 2.883 11.545a37.406 37.406 0 0011.239 7.805 51.418 51.418 0 0030.498 4.568c1.268-.2.73-2.127-.531-1.928a49.015 49.015 0 01-22.5-1.782 48.43 48.43 0 01-10.261-4.654c-3.18-1.918-6.593-4.207-8.563-7.442-1.825-2.998-2.149-7.27.833-9.667a13.695 13.695 0 014.95-2.188c1.899-.537 4.452-1.276 6.114.22 1.798 1.62.2 3.383-1.49 4.156a12.457 12.457 0 01-4.957 1.047 13.858 13.858 0 01-9.31-3.347c-2.98-2.641-4.928-7.105-2.849-10.873 1.812-3.282 5.637-5.005 8.962-6.33 1.181-.47.666-2.406-.532-1.929zM390.99 127.45a48.001 48.001 0 01-26.424 2.997 44.681 44.681 0 01-18.04-7.472c-1.768-1.242-3.914-2.661-5.167-4.46-.703-1.01-1.203-2.468-.128-3.421a4.728 4.728 0 012.914-.763c2.315-.135 5.001-.297 7.029 1.046a5.411 5.411 0 012.203 5.447c-.507 2.3-2.652 3.437-4.733 4.044-9.613 2.805-19.817-1.062-27.62-6.704-7.834-5.665-13.649-14.115-14.32-23.937-.087-1.277-2.088-1.287-2 0 .685 10.025 6.238 18.678 14.074 24.736 8.478 6.556 20.224 11.154 30.895 7.68a8.193 8.193 0 005.24-4.149 7.172 7.172 0 00-.477-6.456 7.748 7.748 0 00-5.895-3.632 15.839 15.839 0 00-7.343.419 4.049 4.049 0 00-2.236 5.446c.909 2.276 3.11 3.842 4.99 5.282a46.474 46.474 0 0013.417 7.12 49.357 49.357 0 0034.153-1.294c1.177-.479.662-2.414-.531-1.929z"
                                        className="color6ca8f5 svgShape"
                                    ></path>
                                    <path
                                        fill="#2b8941"
                                        d="M317.682 178.805l-165.834 93.782c-7.239 4.093-15.346 1.918-17.956-4.88l-44.89-116.94c-2.217-5.777 1.845-12.48 8.94-14.974L263.904 77.48a10.165 10.165 0 0111.88 4.861l44.883 83.51c2.558 4.759 1.254 10.557-2.985 12.954z"
                                        className="color2b8941 svgShape"
                                    ></path>
                                    <path
                                        fill="#252525"
                                        d="M103.138 187.591l186.963-78.613-9.167-17.056-186.867 72.039 9.071 23.63z"
                                        className="color252525 svgShape"
                                    ></path>
                                    <path
                                        fill="#fff"
                                        d="M289.31 133.163L131.9 205.717c-2.366 1.09-4.958.298-5.775-1.77l-5.15-13.04c-.8-2.027.475-4.49 2.834-5.503l157.421-67.547a3.307 3.307 0 014.106 1.596l5.146 9.707a2.842 2.842 0 01-1.172 4.003z"
                                        className="colorfff svgShape"
                                    ></path>
                                    <path
                                        fill="#f4b1e6"
                                        d="M292.06 166.282c3.147 6.078 1.163 13.517-4.553 16.625-5.829 3.168-13.23.602-16.4-5.74-3.143-6.288-.845-13.742 5.006-16.645 5.74-2.848 12.825-.267 15.946 5.76z"
                                        className="colorf4b1e6 svgShape"
                                    ></path>
                                    <path
                                        fill="#ef603f"
                                        d="M306.254 158.909c3.128 5.9 1.348 13.074-4.09 16.03-5.542 3.013-12.69.475-15.844-5.675-3.128-6.099-1.054-13.284 4.513-16.047 5.465-2.71 12.317-.162 15.42 5.692z"
                                        className="coloref603f svgShape"
                                    ></path>
                                    <path
                                        fill="#f9d95a"
                                        d="M284.25 132.418l-17.262 7.867a5.583 5.583 0 01-7.173-2.666l-.69-1.366a4.908 4.908 0 012.32-6.839l17.291-7.505a5.454 5.454 0 016.812 2.626l.685 1.298a4.673 4.673 0 01-1.983 6.585z"
                                        className="colorf9d95a svgShape"
                                    ></path>
                                    <path
                                        fill="#6ca8f5"
                                        d="M244.528 178.12l-103.237 51.54c-2.377 1.186-5.002.416-5.846-1.722-.842-2.133.414-4.8 2.789-5.956l103.219-50.248a3.504 3.504 0 014.693 1.628 3.582 3.582 0 01-1.618 4.757zm5.333 11.07l-103.26 53.805c-2.38 1.24-5.02.484-5.879-1.692-.856-2.17.39-4.91 2.77-6.119l103.247-52.475a3.47 3.47 0 014.714 1.61 3.7 3.7 0 01-1.592 4.872zm5.413 11.238l-103.269 56.137c-2.383 1.296-5.038.553-5.912-1.66-.872-2.209.365-5.024 2.747-6.288l103.265-54.767a3.438 3.438 0 014.733 1.59 3.822 3.822 0 01-1.564 4.988z"
                                        className="color6ca8f5 svgShape"
                                    ></path>
                                    <path
                                        fill="#ef603f"
                                        d="M386.153 299.42l-181.918-27.234c-5.283-.79-8.95-6.304-8.234-12.303l12.966-108.54c.673-5.638 5.372-10.007 10.534-9.771l176.84 8.07a11.808 11.808 0 0111.5 12.388l-8.18 126.544a11.585 11.585 0 01-13.508 10.846z"
                                        className="coloref603f svgShape"
                                    ></path>
                                    <path
                                        fill="#f9d95a"
                                        d="M243.01 181.092l-21.8-1.639c-2.909-.219-4.955-2.986-4.582-6.176l1.722-14.77a5.936 5.936 0 015.899-5.431l21.692 1.18a5.344 5.344 0 014.824 6.162l-1.632 15.154a5.98 5.98 0 01-6.122 5.52z"
                                        className="colorf9d95a svgShape"
                                    ></path>
                                    <path
                                        fill="#397ec3"
                                        d="M372.237 175.57a14.59 14.59 0 01-15.637 13.402 14.137 14.137 0 01-13.106-15.258 14.61 14.61 0 0115.337-13.31 14.132 14.132 0 0113.406 15.165z"
                                        className="color397ec3 svgShape"
                                    ></path>
                                    <path
                                        fill="#6ca8f5"
                                        d="M393.644 176.95a14.817 14.817 0 01-16.006 13.595 14.55 14.55 0 01-13.595-15.505 14.834 14.834 0 0115.693-13.499 14.553 14.553 0 0113.908 15.41z"
                                        className="color6ca8f5 svgShape"
                                    ></path>
                                    <path
                                        fill="#252525"
                                        d="M293.813 213.758a3.406 3.406 0 01-4.7 1.549 3.727 3.727 0 01-1.422-4.922 3.408 3.408 0 014.685-1.558 3.725 3.725 0 011.437 4.93zm26.026.437a3.54 3.54 0 01-4.863 1.576 3.784 3.784 0 01-1.517-5.014 3.542 3.542 0 014.845-1.586 3.782 3.782 0 011.535 5.024zm-38.03 20.878a.5.5 0 01-.287-.91c14.568-10.16 35.528-6.536 35.739-6.498a.5.5 0 01.403.581.51.51 0 01-.581.403c-.205-.037-20.766-3.585-34.988 6.334a.498.498 0 01-.286.09z"
                                        className="color252525 svgShape"
                                    ></path>
                                    <path
                                        fill="#f4b1e6"
                                        d="M280.615 270.774l-68.815-9.641c-2.951-.414-5.018-3.454-4.629-6.787l.2-1.716c.388-3.324 3.079-5.708 6.023-5.328l68.632 8.857a6.5 6.5 0 015.354 7.182l-.174 1.83a5.944 5.944 0 01-6.591 5.603zm53.582 7.507l-29.555-4.14a6.745 6.745 0 01-5.544-7.362l.17-1.846a6.062 6.062 0 016.716-5.683l29.464 3.803a7.003 7.003 0 015.911 7.515l-.153 1.906a6.187 6.187 0 01-7.01 5.807z"
                                        className="colorf4b1e6 svgShape"
                                    ></path>
                                </g>
                            </svg>)
                    }
                </div>
                <h3 className="text-center mb-2 text-dark">{type === 'success' ? mainMessageSuccess : mainMessageError}</h3>
                <h4 className="text-center mb-2 text-dark">{type === 'success' ? subMessageSuccess : subMessageError}</h4>
            </div>
        </>
    );
};

export default PaymentNotification;
