import { createAsyncThunk } from "@reduxjs/toolkit";
import voteApi from "api/voteApi";
import { formatQueryParams } from "helpers/format";

export const myTicket = createAsyncThunk("My Ticket", async (params: any = {}) => {
  try {
    const response = await voteApi.getTickets(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const postVote = async (data: any = {}) => {
  try {
    const response = await voteApi.postVoting(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const postSurvey = async (data: any = {}) => {
  try {
    const response = await voteApi.postSurvey(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const getTicket = async (data: any = {}) => {
  try {
    const response = await voteApi.getTickets(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const postPaymentProcess = async (data: any = {}) => {
  try {
    const response = await voteApi.postPaymentProcess(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const getSurveyForms = async (category_id: string | number, params: any = {}) => {
  try {
    let cate_id: any = category_id;
    // if (String(category_id) === '705') {
    //   cate_id = '1';
    // }
    // if (String(category_id) === '30') {
    //   cate_id = '30';
    // }
    // if (String(category_id) === '708') {
    //   cate_id = '2';
    // }
    // if (String(category_id) === '709') {
    //   cate_id = '47';
    // }
    const response = await voteApi.getSurveyForms(cate_id, params);
    return response;
  } catch (error) {
    return error;
  }
};
