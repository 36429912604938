import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { postSurvey } from 'store/voting/thunk';
import ButtonPrimary from 'ui/components/buttons/ButtonPrimary';
import CheckBoxPrimary from 'ui/components/buttons/CheckBoxPrimary';
import RadioPrimary from 'ui/components/buttons/RadioPrimary';
import useToastNotifications from 'ui/components/Hooks/useToastNotifications';

const ModalSurvey = ({ surveyForm = {}, surveyDetail = {}, isShow = false, onClose }: any) => {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const { notify } = useToastNotifications();

    const [isLoading, setIsLoading] = useState(false);

    const [form, setForm] = useState(surveyForm || {});

    const [valueAnswers, setValueAnswers] = useState([surveyForm?.question?.answers?.[0]?.id]);

    const onHide = () => {
        onClose && onClose();
    };

    const handleSurvey = async () => {
        try {
            if (isLoading) {
                return;
            }
            setIsLoading((_prev) => true);
            const data = {
                category_id: Number(surveyDetail?.category_id || ''),
                keyword_id: Number(surveyDetail?.keyword_id || ''),
                answer_ids: valueAnswers?.map((answer) => Number(answer)),
            };
            const response: any = await postSurvey(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                notify('success', t('Survey success'));
                onHide();
            } else {
                setIsLoading((_prev) => false);
                notify('error', t(`${response}`));
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            notify('error', `${error?.message || ""}`);
            return error;
        }
    };

    const handleCheckMulti = (e: any) => {
        if (e?.target?.checked && Number(form?.submission_limit) > 1 && Number(form?.submission_limit) === valueAnswers?.length) {
            return;
        }
        if (e?.target?.checked && !!e?.target?.value) {
            setValueAnswers((_prev) => [..._prev, Number(e?.target?.value)]);
            return;
        }
        if (!e?.target?.checked && !!e?.target?.value) {
            setValueAnswers((_prev) => _prev?.filter((item) => item !== Number(e?.target?.value)));
            return;
        }
    };

    useEffect(() => {
        if (surveyForm) {
            setForm((_prev: any) => surveyForm);
            setValueAnswers((_prev: any) => (Number(form?.submission_limit) > 1 ? [] : [surveyForm?.question?.answers?.[0]?.id]));
        }
    }, [JSON.stringify(surveyForm), JSON.stringify(surveyDetail)]);

    return (
        <>
            <Modal id="modal-survey" className="fade bd-example-modal-lg" show={!!isShow} centered onHide={onHide}>
                <Modal.Header className="border-0 pt-4">
                    <Modal.Title>{i18n?.language === 'en' ? t('Survey to') : ''} <span style={{ borderBottom: '2px solid var(--text-dark)' }}>{surveyDetail?.keyword?.keyword}</span> {i18n?.language === 'en' ? '' : t('Survey to')}</Modal.Title>
                    <svg width="24" height="24" viewBox="0 0 24 24" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onHide}>
                        <path d="M18 18L6 6M18 6L6 18" stroke="#47494E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Modal.Header>
                <Modal.Body className="py-0 pt-2">
                    <Container className="px-0">
                        <Row>
                            <Col sm="12">
                                <div className="card-body p-0">
                                    <div className="basic-form">
                                        <div className="mb-3 mb-0">
                                            <div className="form-label mb-1" style={{ fontSize: '16px' }}>
                                                {surveyForm?.question?.name || ''}
                                                {(Number(form?.submission_limit) > 1) && <span className="ms-1 text-muted">({t('Max [number] answers').replace('[number]', form?.submission_limit)})</span>}
                                            </div>
                                            <div className="p-1">
                                                {form?.question?.answers?.map((answer: any) => (
                                                    <div className="mb-1" key={answer?.id} >
                                                        {(Number(form?.submission_limit) > 1) ? <CheckBoxPrimary
                                                            text={answer?.content || ''}
                                                            checked={!!(valueAnswers?.includes(answer?.id))}
                                                            value={answer?.id}
                                                            fontSize="14px"
                                                            onChange={(e: any) => handleCheckMulti(e)}
                                                        /> : <RadioPrimary
                                                            text={answer?.content || ''}
                                                            checked={!!(valueAnswers?.includes(answer?.id))}
                                                            fontSize="14px"
                                                            onClick={() => setValueAnswers([answer?.id])}
                                                        />}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="text-center border-0 pb-4 pt-0">
                    <ButtonPrimary
                        disabled={valueAnswers?.length === 0}
                        isLoading={isLoading}
                        onClick={handleSurvey}
                        variant="contained"
                        text={t('Survey')}
                        className="py-3 m-0 fs-16"
                    />
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default React.memo(ModalSurvey);