import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import NoImage from '../../../../../assets/images/no-photo-available.png';

const Singer = ({ keyword_info = {} }: any) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="d-flex flex-w flex-wrap mb-3">
                <div className="d-flex flex-column gap-2 w-100">
                    <>
                        <div style={{ width: '100%', height: 'auto', maxHeight: '300px' }}>
                            <img alt={keyword_info?.name || ''} loading="lazy" src={keyword_info?.image_url || keyword_info?.image || NoImage}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                    // backgroundColor: '#eaeaea'
                                }}
                            />
                        </div>
                        <div className="w-100">
                            <div>
                                <h3 className="mb-1">
                                    {keyword_info?.name || ''}
                                </h3>
                            </div>
                            <table className="table table table-bordered mb-0">
                                <tbody>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Gender')}</td>
                                        <td className="p-2 text-start">{t(String(keyword_info?.gender || '-').toUpperCase())}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Birthday')}</td>
                                        <td className="p-2 text-start">{keyword_info?.birthday || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Star Sign')}</td>
                                        <td className="p-2 text-start">{keyword_info?.star_sign || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Real Name')}</td>
                                        <td className="p-2 text-start">{keyword_info?.real_name || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Nationality')}</td>
                                        <td className="p-2 text-start">{keyword_info?.nationality || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Debut Date')}</td>
                                        <td className="p-2 text-start">{keyword_info?.debut_date || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Active Years')}</td>
                                        <td className="p-2 text-start">{keyword_info?.active_years || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Activity Type')}</td>
                                        <td className="p-2 text-start">{t(String(keyword_info?.activity_type || '-').toUpperCase())}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Celeb of Agency')}</td>
                                        <td className="p-2 text-start">{keyword_info?.agency || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Genre')}</td>
                                        <td className="p-2 text-start">
                                            {Array.isArray(keyword_info?.genre) ? keyword_info?.genre.join(', ') : keyword_info?.genre || ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Group Members')}</td>
                                        <td className="p-2 text-start">{(keyword_info?.group_members || [])?.map((member: any) => (<>{member?.name} ({member?.code}) <br /></>))}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}

export default React.memo(Singer);