export const MenuList = [

    {
        title: 'Home',
        classsChange: 'mm-collapse',
        path: '/',
        to: '/',
        cate_type: ''
    },

];

export const MenuListBottom = [

    {
        title: 'Subscription',
        classsChange: 'mm-collapse',
        iconStyle: <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.51221H22V17.6313C22 18.9469 21.745 20.2501 21.2491 21.4688C20.1463 24.1791 17.9307 26.2842 15.1671 27.2474L13.1848 27.9383C12.9522 28.0193 12.6993 28.0206 12.466 27.9419L10.5959 27.311C7.63328 26.3116 5.33455 23.945 4.42206 20.955C4.14224 20.0381 4 19.0847 4 18.1261V7.51221ZM6.21538 9.7271V18.1261C6.21538 18.8657 6.32512 19.6012 6.54101 20.3086C7.24501 22.6154 9.01851 24.4413 11.3042 25.2124L12.8143 25.7218L14.4379 25.1559C16.6003 24.4022 18.3341 22.755 19.1971 20.6341C19.5851 19.6805 19.7846 18.6608 19.7846 17.6313V9.7271H6.21538Z" fill="#1A1A1C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 11.9512H13.9824C15.641 11.9512 16.9855 13.3056 16.9855 14.9765C16.9855 16.6473 15.641 18.0018 13.9824 18.0018H8.84624V11.9512ZM11.0916 14.2131V15.7399H13.9824C14.4009 15.7399 14.7402 15.3981 14.7402 14.9765C14.7402 14.5549 14.4009 14.2131 13.9824 14.2131H11.0916Z" fill="#1A1A1C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 15.7402H12.102C14.8921 15.7402 17.1539 18.0188 17.1539 20.8295H14.9086C14.9086 19.268 13.652 18.0021 12.102 18.0021H11.0916V20.8295H8.84624V15.7402Z" fill="#1A1A1C" />
            <path d="M16.9293 1.84769V3.39273H14.4458L14.82 1.59283L13 0L11.2479 1.59283L11.6222 3.40866H9.1387V1.86361L6.07689 0.557491L6.62121 5.46341H13.034H19.4468L19.923 0.541563L16.9293 1.84769Z" fill="#F6B40C" />
        </svg>,
        iconStyleActive: <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.5127H22V17.6318C22 18.9474 21.745 20.2506 21.2491 21.4693C20.1463 24.1796 17.9307 26.2847 15.1671 27.2479L13.1848 27.9388C12.9522 28.0198 12.6993 28.0211 12.466 27.9424L10.5959 27.3115C7.63328 26.312 5.33455 23.9455 4.42206 20.9555C4.14224 20.0386 4 19.0852 4 18.1265V7.5127ZM6.21538 9.72759V18.1265C6.21538 18.8662 6.32512 19.6017 6.54101 20.3091C7.24501 22.6159 9.01851 24.4418 11.3042 25.2129L12.8143 25.7223L14.4379 25.1564C16.6003 24.4027 18.3341 22.7555 19.1971 20.6346C19.5851 19.681 19.7846 18.6613 19.7846 17.6318V9.72759H6.21538Z" fill="#FFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 11.9512H13.9824C15.641 11.9512 16.9855 13.3056 16.9855 14.9765C16.9855 16.6473 15.641 18.0018 13.9824 18.0018H8.84624V11.9512ZM11.0916 14.2131V15.7399H13.9824C14.4009 15.7399 14.7402 15.3981 14.7402 14.9765C14.7402 14.5549 14.4009 14.2131 13.9824 14.2131H11.0916Z" fill="#FFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 15.7402H12.102C14.8921 15.7402 17.1539 18.0188 17.1539 20.8295H14.9086C14.9086 19.268 13.652 18.0021 12.102 18.0021H11.0916V20.8295H8.84624V15.7402Z" fill="#FFF" />
            <path d="M16.9293 1.84769V3.39273H14.4458L14.82 1.59283L13 0L11.2479 1.59283L11.6222 3.40866H9.1387V1.86361L6.07689 0.557491L6.62121 5.46341H13.034H19.4468L19.923 0.541563L16.9293 1.84769Z" fill="#F6B40C" />
        </svg>,
        path: '/subscription-introduction',
        to: '/subscription-introduction'
    },
    {
        title: 'Service Introduction',
        classsChange: 'mm-collapse',
        iconStyle: <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.51221H22V17.6313C22 18.9469 21.745 20.2501 21.2491 21.4688C20.1463 24.1791 17.9307 26.2842 15.1671 27.2474L13.1848 27.9383C12.9522 28.0193 12.6993 28.0206 12.466 27.9419L10.5959 27.311C7.63328 26.3116 5.33455 23.945 4.42206 20.955C4.14224 20.0381 4 19.0847 4 18.1261V7.51221ZM6.21538 9.7271V18.1261C6.21538 18.8657 6.32512 19.6012 6.54101 20.3086C7.24501 22.6154 9.01851 24.4413 11.3042 25.2124L12.8143 25.7218L14.4379 25.1559C16.6003 24.4022 18.3341 22.755 19.1971 20.6341C19.5851 19.6805 19.7846 18.6608 19.7846 17.6313V9.7271H6.21538Z" fill="#1A1A1C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 11.9512H13.9824C15.641 11.9512 16.9855 13.3056 16.9855 14.9765C16.9855 16.6473 15.641 18.0018 13.9824 18.0018H8.84624V11.9512ZM11.0916 14.2131V15.7399H13.9824C14.4009 15.7399 14.7402 15.3981 14.7402 14.9765C14.7402 14.5549 14.4009 14.2131 13.9824 14.2131H11.0916Z" fill="#1A1A1C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 15.7402H12.102C14.8921 15.7402 17.1539 18.0188 17.1539 20.8295H14.9086C14.9086 19.268 13.652 18.0021 12.102 18.0021H11.0916V20.8295H8.84624V15.7402Z" fill="#1A1A1C" />
            <path d="M16.9293 1.84769V3.39273H14.4458L14.82 1.59283L13 0L11.2479 1.59283L11.6222 3.40866H9.1387V1.86361L6.07689 0.557491L6.62121 5.46341H13.034H19.4468L19.923 0.541563L16.9293 1.84769Z" fill="#F6B40C" />
        </svg>,
        iconStyleActive: <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.5127H22V17.6318C22 18.9474 21.745 20.2506 21.2491 21.4693C20.1463 24.1796 17.9307 26.2847 15.1671 27.2479L13.1848 27.9388C12.9522 28.0198 12.6993 28.0211 12.466 27.9424L10.5959 27.3115C7.63328 26.312 5.33455 23.9455 4.42206 20.9555C4.14224 20.0386 4 19.0852 4 18.1265V7.5127ZM6.21538 9.72759V18.1265C6.21538 18.8662 6.32512 19.6017 6.54101 20.3091C7.24501 22.6159 9.01851 24.4418 11.3042 25.2129L12.8143 25.7223L14.4379 25.1564C16.6003 24.4027 18.3341 22.7555 19.1971 20.6346C19.5851 19.681 19.7846 18.6613 19.7846 17.6318V9.72759H6.21538Z" fill="#FFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 11.9512H13.9824C15.641 11.9512 16.9855 13.3056 16.9855 14.9765C16.9855 16.6473 15.641 18.0018 13.9824 18.0018H8.84624V11.9512ZM11.0916 14.2131V15.7399H13.9824C14.4009 15.7399 14.7402 15.3981 14.7402 14.9765C14.7402 14.5549 14.4009 14.2131 13.9824 14.2131H11.0916Z" fill="#FFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 15.7402H12.102C14.8921 15.7402 17.1539 18.0188 17.1539 20.8295H14.9086C14.9086 19.268 13.652 18.0021 12.102 18.0021H11.0916V20.8295H8.84624V15.7402Z" fill="#FFF" />
            <path d="M16.9293 1.84769V3.39273H14.4458L14.82 1.59283L13 0L11.2479 1.59283L11.6222 3.40866H9.1387V1.86361L6.07689 0.557491L6.62121 5.46341H13.034H19.4468L19.923 0.541563L16.9293 1.84769Z" fill="#F6B40C" />
        </svg>,
        path: '/service-introduction',
        to: '/service-introduction'
    },
    {
        title: 'Official Media',
        classsChange: 'mm-collapse',
        iconStyle: <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.51221H22V17.6313C22 18.9469 21.745 20.2501 21.2491 21.4688C20.1463 24.1791 17.9307 26.2842 15.1671 27.2474L13.1848 27.9383C12.9522 28.0193 12.6993 28.0206 12.466 27.9419L10.5959 27.311C7.63328 26.3116 5.33455 23.945 4.42206 20.955C4.14224 20.0381 4 19.0847 4 18.1261V7.51221ZM6.21538 9.7271V18.1261C6.21538 18.8657 6.32512 19.6012 6.54101 20.3086C7.24501 22.6154 9.01851 24.4413 11.3042 25.2124L12.8143 25.7218L14.4379 25.1559C16.6003 24.4022 18.3341 22.755 19.1971 20.6341C19.5851 19.6805 19.7846 18.6608 19.7846 17.6313V9.7271H6.21538Z" fill="#1A1A1C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 11.9512H13.9824C15.641 11.9512 16.9855 13.3056 16.9855 14.9765C16.9855 16.6473 15.641 18.0018 13.9824 18.0018H8.84624V11.9512ZM11.0916 14.2131V15.7399H13.9824C14.4009 15.7399 14.7402 15.3981 14.7402 14.9765C14.7402 14.5549 14.4009 14.2131 13.9824 14.2131H11.0916Z" fill="#1A1A1C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 15.7402H12.102C14.8921 15.7402 17.1539 18.0188 17.1539 20.8295H14.9086C14.9086 19.268 13.652 18.0021 12.102 18.0021H11.0916V20.8295H8.84624V15.7402Z" fill="#1A1A1C" />
            <path d="M16.9293 1.84769V3.39273H14.4458L14.82 1.59283L13 0L11.2479 1.59283L11.6222 3.40866H9.1387V1.86361L6.07689 0.557491L6.62121 5.46341H13.034H19.4468L19.923 0.541563L16.9293 1.84769Z" fill="#F6B40C" />
        </svg>,
        iconStyleActive: <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.5127H22V17.6318C22 18.9474 21.745 20.2506 21.2491 21.4693C20.1463 24.1796 17.9307 26.2847 15.1671 27.2479L13.1848 27.9388C12.9522 28.0198 12.6993 28.0211 12.466 27.9424L10.5959 27.3115C7.63328 26.312 5.33455 23.9455 4.42206 20.9555C4.14224 20.0386 4 19.0852 4 18.1265V7.5127ZM6.21538 9.72759V18.1265C6.21538 18.8662 6.32512 19.6017 6.54101 20.3091C7.24501 22.6159 9.01851 24.4418 11.3042 25.2129L12.8143 25.7223L14.4379 25.1564C16.6003 24.4027 18.3341 22.7555 19.1971 20.6346C19.5851 19.681 19.7846 18.6613 19.7846 17.6318V9.72759H6.21538Z" fill="#FFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 11.9512H13.9824C15.641 11.9512 16.9855 13.3056 16.9855 14.9765C16.9855 16.6473 15.641 18.0018 13.9824 18.0018H8.84624V11.9512ZM11.0916 14.2131V15.7399H13.9824C14.4009 15.7399 14.7402 15.3981 14.7402 14.9765C14.7402 14.5549 14.4009 14.2131 13.9824 14.2131H11.0916Z" fill="#FFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 15.7402H12.102C14.8921 15.7402 17.1539 18.0188 17.1539 20.8295H14.9086C14.9086 19.268 13.652 18.0021 12.102 18.0021H11.0916V20.8295H8.84624V15.7402Z" fill="#FFF" />
            <path d="M16.9293 1.84769V3.39273H14.4458L14.82 1.59283L13 0L11.2479 1.59283L11.6222 3.40866H9.1387V1.86361L6.07689 0.557491L6.62121 5.46341H13.034H19.4468L19.923 0.541563L16.9293 1.84769Z" fill="#F6B40C" />
        </svg>,
        path: '/official-media',
        to: '/official-media'
    },
    {
        title: 'My Page',
        classsChange: 'mm-collapse',
        iconStyle: <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.51221H22V17.6313C22 18.9469 21.745 20.2501 21.2491 21.4688C20.1463 24.1791 17.9307 26.2842 15.1671 27.2474L13.1848 27.9383C12.9522 28.0193 12.6993 28.0206 12.466 27.9419L10.5959 27.311C7.63328 26.3116 5.33455 23.945 4.42206 20.955C4.14224 20.0381 4 19.0847 4 18.1261V7.51221ZM6.21538 9.7271V18.1261C6.21538 18.8657 6.32512 19.6012 6.54101 20.3086C7.24501 22.6154 9.01851 24.4413 11.3042 25.2124L12.8143 25.7218L14.4379 25.1559C16.6003 24.4022 18.3341 22.755 19.1971 20.6341C19.5851 19.6805 19.7846 18.6608 19.7846 17.6313V9.7271H6.21538Z" fill="#1A1A1C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 11.9512H13.9824C15.641 11.9512 16.9855 13.3056 16.9855 14.9765C16.9855 16.6473 15.641 18.0018 13.9824 18.0018H8.84624V11.9512ZM11.0916 14.2131V15.7399H13.9824C14.4009 15.7399 14.7402 15.3981 14.7402 14.9765C14.7402 14.5549 14.4009 14.2131 13.9824 14.2131H11.0916Z" fill="#1A1A1C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 15.7402H12.102C14.8921 15.7402 17.1539 18.0188 17.1539 20.8295H14.9086C14.9086 19.268 13.652 18.0021 12.102 18.0021H11.0916V20.8295H8.84624V15.7402Z" fill="#1A1A1C" />
            <path d="M16.9293 1.84769V3.39273H14.4458L14.82 1.59283L13 0L11.2479 1.59283L11.6222 3.40866H9.1387V1.86361L6.07689 0.557491L6.62121 5.46341H13.034H19.4468L19.923 0.541563L16.9293 1.84769Z" fill="#F6B40C" />
        </svg>,
        iconStyleActive: <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.5127H22V17.6318C22 18.9474 21.745 20.2506 21.2491 21.4693C20.1463 24.1796 17.9307 26.2847 15.1671 27.2479L13.1848 27.9388C12.9522 28.0198 12.6993 28.0211 12.466 27.9424L10.5959 27.3115C7.63328 26.312 5.33455 23.9455 4.42206 20.9555C4.14224 20.0386 4 19.0852 4 18.1265V7.5127ZM6.21538 9.72759V18.1265C6.21538 18.8662 6.32512 19.6017 6.54101 20.3091C7.24501 22.6159 9.01851 24.4418 11.3042 25.2129L12.8143 25.7223L14.4379 25.1564C16.6003 24.4027 18.3341 22.7555 19.1971 20.6346C19.5851 19.681 19.7846 18.6613 19.7846 17.6318V9.72759H6.21538Z" fill="#FFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 11.9512H13.9824C15.641 11.9512 16.9855 13.3056 16.9855 14.9765C16.9855 16.6473 15.641 18.0018 13.9824 18.0018H8.84624V11.9512ZM11.0916 14.2131V15.7399H13.9824C14.4009 15.7399 14.7402 15.3981 14.7402 14.9765C14.7402 14.5549 14.4009 14.2131 13.9824 14.2131H11.0916Z" fill="#FFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 15.7402H12.102C14.8921 15.7402 17.1539 18.0188 17.1539 20.8295H14.9086C14.9086 19.268 13.652 18.0021 12.102 18.0021H11.0916V20.8295H8.84624V15.7402Z" fill="#FFF" />
            <path d="M16.9293 1.84769V3.39273H14.4458L14.82 1.59283L13 0L11.2479 1.59283L11.6222 3.40866H9.1387V1.86361L6.07689 0.557491L6.62121 5.46341H13.034H19.4468L19.923 0.541563L16.9293 1.84769Z" fill="#F6B40C" />
        </svg>,
        path: '/my-page',
        to: '/my-page'
    },
    {
        title: 'Account Management',
        classsChange: 'mm-collapse',
        iconStyle: <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.51221H22V17.6313C22 18.9469 21.745 20.2501 21.2491 21.4688C20.1463 24.1791 17.9307 26.2842 15.1671 27.2474L13.1848 27.9383C12.9522 28.0193 12.6993 28.0206 12.466 27.9419L10.5959 27.311C7.63328 26.3116 5.33455 23.945 4.42206 20.955C4.14224 20.0381 4 19.0847 4 18.1261V7.51221ZM6.21538 9.7271V18.1261C6.21538 18.8657 6.32512 19.6012 6.54101 20.3086C7.24501 22.6154 9.01851 24.4413 11.3042 25.2124L12.8143 25.7218L14.4379 25.1559C16.6003 24.4022 18.3341 22.755 19.1971 20.6341C19.5851 19.6805 19.7846 18.6608 19.7846 17.6313V9.7271H6.21538Z" fill="#1A1A1C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 11.9512H13.9824C15.641 11.9512 16.9855 13.3056 16.9855 14.9765C16.9855 16.6473 15.641 18.0018 13.9824 18.0018H8.84624V11.9512ZM11.0916 14.2131V15.7399H13.9824C14.4009 15.7399 14.7402 15.3981 14.7402 14.9765C14.7402 14.5549 14.4009 14.2131 13.9824 14.2131H11.0916Z" fill="#1A1A1C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 15.7402H12.102C14.8921 15.7402 17.1539 18.0188 17.1539 20.8295H14.9086C14.9086 19.268 13.652 18.0021 12.102 18.0021H11.0916V20.8295H8.84624V15.7402Z" fill="#1A1A1C" />
            <path d="M16.9293 1.84769V3.39273H14.4458L14.82 1.59283L13 0L11.2479 1.59283L11.6222 3.40866H9.1387V1.86361L6.07689 0.557491L6.62121 5.46341H13.034H19.4468L19.923 0.541563L16.9293 1.84769Z" fill="#F6B40C" />
        </svg>,
        iconStyleActive: <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.5127H22V17.6318C22 18.9474 21.745 20.2506 21.2491 21.4693C20.1463 24.1796 17.9307 26.2847 15.1671 27.2479L13.1848 27.9388C12.9522 28.0198 12.6993 28.0211 12.466 27.9424L10.5959 27.3115C7.63328 26.312 5.33455 23.9455 4.42206 20.9555C4.14224 20.0386 4 19.0852 4 18.1265V7.5127ZM6.21538 9.72759V18.1265C6.21538 18.8662 6.32512 19.6017 6.54101 20.3091C7.24501 22.6159 9.01851 24.4418 11.3042 25.2129L12.8143 25.7223L14.4379 25.1564C16.6003 24.4027 18.3341 22.7555 19.1971 20.6346C19.5851 19.681 19.7846 18.6613 19.7846 17.6318V9.72759H6.21538Z" fill="#FFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 11.9512H13.9824C15.641 11.9512 16.9855 13.3056 16.9855 14.9765C16.9855 16.6473 15.641 18.0018 13.9824 18.0018H8.84624V11.9512ZM11.0916 14.2131V15.7399H13.9824C14.4009 15.7399 14.7402 15.3981 14.7402 14.9765C14.7402 14.5549 14.4009 14.2131 13.9824 14.2131H11.0916Z" fill="#FFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84624 15.7402H12.102C14.8921 15.7402 17.1539 18.0188 17.1539 20.8295H14.9086C14.9086 19.268 13.652 18.0021 12.102 18.0021H11.0916V20.8295H8.84624V15.7402Z" fill="#FFF" />
            <path d="M16.9293 1.84769V3.39273H14.4458L14.82 1.59283L13 0L11.2479 1.59283L11.6222 3.40866H9.1387V1.86361L6.07689 0.557491L6.62121 5.46341H13.034H19.4468L19.923 0.541563L16.9293 1.84769Z" fill="#F6B40C" />
        </svg>,
        path: '/account-management',
        to: '/account-management'
    },
];