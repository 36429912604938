import { createSlice } from "@reduxjs/toolkit";
import { ICategory } from "api/types/_category";
import { allCategoryMenu, allMenu, allSubscription } from "./thunk";
import { ISubscription } from "api/types/_subscription";
export interface IState {
  menu: ICategory[] | null,
  isMenuLoading: boolean,
  isMenuSuccess: boolean,

  menuNew: ICategory[] | null,
  isMenuNewLoading: boolean,
  isMenuNewSuccess: boolean,

  menuSideBar: ICategory[] | null,

  subscriptions: ISubscription[] | null,
  isSubscriptionLoading: boolean,
  isSubscriptionSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  menu: null,
  isMenuLoading: false,
  isMenuSuccess: false,

  menuNew: null,
  isMenuNewLoading: false,
  isMenuNewSuccess: false,

  menuSideBar: [],

  subscriptions: null,
  isSubscriptionLoading: false,
  isSubscriptionSuccess: false,

  error: {},
};

const RankingSlice = createSlice({
  name: "Master",
  initialState,
  reducers: {
    saveMenuSideBarAction(state: any, action : any) {
      state.menuSideBar = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(allCategoryMenu.pending, (state: IState, action: any) => {
      state.isMenuLoading = true
    });
    builder.addCase(allCategoryMenu.fulfilled, (state: IState, action: any) => {
      state.menu = action.payload.data;
      state.isMenuSuccess = true;
      state.isMenuLoading = false;
    });
    builder.addCase(allCategoryMenu.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMenuSuccess = false;
      state.isMenuLoading = false;
    });

    builder.addCase(allMenu.pending, (state: IState, action: any) => {
      state.isMenuNewLoading = true
    });
    builder.addCase(allMenu.fulfilled, (state: IState, action: any) => {
      state.menuNew = action.payload.data;
      state.isMenuNewSuccess = true;
      state.isMenuNewLoading = false;
    });
    builder.addCase(allMenu.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMenuNewSuccess = false;
      state.isMenuNewLoading = false;
    });

    //Subscriptions
    builder.addCase(allSubscription.pending, (state: IState, action: any) => {
      state.isSubscriptionLoading = true
    });
    builder.addCase(allSubscription.fulfilled, (state: IState, action: any) => {
      state.subscriptions = action.payload.data;
      state.isSubscriptionSuccess = true;
      state.isSubscriptionLoading = false;
    });
    builder.addCase(allSubscription.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSubscriptionSuccess = false;
      state.isSubscriptionLoading = false;
    });

  },
});

export const { saveMenuSideBarAction } = RankingSlice.actions;

export default RankingSlice.reducer;