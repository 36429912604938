import React from "react";

const Loading = ({ isLoading = false, textEndLoading = '', className = '' }) => {
    return (
        <div className={`d-flex m-auto text-center align-items-center justify-content-center pt-3 w-100 ${className}`}>
            {isLoading ? <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <g transform="translate(20 50)">
                    <circle cx="0" cy="0" r="10" fill="#020220">
                        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    </circle>
                </g><g transform="translate(40 50)">
                    <circle cx="0" cy="0" r="10" fill="#020220">
                        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    </circle>
                </g><g transform="translate(60 50)">
                    <circle cx="0" cy="0" r="10" fill="#020220">
                        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    </circle>
                </g><g transform="translate(80 50)">
                    <circle cx="0" cy="0" r="10" fill="#020220">
                        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    </circle>
                </g>
            </svg> : <div className="text-center">
                {textEndLoading || 'No data'}
            </div>}
        </div>
    );
};

export default React.memo(Loading);
