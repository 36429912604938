import { formatNumberWithCommas } from 'helpers/format';
import React from 'react';
import { Button, Col, Container, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ModalNotiVote = ({ isLoading, keywordVotedInfo = null, setKeywordVotedInfo, isShow = false }: any) => {
    const { t, i18n } = useTranslation();

    const onAction = () => {
        setKeywordVotedInfo && setKeywordVotedInfo((_prev: any) => (_prev?.step === 2 ? null : { ..._prev, step: 2 }))
    }
    return (
        <>
            <Modal id="modal-noti-vote" className="fade bd-example-modal-lg" show={!!isShow} centered>
                <Modal.Header className="border-0 pt-4">
                    <Modal.Title className="text-center m-auto">
                        {
                            keywordVotedInfo?.step === 2 ? <>
                                {t('The vote has been completed!')}
                            </> :
                                (
                                    i18n?.language === 'en' ? <>
                                        {t('We delivered')} {formatNumberWithCommas(keywordVotedInfo?.history?.ticket_amount || 0)} {t('votes to')} {keywordVotedInfo?.keyword?.keyword || ''}
                                    </> : <>
                                        {keywordVotedInfo?.keyword?.keyword || ''} {t('votes to')} {formatNumberWithCommas(keywordVotedInfo?.history?.ticket_amount || 0)} {t('We delivered')}
                                    </>
                                )
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-2 pt-2">
                    <Container className="px-0">
                        <Col sm="12" md="12" >
                            {keywordVotedInfo?.step === 2 ? <div className="d-flex justify-content-around align-items-center py-2">
                                {t('Your vote will be reflected in the rankings after one hour.')}
                            </div> : (<div className="d-flex justify-content-around align-items-center py-2">
                                <div className="text-center">
                                    <div className="mb-0 text-center">{t('Participation Index')}</div>
                                    <h3 className="mb-0 text-center">{formatNumberWithCommas(keywordVotedInfo?.p_index_before || 0)}</h3>
                                </div>
                                <div className="text-center">
                                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.7725 23.4657L8.3261 16.2228C7.83674 15.7468 7 16.0839 7 16.7571V31.2429C7 31.9161 7.83674 32.2532 8.3261 31.7772L15.7725 24.5343C16.0758 24.2392 16.0758 23.7608 15.7725 23.4657Z" fill="#FFD466" />
                                        <path d="M27.7725 23.4657L20.3261 16.2228C19.8367 15.7468 19 16.0839 19 16.7571V31.2429C19 31.9161 19.8367 32.2532 20.3261 31.7772L27.7725 24.5343C28.0758 24.2392 28.0758 23.7608 27.7725 23.4657Z" fill="#FFBE1A" />
                                        <path d="M39.7725 23.4657L32.3261 16.2228C31.8367 15.7468 31 16.0839 31 16.7571V31.2429C31 31.9161 31.8367 32.2532 32.3261 31.7772L39.7725 24.5343C40.0758 24.2392 40.0758 23.7608 39.7725 23.4657Z" fill="#E7A600" />
                                    </svg>
                                </div>
                                <div className="text-center">
                                    <div className="mb-0 text-center">{t('Participation Index')}</div>
                                    <h3 className="mb-0 text-center">{formatNumberWithCommas(keywordVotedInfo?.p_index_after || 0)}</h3>
                                </div>
                            </div>)
                            }
                        </Col>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="text-center border-0 pb-4">
                    <Button
                        variant=""
                        type="button"
                        className="btn btn-md m-0 btn-block btn-primary m-auto"
                        onClick={onAction}
                        disabled={isLoading}
                        style={{ borderRadius: '12px', width: '150px' }}
                    >
                        {isLoading ? <Spinner size="sm"></Spinner> : t('Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default React.memo(ModalNotiVote);