import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Spinner } from 'react-bootstrap';

const CustomizedButtons = styled(Button)(({ variant }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 800,
  padding: '16px 24px',
  lineHeight: '150%',
  borderRadius: '50px',
  // flex: '1 0 auto',
  display: 'grid',
  alignItems: 'center',
  alignContent: 'center',
  width: 'auto',
  fontFamily: [
    'NanumSquare',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '@media (max-width: 1800px)': {
    fontSize: '16px',
    fontWeight: 800,
    padding: '12px 24px',
  },
  '@media (max-width: 800px)': {
    fontSize: '15px',
    fontWeight: 800,
    padding: '16px 24px',
    width: '50%',
  },
  '@media (max-width: 500px)': {
    fontSize: '16px',
    fontWeight: 800,
    width: '100%',
  },
  ...(variant === 'contained' && {
    backgroundColor: '#FFFFFF',  // Màu nền trắng
    borderColor: '#FFFFFF',  // Màu viền trắng
    color: '#3E3F46',  // Màu chữ
    '&:hover': {
      backgroundColor: '#f0f0f0',  // Màu nền khi hover
      borderColor: '#f0f0f0',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#e0e0e0',  // Màu nền khi nhấn
      borderColor: '#e0e0e0',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(62, 63, 70, 0.5)',  // Đổi màu khi focus
    },
  }),
  ...(variant === 'outlined' && {
    backgroundColor: 'transparent',
    borderColor: '#3E3F46',  // Màu viền
    color: '#3E3F46',  // Màu chữ
    '&:hover': {
      backgroundColor: 'rgba(62, 63, 70, 0.1)',  // Màu nền khi hover
      borderColor: '#3E3F46',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(62, 63, 70, 0.2)',  // Màu nền khi nhấn
      borderColor: '#3E3F46',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(62, 63, 70, 0.5)',  // Đổi màu khi focus
    },
  }),
}));

export default function ButtonWhite({ text, isLoading, variant = 'contained', isIcon = false, Icon = undefined, iconError = true, ...props }: any) {
  return (
    <CustomizedButtons variant={variant} {...props}>
      {isLoading ? <div style={{ transform: 'translateY(3px)' }}><Spinner size="sm" /></div> : <div className="d-flex justify-content-center align-items-center" style={{ lineHeight: '24px' }}>{isIcon ? (Icon ? Icon : <></>) : <></>} <div>{text}</div></div>}
    </CustomizedButtons>
  );
}

