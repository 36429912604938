import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Error404 = () => {
   const { t } = useTranslation();
   return (
      <div className="authincation fix-wrapper">
         <div className="container">
            <div className="row justify-content-center h-100 align-items-center">
               <div className="col-md-6">
                  <div className="error-page">
                     <div className="error-inner text-center">
                        <div className="dz-error" data-text="404">404</div>
                        <h2 className="error-head mb-0">{t('The page you were looking for is not found!')}</h2>
                        <p>{t('You may have mistyped the address or the page may have moved.')}</p>
                        <Link to={"/"} className="btn btn-primary">{t('BACK TO HOMEPAGE')}</Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

   );
};

export default Error404;
