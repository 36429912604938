import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { FREQUENCY_TYPE } from 'helpers/constans';
import moment from 'moment';
import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';

interface RangeDatePickerProps {
    frequencyTypeSearch: { value: string } | null;
    dateSearch: string | null;
    handleChangeDatePicker: (date: Date) => void;
    intervalTimeSearch: string;
    className?: string,
}

registerLocale("en", en as any);
registerLocale("ko", ko as any);

const RangeDatePicker: React.FC<RangeDatePickerProps> = ({
    frequencyTypeSearch,
    dateSearch,
    handleChangeDatePicker,
    intervalTimeSearch,
    className = 'form-control-sm form-height-38',
}) => {
    const { t, i18n } = useTranslation();

    const isWeekly = String(frequencyTypeSearch?.value) === String(FREQUENCY_TYPE.WEEKLY);
    const isMonthly = String(frequencyTypeSearch?.value) === String(FREQUENCY_TYPE.MONTHLY);

    // Calculate the maxDate based on frequency type and today's date
    let maxDate = moment().subtract(2, 'days').toDate();

    const getWeekOfMonth = (date: Date) => {
        const startWeek = moment(date).startOf('month').week();
        const currentWeek = moment(date).week();
        return currentWeek - startWeek + 1;
    };

    const getDateFormat = () => {
        if (isMonthly) {
            return 'yyyy-MM';
        } else if (isWeekly) {
            const weekOfMonth = dateSearch ? getWeekOfMonth(new Date(dateSearch)) : '';
            return `yyyy-MM ${weekOfMonth}'${t('weeks')}'`;
        } else {
            return 'yyyy-MM-dd';
        }
    };

    return (
        <DatePicker
            className={`form-control  bt-datepicker fs-14  ${className}`}
            popperClassName="form-control-popper-date-picker-range"
            placeholderText={t('Date')}
            selected={dateSearch ? new Date(dateSearch) : undefined}
            dateFormat={getDateFormat()}
            calendarStartDay={isWeekly ? 1 : 0}
            showWeekNumbers={isWeekly}
            weekLabel={t('Week')}
            onWeekSelect={(firstDayOfWeek: Date, weekNumber: number, event: React.SyntheticEvent) => {
                handleChangeDatePicker(firstDayOfWeek);
            }}
            showMonthYearPicker={isMonthly}
            showFullMonthYearPicker={isMonthly}
            showFourColumnMonthYearPicker={isMonthly}
            isClearable={false}
            locale={i18n.language === 'ko' ? 'ko' : 'en'}
            onChange={(date: Date) => handleChangeDatePicker(date)}
            maxDate={maxDate}
            disabled={intervalTimeSearch !== 'SE'}
        />
    );
};

export default RangeDatePicker;
