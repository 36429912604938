import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { IMAGES } from '../../constant/theme';
import { useEffect } from 'react';
import { TYPE_MESSAGES } from 'helpers/constans';
import PaymentNotification from './components/PaymentNotification';
import { useQueryParams, withDefault, StringParam } from 'use-query-params';

const Payment: React.FC = (props) => {
	const { t, i18n } = useTranslation();
	const { type } = useParams();
	const [query, setQuery] = useQueryParams({ payment_id: withDefault(StringParam, '') });

	useEffect(() => {
		if(query?.payment_id){
			const messagePost = type === 'success' ? t('Transaction successful.') : t('Transaction failed');
			const typePost = type === 'success' ? TYPE_MESSAGES.PAYMENT_SUCCESS : TYPE_MESSAGES.PAYMENT_CANCEL;
			const channel = new BroadcastChannel('my_channel_payment');
			channel.postMessage({ type: typePost, payment_id: query?.payment_id, message: messagePost });
			window.close();
		}
	}, [query?.payment_id]);

	return (
		<>
			<div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
				<div className="login-aside text-center  d-flex flex-column flex-row-auto justify-content-center">
					<div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
						<div className="text-center mb-lg-4 mb-2 pt-4 pt-md-0 logo">
							<Link to="/" className="d-block d-sm-none" >
								<img src={IMAGES.LogoRankifyDark} alt="logo" style={{ width: 'auto' }} />
							</Link>
							<Link to="/" className="d-none d-sm-block">
								<img src={IMAGES.LogoRankifyLight} alt="logo" style={{ width: 'auto' }} />
							</Link>
						</div>
						<h3 className="mb-2 text-white d-none d-sm-block">{t('Welcome back!')}</h3>
						<p className="mb-4 d-none d-sm-block">{t('Experience various indicators in different fields now')}</p>
					</div>
				</div>
				<div className="container flex-row-fluid d-flex flex-column justify-content-start justify-content-sm-center position-relative overflow-hidden p-7 mx-auto">
					<div className="d-flex justify-content-center h-100 align-items-center">
						<div className="authincation-content style-2">
							<div className="row no-gutters">
								<div className="col-xl-12 tab-content">
									<div id="sign-up" className="auth-form tab-pane fade show active  form-validation">
										<div className="mb-4">
											<PaymentNotification type={type || ''} />
										</div>
										<div className="new-account mt-3 text-center">
											<p className="font-w500"><Link className="text-primary text-decoration-underline" to="/">{t('Rankify Dashboard')}</Link></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Payment;
