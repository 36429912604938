import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  var d = new Date();
  const { t, i18n } = useTranslation();
  return (
    <div className="footer">
      <div className="copyright mt-0">
        <p>
          {t('Copyright')} © Rankify {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
