
import axios from "axios";
import { LOGIN_TYPE } from "helpers/constans";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginSocial } from "store/thunks";

const KadaoCallBack: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { t, i18n } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const clientId = process.env.REACT_APP_KAKAO_REST_API_KEY || "";
  const redirectUri = `${window.location.origin}/kakaocallback`;


  const verifyKadao = async () => {
    try {
      if (!code) {
        return;
      }
      const requestData = new URLSearchParams();
      requestData.append("grant_type", "authorization_code");
      requestData.append("client_id", clientId);
      requestData.append("redirect_uri", redirectUri);
      requestData.append("code", code);
      const res_kakao = await axios.post("https://kauth.kakao.com/oauth/token", requestData);
      if (res_kakao?.status === 200) {
        dispatch(loginSocial({
          token: res_kakao?.data?.access_token,
          platform: LOGIN_TYPE.KAKAO
        }, navigate, '/'));

      }
    } catch (error) {
      console.log('error', error);
      //window.close();
    }
  };

  useEffect(() => {
    verifyKadao();
  }, []);

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center bg-white">
      <div className="text-center">
        <Spinner size="sm" style={{ color: "#F3DF4A" }}></Spinner>
        <div style={{ color: '#0C0C0C' }}>
          {t('Processing authentication information, please wait.')}...
        </div>
      </div>
    </div>
  );
};

export default KadaoCallBack;
