import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  sideMenu: false,
  error: "", // for error message
};

const layoutSlice  = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleMenu(state, action) {
      state.sideMenu = !state?.sideMenu; // action?.payload;
    },
  },
});

export const {
  toggleMenu,
} = layoutSlice.actions

export default layoutSlice.reducer;