import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CustomSkeleton = ({ children = <></>, isLoading = false, isDark = false, height = 30 }) => {
  const baseColor = isDark ? '#202020' : '#eeeeee';
  const highlightColor = isDark ? '#444' : '#f9f9f9';
  return (
    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
      <>{isLoading ? <Skeleton height={height} /> : children}</>
    </SkeletonTheme>
  );
}

export default React.memo(CustomSkeleton);
