import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Spinner } from 'react-bootstrap';

const CustomizedButtons = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '0.813rem',
  padding: '0.57rem 1rem',
  // border: '1px solid',
  borderRadius: '0.5rem',
  // lineHeight: 1.5,
  flex: '1 0 auto',
  display: 'grid',
  alignItems: 'center',
  alignContent: 'center',
  backgroundColor: '#e6e6e6',
  borderColor: '#e6e6e6',
  color: '#000000',
  fontFamily: [
    'NanumSquare',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#cccccc',
    borderColor: '#cccccc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#b3b3b3',
    borderColor: '#b3b3b3',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(230, 230, 230, 0.5)',
  },
});

export default function ButtonSecondary({ text, isLoading, ...props }: any) {
  return (
    <CustomizedButtons variant="contained"  {...props}>
      {isLoading ? <div style={{ transform: 'translateY(3px)' }}><Spinner size="sm"></Spinner></div> : text}
    </CustomizedButtons>
  );
}
