import { TYPE_PARTICIPATION_TEMPLATE_IS_SURVEY, TYPE_PARTICIPATION_TEMPLATE_IS_VOTE } from "helpers/constans";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Body from "./Body";
import Header from "./Header";
// import { useDraggable } from "react-use-draggable-scroll";

const TableContainer = ({ query = {}, data = [], categoryType, categoryGroupType, frequencyType, intervalTimeSearch, isCollapse, setIsCollapse, typeShowParticipation = false, sortColumnTableRanking = [], handleChangeSorting, handleVote, handleSurvey, handleShowMore, handleSetKeywordDetail, handleSetKeywordInfo }: any) => {

    const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false);
    const [scrollLeft, setScrollLeft] = useState<any>(0);

    // const [isCollapse, setIsCollapse] = useState<boolean>(false);

    const thRefs = useRef<any>([]);
    const tableRef = useRef<any>(null);
    const warperTableRef = useRef<any>(null);
    const elementFixedRef = useRef<any>(null);
    const elementHeaderFixedRef = useRef<any>(null);
    const elementTable: any = tableRef?.current?.getBoundingClientRect() || {};
    const elementWarperTable: any = warperTableRef?.current?.getBoundingClientRect() || {};

    const [isShowHeaderTableFixed, setIsShowHeaderTableFixed] = useState<boolean>(false);

    // const { events } = useDraggable(warperTableRef);

    const onSorting = (sort: any) => {
        handleChangeSorting && handleChangeSorting(sort);
    };
    const onAction = (info: any) => {
        if (String(typeShowParticipation) === TYPE_PARTICIPATION_TEMPLATE_IS_VOTE) {
            handleVote && handleVote(info);
            return;
        }
        if (String(typeShowParticipation) === TYPE_PARTICIPATION_TEMPLATE_IS_SURVEY) {
            handleSurvey && handleSurvey(info);
        }
    };

    const onShowMore = (more: any, level_sub: number = 1) => {
        handleShowMore && handleShowMore(more, level_sub);
    };
    const onSetKeywordDetail = (detail: any) => {
        handleSetKeywordDetail && handleSetKeywordDetail(detail);
    };
    const onSetKeywordInfo = (detail: any) => {
        handleSetKeywordInfo && handleSetKeywordInfo(detail);
    };


    const NUMBER_PLUS = isShowHeaderTableFixed ? 1 : 0;

    const handleScroll = (e: any) => {
        setScrollLeft((prev: any) => e?.target?.scrollLeft)
        if (warperTableRef?.current && elementFixedRef?.current) {
            // If warperTableRef scrolls, then scroll elementFixedRef as well
            if (e.target === warperTableRef.current) {
                elementFixedRef.current.scrollLeft = e?.target?.scrollLeft;
                if (elementHeaderFixedRef?.current) {
                    elementHeaderFixedRef.current.scrollLeft = e?.target?.scrollLeft + NUMBER_PLUS;
                }

            }
            // If elementFixedRef scrolls, then scroll warperTableRef as well
            else if (e.target === elementFixedRef.current) {
                warperTableRef.current.scrollLeft = e?.target?.scrollLeft;
            }
        }

    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document?.documentElement?.scrollTop || 0;
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const MARGIN_BOTTOM_HEIGHT = 170; // 170px
            if ((scrollTop + windowHeight + MARGIN_BOTTOM_HEIGHT) >= documentHeight) {
                setIsScrollBottom((prev) => true);
            } else {
                setIsScrollBottom((prev) => false);
            }

            const rect = warperTableRef.current.getBoundingClientRect();
            const distanceFromTop = rect.top;
            if (distanceFromTop <= 82 && elementHeaderFixedRef?.current) {
                elementHeaderFixedRef.current.scrollLeft = elementFixedRef?.current?.scrollLeft + NUMBER_PLUS;
                setIsShowHeaderTableFixed((prev) => true);
            } else {
                setIsShowHeaderTableFixed((prev) => false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const columnTableRanking = useMemo(() => {
        return sortColumnTableRanking?.filter((column: any) => !!column?.isShow);
    }, [sortColumnTableRanking]);

    const handleSetIsCollapse = () => {
        // setIsCollapse((_prev) => !_prev);
        setIsCollapse && setIsCollapse();
    };

    return (
        <>
            <div className="overflow-x-auto overflow-y-hidden position-relative" ref={warperTableRef} onScroll={handleScroll} >
                <table className={`table table-bordered display mb-0 orderbookTable dataTable no-footer sticky-table-ranking-keyword table-ranking-keyword ${Number(scrollLeft || 0) > 0 && 'is-scroll'}`} ref={tableRef}>
                    <Header isCollapse={isCollapse} columnTableRanking={columnTableRanking} ref={thRefs} query={query} frequencyType={frequencyType} intervalTimeSearch={intervalTimeSearch} categoryType={categoryType} categoryGroupType={categoryGroupType} handleChangeSorting={onSorting} typeShowParticipation={typeShowParticipation} handleSetIsCollapse={handleSetIsCollapse} />
                    <Body isCollapse={isCollapse} columnTableRanking={columnTableRanking} data={data} frequencyType={frequencyType} intervalTimeSearch={intervalTimeSearch} categoryType={categoryType} categoryGroupType={categoryGroupType} handleAction={onAction} handleShowMore={onShowMore} onSetKeywordDetail={onSetKeywordDetail} onSetKeywordInfo={onSetKeywordInfo} typeShowParticipation={typeShowParticipation} />
                </table>
            </div>
            {!!(window.innerWidth > 600) && <div
                className={`table-custom-pagination overflow-x-auto m-0 hidden-scrollbar-os ${isShowHeaderTableFixed ? '' : 'd-none'}`}
                style={{
                    position: 'fixed',
                    top: 'var(--header-height)',
                    zIndex: 9,
                    left: elementWarperTable?.left,
                    width: elementWarperTable?.width,
                    right: 0,
                    tableLayout: 'fixed',
                    border: 'var(--bs-border-width) solid var(--border)'
                }}
                ref={elementHeaderFixedRef}
            >
                <table className={`table table-bordered table-bordered-none-border-header  display mb-0 orderbookTable dataTable table-fixed-header no-footer sticky-table-ranking-keyword table-ranking-keyword ${Number(scrollLeft || 0) > 0 && 'is-scroll'}`} style={{ height: 'auto', tableLayout: 'fixed', }}>
                    <Header isCollapse={isCollapse} columnTableRanking={columnTableRanking} widthColumn={thRefs?.current} style={{ width: elementTable?.width }} query={query} frequencyType={frequencyType} intervalTimeSearch={intervalTimeSearch} categoryType={categoryType} categoryGroupType={categoryGroupType} handleChangeSorting={onSorting} typeShowParticipation={typeShowParticipation} handleSetIsCollapse={handleSetIsCollapse} />
                </table>
            </div>}
            <div
                className="overflow-x-auto color-scrollbar-os"
                style={{
                    position: 'fixed',
                    zIndex: 999,
                    left: elementWarperTable?.left,
                    bottom: 2,
                    width: elementWarperTable?.width,
                    right: 0,
                    // opacity: isScrollBottom ? 0 : 1,
                }}
                ref={elementFixedRef}
                onScroll={handleScroll}
            >
                <div style={{ width: `${elementTable?.width}px`, height: '10px' }}></div>
            </div>
        </>
    );
};

export default React.memo(TableContainer);
