import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { useContext, useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

/// Image

import { CATEGORY_TYPE, COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS, PLAN_TYPE_SUBSCRIPTION_OPTIONS, TYPE_SUBSCRIPTION_OPTIONS } from "helpers/constans";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import ButtonPrimary from "ui/components/buttons/ButtonPrimary";
import useToastNotifications from "ui/components/Hooks/useToastNotifications";
import { ThemeContext } from "../../../context/ThemeContext";
import { SVGICON } from "../../constant/theme";
import LanguageDropdown from "./LanguageDropdown";
import Logout from "./Logout";
import { MenuList, MenuListBottom } from "./Menu";


const Header = ({ onNote }: any) => {
  const { t, i18n } = useTranslation();
  const headerRef = useRef(null);
  const [headerFix, setheaderFix] = useState(false);
  const user: any = useSelector((state: any) => state?.Login);
  const navigate = useNavigate();
  const { notify } = useToastNotifications();

  const [searchParams] = useSearchParams();
  const category_id = searchParams.get('category_id');
  const category_level_1 = searchParams.get('category_level_1');
  const category_level_2 = searchParams.get('category_level_2');
  const category_level_3 = searchParams.get('category_level_3');
  const category_level_4 = searchParams.get('category_level_4');
  const level_sub = searchParams.get('level_sub');


  const TYPE_SUBSCRIPTION_OPTIONS_LANG = TYPE_SUBSCRIPTION_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
  const PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG = PLAN_TYPE_SUBSCRIPTION_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const selectLayoutState = (state: any) => state.Master;

  const MasterProperties = createSelector(selectLayoutState, (state) => ({
    menu: state.menu,
    isMenuSuccess: state.isMenuSuccess,
    isMenuLoading: state.isMenuLoading,
    menuSideBar: state.menuSideBar,
    error: state.error,
  }));
  const { menu, menuSideBar, isMenuLoading } = useSelector(MasterProperties);


  const [menuList, setMenuList] = useState<any>(
    [...menuSideBar, ...MenuListBottom] || []
  );

  function CommanScroll() {
    setheaderFix(window.scrollY > 50);
  }

  useEffect(() => {
    if (menuSideBar?.length) {
      setMenuList((_prev: any) => ([...menuSideBar, ...MenuListBottom]));
    }
  }, [menuSideBar]);

  useEffect(() => {
    window.addEventListener("scroll", CommanScroll);
    return () => {
      window.removeEventListener("scroll", CommanScroll);
    };
  }, []);

  const { background, changeBackground, headWallet, setHeadWallet } = useContext(ThemeContext);

  const handleThemeMode = () => {
    if (background.value === "dark") {
      changeBackground({ value: "light", label: "Light" });
    } else {
      changeBackground({ value: "dark", label: "Dark" });
    }
  };

  const path_full = window.location.pathname;
  const pathUrl = window.location.pathname.split("/");
  const path = pathUrl[pathUrl.length - 1];

  const press = menuList?.reduce((arr: any, item: any) => {
    return item?.subs ? arr.concat([item, ...item?.subs]) : arr.concat([item]);
  }, []);

  const menuActive = menuList?.find((item: any) => {
    return String(path_full) === String(item?.path);
  });

  const navigationLevel_0 = menuActive || null;
  const navigationLevel_1 = category_level_1 ? navigationLevel_0?.subs?.find((item: any) => (String(item?.id) === String(category_level_1) || ((String(item?.category_group_type) === String(CATEGORY_TYPE.STOCK)) && String(item?.queries?.level_3_category_id) === String(category_level_2)))) : null;
  const navigationLevel_2 = category_level_2 ? navigationLevel_1?.subs?.find((item: any) => String(item?.id) === String(category_level_2)) : null;
  const navigationLevel_3 = category_level_3 ? navigationLevel_2?.subs?.find((item: any) => String(item?.id) === String(category_level_3)) : null;

  const nameMenuActive: any = `${`<span class="text-sub-disabled">${t("Rankify_KR")} </span>`} 
  ${navigationLevel_0 ? `<span class="text-sub-disabled mx-2">|</span><span class=${navigationLevel_1 ? 'text-sub-disabled' : 'text-sub-active'}>${t(navigationLevel_0?.title || "")}</span>` : ''}
  ${navigationLevel_1 && Number(level_sub) >= 2 ? `<span class="text-sub-disabled mx-2">|</span><span class=${navigationLevel_2 && String(level_sub) !== '2' ? 'text-sub-disabled' : 'text-sub-active'}>${navigationLevel_1?.title || ""}</span>` : ''}
  ${navigationLevel_2 && Number(level_sub) >= 3 ? `<span class="text-sub-disabled mx-2">|</span><span class=${navigationLevel_3 ? 'text-sub-disabled' : 'text-sub-active'}>${navigationLevel_2?.title || ""}</span>` : ''}
  ${navigationLevel_3 && Number(level_sub) >= 4 ? `<span class="text-sub-disabled mx-2">|</span><span class="text-sub-active">${t(navigationLevel_3?.title || "")}</span>` : ''}
  `;

  const pathtitle = window.location.pathname.split("/");
  const name = pathtitle[pathtitle.length - 1].split("-");

  function handleActiveWallet() {
    setHeadWallet(!headWallet);
  }
  const goToLogin = () => {
    const currentUrl = window.location.pathname + window.location.search; // Get the current URL
    localStorage.setItem("referralUrl", currentUrl);
    navigate("/login");
  };

  const goToSignUp = () => {
    const currentUrl = window.location.pathname + window.location.search; // Get the current URL
    localStorage.setItem("referralUrl", currentUrl);
    navigate("/sign-up");
  };

  const handleOnClickAccountManagement = (e: any) => {
    if (Number(user?.user?.user_subscription?.subscription?.number_of_accounts) <= 1) {
      e.preventDefault();
      notify('info', t('Account management is available after changing the subscription plan!'));
    }
  }
  const walletActive = window.matchMedia("(max-width:100rem)").matches;

  useEffect(() => {
    if (walletActive) {
      setHeadWallet(true);
    } else {
      setHeadWallet(false);
    }
  }, [walletActive]);

  useEffect(() => {
    const header: any = headerRef.current;
    if (header) {
      const headerHeight = header.offsetHeight;
      document.documentElement.style.setProperty(
        "--header-height",
        `${headerHeight}px`
      );
    }
  }, []);

  return (
    <>
      <div
        className={`header ${path === "dashboard" || path === "index-2" ? "home" : ""
          } ${headerFix ? "is-fixed" : ""}`}
        id="header-page"
      >
        <div className="header-content" ref={headerRef}>
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left"></div>
              <ul className="navbar-nav header-right ">
                {user?.user?.user_subscription?.subscription && <>
                  <li className="nav-item">
                    <Link
                      to={"/my-page"}
                      className="d-flex align-items-center"
                    >
                      <h4 className={`mb-0 fs-14 fw-600 me-3 text-${COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS[Number(user?.user?.user_subscription?.subscription?.plan_type)]}`}
                        style={{
                          paddingLeft: '14px',
                          paddingRight: '14px',
                          borderRadius: '17px',
                          boxSizing: 'border-box',
                          lineHeight: '31px',
                          verticalAlign: 'middle',
                          backgroundColor: `rgba(var(--bs-${COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS[Number(user?.user?.user_subscription?.subscription?.plan_type)]}-rgb), 0.1)`,
                        }}
                      >
                        {PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(user?.user?.user_subscription?.subscription?.plan_type))?.label}{' '}{t('Plan')}
                      </h4>
                    </Link>
                  </li>
                </>}
                <li className="nav-item">
                  <LanguageDropdown />
                </li>

                {!!user?.user?.email ? (
                  <Dropdown as="li" className="nav-item header-profile2">
                    <Dropdown.Toggle
                      href={"" as any}
                      className="nav-link i-false me-0"
                      as="div"
                      aria-expanded="false"
                    >
                      <div className="header-info2 d-flex align-items-center">
                        <AccountCircleIcon
                          sx={{ height: "36px", width: "36px" }}
                        />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      align="end"
                      className="mt-3 dropdown-menu dropdown-menu-right"
                      style={{ width: "300px" }}
                    >
                      <Dropdown.Item className="dropdown-item ai-icon icon-bell-effect d-flex align-items-center">
                        <Link
                          to={"/my-page"}
                          className="d-flex align-items-center w-100"
                          onClick={() => { localStorage.setItem('TAB_ACTIVE_MY_PAGE', 'INFO_ACCOUNT') }}
                        >
                          {SVGICON.ProfileSvgIcon}
                          <div>
                            <div className="ms-2" style={{ fontSize: "13px" }}>
                              <strong>{user?.user?.name || "admin"}</strong>
                            </div>
                            <div className="ms-2" style={{ fontSize: "13px" }}>
                              {user?.user?.email || "rankify@gmail.com"}{" "}
                            </div>
                          </div>
                        </Link>
                      </Dropdown.Item>
                      <hr className="my-2"></hr>
                      {user?.user?.user_subscription?.subscription && <Dropdown.Item className="dropdown-item ai-icon">
                        <Link
                          to={"/my-page"}
                          className="d-flex align-items-center"
                        >
                          <button className="btn p-0" style={{ border: 0 }}>
                            <svg focusable="false" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" data-testid="BookmarkBorderIcon" aria-label="fontSize medium">
                              <path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2m0 15-5-2.18L7 18V5h10z" style={{ fill: `var(--bs-${COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS[Number(user?.user?.user_subscription?.subscription?.plan_type)]})` }}></path>
                            </svg>
                            <span className={`ms-2 text-${COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS[Number(user?.user?.user_subscription?.subscription?.plan_type)]}`} style={{ fontSize: "13px" }}>
                              {TYPE_SUBSCRIPTION_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(user?.user?.user_subscription?.subscription?.type))?.label}
                              {` - `}
                              {PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(user?.user?.user_subscription?.subscription?.plan_type))?.label}{' '}{t('Plan')}
                            </span>
                          </button>
                        </Link>
                      </Dropdown.Item>}

                      {(!!user?.user?.id && !user?.user?.parent_id) && <Dropdown.Item className="dropdown-item ai-icon">
                        <Link
                          to={"/account-management"}
                          className="d-flex align-items-center"
                          onClick={handleOnClickAccountManagement}
                        >
                          <button className="btn p-0" style={{ border: 0 }}>
                            <PeopleOutlineIcon
                              sx={{ width: 24, height: 24 }}
                            />
                            <span className="ms-2" style={{ fontSize: "13px" }}>
                              {t("Account Management")}
                            </span>
                          </button>
                        </Link>
                      </Dropdown.Item>
                      }

                      <Dropdown.Item className="dropdown-item ai-icon">
                        <Link
                          to={"/service-introduction"}
                          className="d-flex align-items-center"
                        >
                          <button className="btn p-0" style={{ border: 0 }}>
                            <ErrorOutlineOutlinedIcon
                              sx={{ width: 24, height: 24 }}
                            />
                            <span className="ms-2" style={{ fontSize: "13px" }}>
                              {t("Service Introduction")}
                            </span>
                          </button>
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item ai-icon">
                        <Link
                          to={"/official-media"}
                          className="d-flex align-items-center"
                        >
                          <button className="btn p-0" style={{ border: 0 }}>
                            <VerifiedOutlinedIcon
                              sx={{ width: 24, height: 24 }}
                            />
                            <span className="ms-2" style={{ fontSize: "13px" }}>
                              {t("Official Media")}
                            </span>
                          </button>
                        </Link>
                      </Dropdown.Item>
                      <Logout />
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <>
                    <ButtonPrimary
                      onClick={goToSignUp}
                      variant="outlined"
                      text={t("Join the Membership")}
                      className="btn-login-on-header me-2 ms-2"
                      style={{
                        minWidth: `${i18n.language === "en" ? 84 : 74}px`,
                      }}
                    />
                    <ButtonPrimary
                      onClick={goToLogin}
                      variant="outlined"
                      text={t("Sign In")}
                      className="btn-login-on-header"
                      style={{
                        minWidth: `${i18n.language === "en" ? 84 : 74}px`,
                      }}
                    />
                  </>
                )}
              </ul>
            </div>
          </nav>
        </div>
        <div className="page-titles mb-0 pt-3 pe-3 pe-md-4 pt-md-5  pb-0 pb-md-3">
          <div className="d-flex align-items-center">
            <h2
              style={{ textTransform: "capitalize" }}
              className={`ms-2 title-page-small`}
              dangerouslySetInnerHTML={{ __html: nameMenuActive || <></> }}
            ></h2>
          </div>
          {path === "" && (
            <ol className="breadcrumb">
              <li className="breadcrumb-item ms-2">
                <div className="text-end" style={{ fontSize: "0.875rem" }}>
                  {moment().subtract(2, "days").format("MM월 DD일")} 기준 랭킹 데이터입니다.
                </div>
              </li>
            </ol>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
