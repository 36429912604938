import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';

interface UseToastNotifications {
    notify: (type: 'success' | 'error' | 'warning' | 'info', message: string) => void;
    ToastContainerComponent: React.FC;
}

const useToastNotifications = (): UseToastNotifications => {
    const notify = (type: 'success' | 'error' | 'warning' | 'info', message: string) => {
        const options: ToastOptions = {
            style: {
                fontFamily: 'Open Sans, sans-serif',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '1.43',
                letterSpacing: '0.01071em',
                color: 'rgba(0, 0, 0, 0.87)',
                backgroundColor: '#fff',
                boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                borderRadius: '10px',
                padding: '12px 16px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '16px',
                borderLeft: type === 'success' ? '1px solid #00ADA3' :
                    type === 'error' ? '1px solid #fd5353' :
                        type === 'warning' ? '1px solid #FFAB2D' :
                            type === 'info' ? '1px solid #3693FF' : 'none'
            },
            bodyClassName: 'toast-message',
            closeButton: (
                <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 4L4 12" stroke="#C7C7C7" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M12 12L4 4" stroke="#C7C7C7" stroke-width="1.5" stroke-linecap="round" />
                </svg>
            )
        };
        toast[type](message, options);
    };

    return {
        notify,
        ToastContainerComponent: () => <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    };
};

export default useToastNotifications;
