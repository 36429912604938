import { formatNumberWithCommas } from 'helpers/format';
import React from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ButtonPrimary from 'ui/components/buttons/ButtonPrimary';
import RadioPrimary from 'ui/components/buttons/RadioPrimary';
import PaymentNotification from 'ui/pages/payment/components/PaymentNotification';

const ModalPurchase = ({ isLoading, chargeInfo = null, setChargeInfo, tickets = [], myTicket = null, isShow = false, onClose, handlePurchase }: any) => {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();

    const onHide = () => {
        onClose && onClose();
    };

    const onPurchase = () => {
        handlePurchase && handlePurchase();
    }

    const onSetChargeInfo = (info: any) => {
        setChargeInfo && setChargeInfo(info);
    }

    return (
        <>
            <Modal id="modal-purchase" className="fade bd-example-modal-lg" show={!!isShow} centered onHide={onHide}>
                <Modal.Header className="border-0 pt-4 pb-2">
                    <Modal.Title className="text-start">
                        {t('Voting Ticket Payment')}
                    </Modal.Title>
                    <svg width="24" height="24" viewBox="0 0 24 24" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onHide}>
                        <path d="M18 18L6 6M18 6L6 18" stroke="#47494E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Modal.Header>
                <Modal.Body className="pb-2 pt-2">
                    <Container className="px-0">
                        {!!chargeInfo?.status ? <PaymentNotification
                            type={chargeInfo?.status === 'SUCCESS' ? 'success' : 'error'}
                            subMessageSuccess={t('Your purchased voting tickets have been credited.')}
                        /> : (
                            <>
                                <Row>
                                    <Col sm="12">
                                        <div className="bg-purple-light d-flex justify-content-between align-items-center flex-row" style={{ borderRadius: '10px', padding: '12px 12px 12px 20px' }}>
                                            <div className="flex-grow-1 d-flex justify-content-between align-items-center">
                                                <div className="">
                                                    <h4 className="mb-0 text-color-1" >{t('Voting rights in possession')}</h4>
                                                </div>
                                                <div className="text-center">
                                                    <h4 className="mb-0 text-color-2" >{formatNumberWithCommas(myTicket?.ticket_amount || 0)} <span className="ms-0 me-3 text-color-3 text-normal" style={{ fontWeight: '200' }}>개</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="d-flex flex-column gap-2 justify-content-start align-items-center mt-4">
                                    {tickets?.map((ticket: any) => (
                                        <div className="d-flex justify-content-center cursor-pointer card-push-point w-100" style={{ padding: '10px 16px', borderRadius: '10px', border: '1px solid #E9EBF1' }} onClick={() => onSetChargeInfo({ ticket_id: ticket?.id })}>
                                            <div className="form-check flex-grow-1 align-self-center ps-1 d-flex align-items-center">
                                                <RadioPrimary text='' checked={String(ticket?.id) === String(chargeInfo?.ticket_id)} />
                                                <label className="form-check-label fs-16 ms-0 mt-0" style={{ paddingTop: '3px' }}>
                                                    {formatNumberWithCommas(ticket?.amount)} 개
                                                </label>
                                            </div>
                                            <ButtonPrimary
                                                variant="contained"
                                                text={`${formatNumberWithCommas(ticket?.price_kr)}원`}
                                                className="py-2"
                                                style={{ maxWidth: '110px' }}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <Row>
                                    <Col sm="12" className="mt-3">
                                        <h5>
                                            {t('Voting Rights Usage Guide')}
                                        </h5>
                                        <ul style={{ fontSize: '12px' }} className="ms-3">
                                            <li className="" style={{ listStyleType: 'disc' }}>
                                                {t('After purchasing voting rights, you can use them within the Rankify service.')}
                                            </li>
                                            <li className="" style={{ listStyleType: 'disc' }}>
                                                {t('The price of the voting rights includes VAT.')}
                                            </li>
                                            <li className="" style={{ listStyleType: 'disc' }}>
                                                {t('Refunds for voting rights are only processed for unused voting rights after confirmation.')}
                                            </li>
                                            <li className="" style={{ listStyleType: 'disc' }}>
                                                {t('If you encounter any issues with the purchase or use of voting rights, please contact us with the relevant screen at the following email:')}
                                            </li>
                                            <li className="" style={{ listStyleType: 'disc' }}>
                                                {t('Inquiry Email')}: <a href="mailto:info@rankify.best" className="text-decoration-underline">info@rankify.best</a>
                                            </li>
                                            <li className="" style={{ listStyleType: 'disc' }}>
                                                {t('We are not responsible for the loss or incorrect use of voting rights due to member negligence.')}
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </>)}
                    </Container>
                </Modal.Body>
                <Modal.Footer className="text-center border-0 pb-4">
                    <ButtonPrimary
                        disabled={isLoading}
                        isLoading={isLoading}
                        onClick={() => { !!chargeInfo?.status ? onHide() : onPurchase() }}
                        variant="contained"
                        text={!!chargeInfo?.status ? t('Close') : t('Purchase')}
                        className="py-3 m-0 fs-16"
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default React.memo(ModalPurchase);