import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import ButtonPrimary from "ui/components/buttons/ButtonPrimary";
import useToastNotifications from 'ui/components/Hooks/useToastNotifications';
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import * as Yup from "yup";
import { deleteAccount, getAccounts as onGetAccounts, postAccount, putAccount } from "../../../../store/thunks";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';


const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';

const AccountManagement = () => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state: any) => state?.Login);
  const { notify } = useToastNotifications();
  const navigate = useNavigate();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 100),
    sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    name: withDefault(StringParam, ''),
    is_active: withDefault(StringParam, ''),
  });

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Account;

  const AccountProperties = createSelector(
    selectLayoutState,
    (state) => ({
      accounts: state.accounts,
      isAccountSuccess: state.isAccountSuccess,
      isAccountLoading: state.isAccountLoading,
      error: state.error,
    })
  );

  const { accounts, isAccountLoading } = useSelector(AccountProperties);

  useEffect(() => {
    dispatch(onGetAccounts(query));
  }, [JSON.stringify(query)]);


  const [detail, setDetail] = useState<any>(null);

  const [isShow, setIsShow] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [itemDelete, setItemDelete] = useState<any | null>(null);

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        name: values?.name,
        email: values?.email,
        password: values?.password,
      };
      const response: any = detail?.id ? await putAccount(detail?.id, data) : await postAccount(data);
      if (response?.code === 200) {
        setIsLoading((_prev) => false);
        setDetail((_prev: any) => null);
        notify('success', `${t("The process has been completed.")}`);
        resetData();
      } else {
        setIsLoading((_prev) => false);
        notify('error', `${response}`);
      }
    } catch (errors: any) {
      setIsLoading((_prev) => false);
      const mess = typeof errors === 'string' ? t(`${errors}`) : Object.entries(errors)?.map(([key, value]) => {
        return t(`${(value as any || []).join('')}`);
      }).join('') || '';
      notify('error', mess);
      return errors;
    }
  };

  const handleActionDelete = async () => {
    if (!itemDelete?.id) { return; };
    try {
      setIsLoading((_prev) => true);
      const response: any = await deleteAccount(Number(itemDelete?.id));
      if (response?.code === 200) {
        dispatch(onGetAccounts(query));
        setIsLoading((_prev) => false);
        setItemDelete((_prev: any) => null);
        notify('success', `${t("The process has been completed.")}`);
        resetData();
      } else {
        setIsLoading((_prev) => false);
        notify('error', `${response}`);
      }
    } catch (errors: any) {
      setIsLoading((_prev) => false);
      const mess = typeof errors === 'string' ? t(`${errors}`) : Object.entries(errors)?.map(([key, value]) => {
        return t(`${(value as any || []).join('')}`);
      }).join('') || '';
      notify('error', mess);
      return errors;
    }
  };

  const resetData = () => {
    dispatch(onGetAccounts({ ...query, time_request: + new Date() }));
  };

  const validationSchema = Yup.object({
    name: (detail?.is_update_info || !detail?.id) ? Yup.string().required(`${t("Please enter a name")}`) : Yup.string().nullable(),
    email: (detail?.is_update_info || !detail?.id) ? Yup.string().email(`${t('Please enter a valid email address')}`).required(`${t('Please enter your email address.')}`) : Yup.string().nullable(),
    password: detail?.is_update_info ? Yup.string().nullable() : Yup.string().required(t('Please enter a password.')), // .min(8, t('At least 8 characters'))
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleSetValueForm = (valueDefault: any) => {
    const v_name = valueDefault?.name;
    const v_email = valueDefault?.email;

    formik.setFieldValue("name", v_name);
    formik.setFieldValue("email", v_email);
    formik.setFieldValue("password", '');
  };

  useEffect(() => {
    setTimeout(() => {
      formik.setErrors({});
    }, 300);
    if (detail?.id) {
      handleSetValueForm(detail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  const handleCreate = () => {
    setDetail((_prev: any) => ({}));
  }

  const handleUpdate = (item: any) => {
    setDetail((_prev: any) => ({ ...item, is_update_info: true }));
  }

  const handleResetPassword = (item: any) => {
    setDetail((_prev: any) => ({ ...item, is_update_info: false }));
  }

  const handleDelete = (item: any) => {
    setItemDelete((_prev: any) => (item));
  }
  const onHide = () => {
    formik.setFieldValue("name", '');
    formik.setFieldValue("email", '');
    formik.setFieldValue("password", '');
    setDetail((_prev: any) => null);
  };

  const onHideDelete = () => {
    setItemDelete((_prev: any) => null);
  };


  useEffect(() => {
    document.title = t("Account Management");
  }, [i18n?.language]);

  useEffect(() => {
    if (!user?.user?.id) {
      navigate('/');
    }
  }, [user?.user]);

  const numberOfAccounts = useMemo(() => {
    return Number(user?.user?.user_subscription?.subscription?.number_of_accounts || 0);
  }, [JSON.stringify(user?.user?.user_subscription?.subscription)]);

  return (
    <>
      <div>
        <Row >
          <Col sm={0} md={2}>

          </Col>
          <Col sm={12} md={8}>
            <h3 className="text-start">{t('Account Management')}</h3>
            <div className="d-flex justify-content-end mt-2 mb-3">
              {(!!user?.user?.id && !user?.user?.parent_id && ((numberOfAccounts > 1) && (numberOfAccounts > accounts?.length))) && <ButtonPrimary
                onClick={() => handleCreate()}
                variant="contained"
                text={t('Create')}
                className="btn-login-on-header"
                style={{
                  maxWidth: `90px`,
                }}
                isIcon={true}
                Icon={<PersonAddAltIcon fontSize="small" className="me-2" />}
              />}
            </div>
            <div>
              <div className="mb-5">
                <div className="card" style={{ minWidth: i18n?.language === 'en' ? '300px' : '250px', boxShadow: '0 4px 14px rgba(194, 199, 226, .2)' }}>
                  <div className="card-body overflow-x-auto">
                    <table id="accountInfo" summary="회원의 이름,이메일,비밀번호 병경, 가입일 정보" data-tab-content="" className="table-account-management w-100">
                      <thead>
                        <tr>
                          <th className="text-start">{t('Name')}</th>
                          <th className="text-start">{t('Account')}</th>
                          <th className="text-end">{t('Created at')}</th>
                          <th className="text-end">{t('Updated at')}</th>
                          <th className="text-end">{t('Action')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {accounts?.map((item: any) => (
                          <tr key={item?.id}>
                            <td className="text-start">{item?.name || ''}</td>
                            <td className="text-start">{item?.email || ''}</td>
                            <td className="text-end">
                              <div className="text-end" style={{ minWidth: '100px' }}>
                                <span><span>{String(item?.created_at || '').split(' ')?.[0] || ''}</span> <br /> <span>{String(item?.created_at || '').split(' ')?.[1] || ''}</span> </span>
                              </div>
                            </td>
                            <td className="text-end">
                              <div className="text-end" style={{ minWidth: '100px' }}>
                                <span><span>{String(item?.updated_at || '').split(' ')?.[0] || ''}</span> <br /> <span>{String(item?.updated_at || '').split(' ')?.[1] || ''}</span> </span>
                              </div>
                            </td>
                            <td className="text-end">
                              <div className="d-flex gap-2 justify-content-end">
                                <Link
                                  to={"#"}
                                  className="btn btn-outline-secondary btn-sm content-icon"
                                  onClick={(e: any) => { e.preventDefault(); handleUpdate(item) }}
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link>
                                <Link
                                  to={"#"}
                                  className="btn btn-outline-warning btn-sm content-icon"
                                  onClick={(e: any) => { e.preventDefault(); handleResetPassword(item) }}
                                >
                                  <i className="fa fa-repeat"></i>
                                </Link>
                                <Link
                                  to={"#"}
                                  className="btn btn-outline-danger btn-sm content-icon"
                                  onClick={(e: any) => { e.preventDefault(); handleDelete(item) }}
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={0} md={2}>

          </Col>
        </Row>
      </div>
      <Modal className="fade bd-example-modal-lg" show={!!detail} centered onHide={onHide}>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header className="border-0 pt-4">
            <Modal.Title>
              {detail?.id ? (detail?.is_update_info ? t('Update') : t('Reset Password')) : t('Create')}
            </Modal.Title>
            <svg width="24" height="24" viewBox="0 0 24 24" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onHide}>
              <path d="M18 18L6 6M18 6L6 18" stroke="#47494E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Modal.Header>
          <Modal.Body className="py-0 pt-2">
            <Container className="px-0">
              <Row>
                <Col sm="12">
                  <div className="card-body p-0">
                    <div className="basic-form">
                      <div className="mb-3 mb-0">
                        <div className="p-1 px-0">
                          {(detail?.is_update_info || !detail?.id) && (
                            <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label required">{t('Name')}</label>
                              <input type="text" className="form-control"
                                name="name"
                                value={formik?.values?.name}
                                onChange={(e) => formik.setFieldValue("name", e?.target?.value || "")}
                                placeholder={t('Please enter a name')}
                                autoComplete="off"
                              />
                              {(formik?.touched?.name && formik?.errors?.name) && (<div className="d-flex justify-content-start align-items-center mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                  <path d="M12 4L4 12" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
                                  <path d="M12 12L4 4" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                                <div className="ms-1">
                                  {formik?.errors?.name || ''}
                                </div>
                              </div>)}
                            </div>
                          )}
                          {(detail?.is_update_info || !detail?.id) && (
                            <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label required">{t('Email')}</label>
                              <input type="text" className="form-control password-style"
                                name="email"
                                value={formik?.values?.email}
                                onChange={(e) => formik.setFieldValue("email", e?.target?.value || "")}
                                placeholder={t('Please enter your email address.')}
                                autoComplete="off"
                              />
                              {(formik?.touched?.email && formik?.errors?.email) && (<div className="d-flex justify-content-start align-items-center mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                  <path d="M12 4L4 12" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
                                  <path d="M12 12L4 4" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                                <div className="ms-1">
                                  {formik?.errors?.email || ''}
                                </div>
                              </div>)}
                            </div>
                          )}
                          {(!detail?.is_update_info || !detail?.id) && (
                            <div className="mb-3 ">
                              <div className="position-relative">
                                <label className="form-label required">{t('Password')}</label>
                                <input
                                  type={isShow ? 'text' : 'password'}
                                  className="form-control password-style"
                                  name="password"
                                  value={formik?.values?.password}
                                  placeholder={t('Please enter a password.')}
                                  autoComplete="off"
                                  onChange={(e) =>
                                    formik.setFieldValue("password", e?.target?.value || "")
                                  }
                                />
                                <span className={`show-pass eye ${isShow ? 'active' : ''} `} onClick={() => setIsShow((prev) => !prev)}>
                                  <i className="fa fa-eye" />
                                  <i className="fa fa-eye-slash" />
                                </span>
                              </div>
                              {(formik?.touched?.password && formik?.errors?.password) && (<div className="d-flex justify-content-start align-items-center mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                  <path d="M12 4L4 12" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
                                  <path d="M12 12L4 4" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                                <div className="ms-1">
                                  {formik?.errors?.password || ''}
                                </div>
                              </div>)}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer className="text-center border-0 pb-4 pt-0">
            <ButtonPrimary
              type="button"
              disabled={isLoading}
              variant="outlined"
              text={t('Cancel')}
              className="py-3 m-0 fs-16 me-3"
              onClick={onHide}
            />
            <ButtonPrimary
              type="submit"
              isLoading={isLoading}
              variant="contained"
              text={detail?.id ? (detail?.is_update_info ? t('Update') : t('Reset Password')) : t('Create')}
              className="py-3 m-0 fs-16"
            />
          </Modal.Footer>
        </form>
      </Modal >
      <Modal className="fade bd-example-modal-lg" show={!!itemDelete} centered onHide={onHideDelete}>
        <Modal.Header className="border-0 pt-4">
          <Modal.Title>
            {t('Delete')}
          </Modal.Title>
          <svg width="24" height="24" viewBox="0 0 24 24" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onHideDelete}>
            <path d="M18 18L6 6M18 6L6 18" stroke="#47494E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </Modal.Header>
        <Modal.Body className="py-0 pt-2">
          <Container className="px-0">
            <Row>
              <Col sm="12">
                <div className="card-body p-0">
                  <h5 className="mb-3">
                    {t("Are you sure you want to delete?")}
                  </h5>
                  <p className="text-muted mb-4">
                    {t(
                      "After confirming the delete action, the data will be permanently removed and cannot be recovered. Do you want to proceed with the delete action."
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="text-center border-0 pb-4 pt-0">
          <ButtonPrimary
            type="button"
            disabled={isLoading}
            variant="outlined"
            text={t('Cancel')}
            className="py-3 m-0 fs-16 me-3"
            onClick={onHideDelete}
          />
          <ButtonPrimary
            type="button"
            isLoading={isLoading}
            variant="contained"
            text={t('Delete')}
            className="py-3 m-0 fs-16"
            onClick={handleActionDelete}
          />
        </Modal.Footer>
      </Modal >
    </>
  );
};

export default AccountManagement;