import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IRanking } from "api/types/_ranking";
import { rankingCategories, rankingKeywords } from "./thunk";
export interface IState {
  ranking: {
    name: string,
    time: number
  }[] | null,

  categories: ResponseData<IRanking[]> & PaginationResponse | null,
  isCategoryLoading: boolean,
  isCategorySuccess: boolean,

  keywords: ResponseData<IRanking[]> & PaginationResponse | null,
  isKeywordLoading: boolean,
  isKeywordSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  ranking: null,

  categories: null,
  isCategoryLoading: false,
  isCategorySuccess: false,

  keywords: null,
  isKeywordLoading: false,
  isKeywordSuccess: false,
  error: {},
};

const RankingSlice = createSlice({
  name: "Ranking",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    //categories
    builder.addCase(rankingCategories.pending, (state: IState, action: any) => {
      state.isCategoryLoading = true
    });
    builder.addCase(rankingCategories.fulfilled, (state: IState, action: any) => {
      state.categories = action.payload.data;
      state.isCategorySuccess = true;
      state.isCategoryLoading = false;
    });
    builder.addCase(rankingCategories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCategorySuccess = false;
      state.isCategoryLoading = false;
    });

    //keywords
    builder.addCase(rankingKeywords.pending, (state: IState, action: any) => {
      state.isKeywordLoading = true
    });
    builder.addCase(rankingKeywords.fulfilled, (state: IState, action: any) => {
      state.keywords = action.payload.data;
      state.isKeywordSuccess = true;
      state.isKeywordLoading = false;
    });
    builder.addCase(rankingKeywords.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordSuccess = false;
      state.isKeywordLoading = false;
    });
  },
});

export const {} = RankingSlice.actions;

export default RankingSlice.reducer;