import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import NoImage from '../../../../../assets/images/no-photo-available.png';

const BoardCaster = ({ keyword_info = {} }: any) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="d-flex flex-w flex-wrap mb-3">
                <div className="d-flex flex-column  gap-2 w-100">
                    <>
                        <div style={{ width: '100%', height: 'auto', maxHeight: '300px' }}>
                            <img alt={keyword_info?.name || ''} loading="lazy" src={keyword_info?.image_url || keyword_info?.image || NoImage}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                    // backgroundColor: '#fff'
                                }}
                            />
                        </div>
                        <div className="w-100">
                            <div>
                                <h3 className="mb-1">
                                    {keyword_info?.name || ''}
                                </h3>
                            </div>
                            <table className="table table table-bordered mb-0">
                                <tbody>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Gender')}</td>
                                        <td className="p-2 text-start">{t(String(keyword_info?.gender || '-').toUpperCase())}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Birthday')}</td>
                                        <td className="p-2 text-start">{keyword_info?.birthday || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Body')}</td>
                                        <td className="p-2 text-start">{keyword_info?.body || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Education')}</td>
                                        <td className="p-2 text-start">{keyword_info?.education || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Debut Date')}</td>
                                        <td className="p-2 text-start">{keyword_info?.debut || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Agency')}</td>
                                        <td className="p-2 text-start">{keyword_info?.agency || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Categories')}</td>
                                        <td className="p-2 text-start">
                                            {Array.isArray(keyword_info?.categories) ? keyword_info?.categories.join(', ') : keyword_info?.categories || ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Activities')}</td>
                                        <td className="p-2 text-start">
                                            {Array.isArray(keyword_info?.activities) ? keyword_info?.activities.join(', ') : keyword_info?.activities || ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Awards')}</td>
                                        <td className="p-2 text-start">
                                            {Array.isArray(keyword_info?.awards) ? keyword_info?.awards.join(', ') : keyword_info?.awards || ''}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}

export default React.memo(BoardCaster);