import React from "react";

const Sort = ({ sort_by = '', order_by = 'DESC', name = '_' }) => {
    return (
        <span>
            {sort_by !== name &&
                <svg width="14" height="14" viewBox="0 0 16 16" className="mb-1 ms-1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 11L8 14L12 11" stroke="#C7C7C7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12 6L8 3L4 6" stroke="#C7C7C7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

            }
            {sort_by === name && (
                order_by === "DESC" ?
                    <i className="fa fa-arrow-down ms-2 fs-12" style={{ opacity: '0.7' }} />
                    :
                    <i className="fa fa-arrow-up ms-2 fs-12" style={{ opacity: '0.7' }} />
            )
            }
        </span>
    );
};

export default React.memo(Sort);
