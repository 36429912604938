import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";

import { IRanking } from "./types/_ranking";
import { PaginationResponse, ResponseData } from "./types/_public";
import { ICategory } from "./types/_category";
const api = new APIClient();

const path = '/ranking';
const rankingApi = {
  rankingCategory(params: any): Promise<AxiosResponse<ResponseData<IRanking[]> & PaginationResponse, any>> {
    const url = `${path}/category/multiple/keyword`
    return api.get(url, params)
  },
  rankingKeywordByCategory(params: any): Promise<AxiosResponse<ResponseData<IRanking[]> & PaginationResponse, any>> {
    const id = params.category_id;
    const paramsClone = structuredClone(params);
    delete paramsClone.category_id;
    const url = `${path}/category/${id}/keyword`
    return api.get(url, paramsClone)
  },
  rankingKeyword(params: any): Promise<AxiosResponse<ResponseData<IRanking[]> & PaginationResponse, any>> {
    const id = params.category_id;
    const paramsClone = structuredClone(params);
    delete paramsClone.category_id;
    const url = `${path}/category/${id}/keyword/chart`
    return api.get(url, paramsClone)
  },

}
export default rankingApi
