import { CATEGORY_GROUP_TYPE, CATEGORY_TYPE, FREQUENCY_TYPE, TYPE_PARTICIPATION_TEMPLATE_IS_VOTE } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import ButtonIconAdd from "ui/components/buttons/ButtonIconAdd";
import ButtonPrimary from "ui/components/buttons/ButtonPrimary";
import CustomSkeleton from "ui/components/loadings/CustomSkeleton";
import RankingChart from "../../RankingChart";
import { Collapse } from "react-bootstrap";

import NoImage from '../../../../../../assets/images/no-photo-available.png';

const Row = ({
    stt = 0,
    isCollapse = true,
    columnTableRanking = [],
    data,
    widthChart,
    categoryType = String(CATEGORY_TYPE.TREND_INDEX),
    categoryGroupType = String(CATEGORY_GROUP_TYPE.PEOPLE),
    frequencyType = String(FREQUENCY_TYPE.DAILY),
    intervalTimeSearch,
    typeShowParticipation = false,
    handleAction,
    handleShowMore,
    handleSetDetail,
    handleKeywordInfo
}: any) => {
    const { t, i18n } = useTranslation();
    const isDark = document.body.getAttribute('data-theme-version') === 'dark';
    const path_full = window.location.pathname;
    const handleActionRow = () => {
        handleAction && handleAction(data);
    };

    const handleDuplicateCategory = (categories: any[] = []) => {
        return categories?.reduce((ob, item) => {
            if (ob?.listId?.includes(item?.name)) {
                return ob;
            }
            return ({ listId: [...ob?.listId, item?.name], listName: [...ob?.listName, item?.name] });
        }, { listId: [], listName: [] });
    };

    const showMore = (keywords: string[], level_sub: number = 1) => {
        handleShowMore && handleShowMore(keywords, level_sub);
    };

    const categories_level_2 = handleDuplicateCategory(data?.sub_category)?.listName || [];
    const categories_level_3 = handleDuplicateCategory(data?.sub_sub_category)?.listName || [];

    const keyword_info: any = data?.keyword_info;

    const showSeeTrend = () => {
        handleSetDetail && handleSetDetail(data);
    };

    const handleSetKeywordInfo = () => {
        handleKeywordInfo && handleKeywordInfo(data)
    };

    const renderTableCell = (key: any, isFixed: boolean = false, index: any, sttRow: number) => {
        switch (key) {
            case 'rank':
                return (
                    <td key={index} className="text-center">
                        <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={60}>
                            <>{sttRow}</>
                        </CustomSkeleton>
                    </td>
                );
            case 'keyword':
                return (
                    <td key={index} className="text-center" translate="yes">
                        <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={60}>
                            {!!keyword_info ? <div style={{ maxWidth: '170px' }} className={`d-flex align-items-center ${keyword_info ? 'justify-content-start' : 'justify-content-center'} cursor-pointer`} onClick={() => !!keyword_info && handleSetKeywordInfo()}>
                                {(keyword_info) &&
                                    <div style={{ width: '60px', height: '60px', borderRadius: '6px', overflow: 'hidden', backgroundColor: '#eaeaea', }} className="me-2">
                                        <img
                                            alt={keyword_info?.name || ''}
                                            loading="lazy" src={keyword_info?.image_url || keyword_info?.image || NoImage}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                objectPosition: 'center',
                                                backgroundColor: '#eaeaea',
                                                // minWidth: '50px'
                                            }}
                                        />
                                    </div>
                                }
                                <div style={{ width: '100px' }} className={keyword_info ? 'text-start' : 'text-center'}>
                                    {data?.keyword?.keyword || ''}
                                </div>
                                <ButtonIconAdd isArrow={true} style={{ maxWidth: '19.1px', minWidth: '19.1px', height: '18.87px' }} className="ms-1" />
                            </div> :
                                <div style={{ minWidth: '100px' }}>
                                    {data?.keyword?.keyword || ''}
                                </div>
                            }
                        </CustomSkeleton>
                    </td>
                );
            case 'user_participation':
                return (
                    (!!typeShowParticipation && (String(frequencyType) === String(FREQUENCY_TYPE.DAILY))) && (intervalTimeSearch === 'D_2') && (
                        <td key={index} className="text-center" translate="yes">
                            <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={60}>
                                <div className="text-center d-flex justify-content-center">
                                    <ButtonPrimary
                                        text={String(typeShowParticipation) === TYPE_PARTICIPATION_TEMPLATE_IS_VOTE ? t('Vote') : t('Survey')}
                                        className="py-1 m-auto btn-font-size-vote"
                                        variant="outlined"
                                        onClick={handleActionRow}
                                        style={{ maxWidth: '92px', float: 'inline-end', maxHeight: '30px' }}
                                    />
                                </div>
                            </CustomSkeleton>
                        </td>
                    )
                );
            case 'category':
                return (
                    (!String(path_full).includes('corporation')) &&
                    <Collapse in={isCollapse || String(path_full).includes('people')}>
                        <td key={index} className="text-center" translate="yes">
                            <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={60}>
                                <div style={{ minWidth: '100px' }}>
                                    {categories_level_2?.map((cate: any, index: number) => (index < 2) ? <Fragment key={index}>{cate} <br /></Fragment> : <Fragment key={index}></Fragment>) || ''}
                                    {categories_level_2?.length > 2 && <span className="text-decoration-underline cursor-pointer fs-12" onClick={() => showMore(categories_level_2, 1)}>{t('Show more')}</span>}
                                </div>
                            </CustomSkeleton>
                        </td>
                    </Collapse>
                );
            case 'subcategory':
                return (
                    (!String(path_full).includes('corporation')) &&
                    <Collapse in={isCollapse || String(path_full).includes('people')}>
                        <td key={index} className="text-center" translate="yes">
                            <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={60}>
                                <div style={{ minWidth: '100px' }}>
                                    {categories_level_3?.map((cate: any, index: number) => (index < 2) ? <Fragment key={index}>{cate} <br /></Fragment> : <Fragment key={index}></Fragment>) || ''}
                                    {categories_level_3?.length > 2 && <span className="text-decoration-underline cursor-pointer fs-12" onClick={() => showMore(categories_level_3, 2)}>{t('Show more')}</span>}
                                </div>
                            </CustomSkeleton>
                        </td>
                    </Collapse>
                );
            case 'index_chart':
                return (
                    <td key={index}>
                        <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={60}>
                            <div className="card-body pt-0 custome-tooltip p-0 text-center d-flex justify-content-center align-items-center" onClick={showSeeTrend}>
                                <div style={{ width: '100px', height: '42px' }} className="cursor-pointer">
                                    <RankingChart data={data?.ranking_changes_chart} name={data?.keyword?.keyword || ''} frequencyType={frequencyType} />
                                </div>
                                <ButtonIconAdd style={{ maxWidth: '19.1px', minWidth: '19.1px', height: '18.87px' }} className="ms-3" />
                            </div>
                        </CustomSkeleton>
                    </td>
                );
            case 'trend_index':
                return (
                    <Collapse in={isCollapse || isFixed}>
                        <td key={index} className="text-end">
                            <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={60}>
                                <div style={{ minWidth: i18n?.language === 'en' ? '100px' : '70px' }}>
                                    {formatNumberWithCommas(data?.index)}
                                </div>
                            </CustomSkeleton>
                        </td>
                    </Collapse>
                );
            case 'stock_index':
                return (
                    (String(categoryType) === String(CATEGORY_TYPE.STOCK)) && (
                        <Collapse in={isCollapse || isFixed}>
                            <td key={index} className="text-end">
                                <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={60}>
                                    <div style={{ minWidth: i18n?.language === 'en' ? '100px' : '70px' }}>
                                        {formatNumberWithCommas(data?.stock_index)}
                                    </div>
                                </CustomSkeleton>
                            </td>
                        </Collapse>
                    )
                );
            case 'activity_index':
                return (
                    (String(categoryGroupType) === String(CATEGORY_GROUP_TYPE.PEOPLE)) && (
                        <Collapse in={isCollapse || isFixed}>
                            <td key={index} className="text-end">
                                <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={60}>
                                    <div style={{ minWidth: i18n?.language === 'en' ? '100px' : '70px' }}>
                                        {formatNumberWithCommas(data?.activity_index)}
                                    </div>
                                </CustomSkeleton>
                            </td>
                        </Collapse>
                    )
                );
            case 'search_index':
                return (
                    <Collapse in={isCollapse || isFixed}>
                        <td key={index} className="text-end">
                            <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={60}>
                                <div style={{ minWidth: i18n?.language === 'en' ? '100px' : '70px' }}>
                                    {formatNumberWithCommas(data?.search_index)}
                                </div>
                            </CustomSkeleton>
                        </td>
                    </Collapse>
                );
            case 'viral_index':
                return (
                    <Collapse in={isCollapse || isFixed}>
                        <td key={index} className="text-end">
                            <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={60}>
                                <div style={{ minWidth: i18n?.language === 'en' ? '90px' : '70px' }}>
                                    {formatNumberWithCommas(data?.viral_index)}
                                </div>
                            </CustomSkeleton>
                        </td>
                    </Collapse>
                );
            case 'news_index':
                return (
                    <Collapse in={isCollapse || isFixed}>
                        <td key={index} className="text-end pe-4">
                            <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={60}>
                                <div style={{ minWidth: i18n?.language === 'en' ? '90px' : '70px' }}>
                                    {formatNumberWithCommas(data?.news_index)}
                                </div>
                            </CustomSkeleton>
                        </td>
                    </Collapse>
                );
            case 'p_index':
                return (
                    !!typeShowParticipation && (
                        <Collapse in={isCollapse || isFixed}>
                            <td key={index} className="text-end pe-4">
                                <CustomSkeleton isDark={isDark} isLoading={!!data?.isLoading} height={60}>
                                    <div style={{ minWidth: i18n?.language === 'en' ? '90px' : '70px' }}>
                                        {formatNumberWithCommas(data?.p_index)}
                                    </div>
                                </CustomSkeleton>
                            </td>
                        </Collapse>
                    )
                );
            default:
                return null;
        }
    };

    return (
        <>
            <tr>
                {columnTableRanking.map((key: any, index: any) => renderTableCell(key?.uuid, key?.isFixed, index, Number(stt)))}
            </tr>
        </>
    );
};

export default React.memo(Row);
