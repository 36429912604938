

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS, PLAN_TYPE_SUBSCRIPTION_OPTIONS, TYPE_SUBSCRIPTION_OPTIONS } from "helpers/constans";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import ButtonPrimary from "ui/components/buttons/ButtonPrimary";
import useToastNotifications from "ui/components/Hooks/useToastNotifications";
import { SVGICON } from "ui/constant/theme";
import LanguageDropdown from "ui/layouts/nav/LanguageDropdown";
import Logout from "ui/layouts/nav/Logout";
import { MenuListBottom } from "ui/layouts/nav/Menu";



const HeaderPage = () => {
  const { t, i18n } = useTranslation();
  const headerRef = useRef(null);
  const [headerFix, setheaderFix] = useState(false);
  const user: any = useSelector((state: any) => state?.Login);
  const navigate = useNavigate();
  const { notify } = useToastNotifications();

  const [searchParams] = useSearchParams();

  const TYPE_SUBSCRIPTION_OPTIONS_LANG = TYPE_SUBSCRIPTION_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
  const PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG = PLAN_TYPE_SUBSCRIPTION_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const selectLayoutState = (state: any) => state.Master;

  const MasterProperties = createSelector(selectLayoutState, (state) => ({
    menu: state.menu,
    isMenuSuccess: state.isMenuSuccess,
    isMenuLoading: state.isMenuLoading,
    menuSideBar: state.menuSideBar,
    error: state.error,
  }));
  const { menuSideBar } = useSelector(MasterProperties);


  function CommanScroll() {
    setheaderFix(window.scrollY > 50);
  }

  useEffect(() => {
    window.addEventListener("scroll", CommanScroll);
    return () => {
      window.removeEventListener("scroll", CommanScroll);
    };
  }, []);

  const goToLogin = () => {
    const currentUrl = window.location.pathname + window.location.search; // Get the current URL
    localStorage.setItem("referralUrl", currentUrl);
    navigate("/login");
  };

  const goToSignUp = () => {
    const currentUrl = window.location.pathname + window.location.search; // Get the current URL
    localStorage.setItem("referralUrl", currentUrl);
    navigate("/sign-up");
  };
  const goToBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1); // Navigate back if there is a history
    } else {
      navigate('/'); // Otherwise, navigate to the homepage
    }
  };

  const handleOnClickAccountManagement = (e: any) => {
    if (Number(user?.user?.user_subscription?.subscription?.number_of_accounts) <= 1) {
      e.preventDefault();
      notify('info', t('Account management is available after changing the subscription plan!'));
    }
  }

  useEffect(() => {
    const header: any = headerRef.current;
    if (header) {
      const headerHeight = header.offsetHeight;
      document.documentElement.style.setProperty(
        "--header-height",
        `${headerHeight}px`
      );
    }
  }, []);


  return (
    <div className="session-header">
      <div className="session-header-left">
        <div className="session-header-left-navigate">
          <Link to="/" onClick={(e: any) => { e.preventDefault(); goToBack(); }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <g clipPath="url(#clip0_1110_8153)">
                <path
                  stroke="#05081C"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 21l-9-9 9-9"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_1110_8153">
                  <path fill="#fff" d="M0 0H24V24H0z"></path>
                </clipPath>
              </defs>
            </svg>
          </Link>
        </div>
        <div className="session-header-left-logo">
          <Link to="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="134"
              height="43"
              fill="none"
              viewBox="0 0 134 43"
              className="d-none d-sm-block"
            >
              <path
                fill="#000"
                fillRule="evenodd"
                d="M2.08 13.608h20.11v11.596a11.93 11.93 0 01-.839 4.398c-1.232 3.106-3.707 5.518-6.795 6.622l-2.214.792c-.26.092-.543.094-.803.004l-2.09-.723c-3.31-1.146-5.878-3.857-6.897-7.284a11.37 11.37 0 01-.472-3.242V13.608zm2.475 2.538v9.625c0 .848.123 1.69.364 2.501.786 2.644 2.768 4.736 5.322 5.62l1.687.584 1.813-.649c2.416-.864 4.353-2.751 5.318-5.182a9.335 9.335 0 00.656-3.44v-9.058H4.555z"
                clipRule="evenodd"
              ></path>
              <path
                fill="#000"
                fillRule="evenodd"
                d="M7.494 18.695h5.738c1.853 0 3.355 1.552 3.355 3.467s-1.502 3.467-3.355 3.467H7.494v-6.934zm2.509 2.592v1.75h3.23c.467 0 .846-.392.846-.875s-.379-.875-.847-.875h-3.23z"
                clipRule="evenodd"
              ></path>
              <path
                fill="#000"
                fillRule="evenodd"
                d="M7.494 23.037h3.638c3.117 0 5.644 2.611 5.644 5.832h-2.509c0-1.79-1.404-3.24-3.135-3.24h-1.13v3.24H7.495v-5.832z"
                clipRule="evenodd"
              ></path>
              <path
                fill="#F6B40C"
                d="M16.525 7.117v1.77H13.75l.418-2.062L12.135 5l-1.957 1.825.418 2.081H7.82v-1.77l-3.42-1.497.608 5.622h14.328l.533-5.64-3.345 1.496z"
              ></path>
              <path
                fill="#000"
                d="M103.083 19.618h3.916l-4.445 10.226c-1.43 3.29-2.722 4.46-5.464 4.46h-1.253v-3.152h1.018c1.39 0 1.802-.376 2.291-1.704l-4.367-9.83h3.956l2.252 5.906 2.096-5.906zM94.495 19.796v2.894h-2.37v7.63h-3.642v-7.63H86.74l-.02-2.894h1.802v-1.01c0-2.16 1.175-3.409 3.408-3.409h2.546v2.834H92.87c-.548 0-.862.317-.862.872v.713h2.487zM81.516 18.29v-3.132h3.603v3.132h-3.603zm-.02 12.03V19.616h3.643V30.32h-3.643zM80.563 30.32h-4.308l-2.644-4.52v4.52H69.97V15.752h3.642v8.066l2.526-4.201h4.093l-3.31 5.113 3.643 5.588zM63.574 19.32c2.448 0 4.073 1.763 4.073 4.478v6.52h-3.643v-6.005c0-1.248-.47-1.862-1.39-1.862-1.136 0-1.782.792-1.782 2.16v5.707H57.19V19.617h2.565l.49 1.11c.783-.892 1.958-1.408 3.329-1.408zM52.169 19.617h2.722v10.702h-2.82l-.313-.753c-.862.713-1.959 1.11-3.193 1.11-3.23 0-5.542-2.379-5.542-5.708 0-3.31 2.311-5.668 5.542-5.668 1.254 0 2.37.416 3.232 1.15l.372-.833zm-3.114 7.808c1.371 0 2.37-1.05 2.37-2.457 0-1.387-.999-2.418-2.37-2.418-1.37 0-2.37 1.03-2.37 2.418 0 1.407 1 2.457 2.37 2.457z"
              ></path>
              <path
                fill="#000"
                fillRule="evenodd"
                d="M29.26 16.667h7.934c2.533 0 4.585 2.077 4.585 4.64 0 2.563-2.052 4.64-4.585 4.64H29.26v-9.28zm3.264 3.303v2.674h4.67c.73 0 1.321-.599 1.321-1.337s-.591-1.337-1.32-1.337h-4.671z"
                clipRule="evenodd"
              ></path>
              <path
                fill="#000"
                fillRule="evenodd"
                d="M29.26 22.645h4.981c4.31 0 7.803 3.535 7.803 7.896H38.78c0-2.537-2.032-4.593-4.539-4.593h-1.717v4.593H29.26v-7.896z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg width="34" height="32" viewBox="0 0 32 50" className="d-block d-sm-none" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.4147H31.1595V31.4844C31.1595 33.8338 30.7181 36.1609 29.8597 38.3371C27.9506 43.177 24.1152 46.9361 19.3312 48.656L15.8996 49.8898C15.4971 50.0345 15.0593 50.0368 14.6553 49.8962L11.4181 48.7697C6.28952 46.9849 2.31022 42.7589 0.730628 37.4197C0.246222 35.7823 0 34.0799 0 32.368V13.4147ZM3.83502 17.3698V32.368C3.83502 33.6887 4.02498 35.0022 4.39871 36.2654C5.61739 40.3847 8.68747 43.6452 12.6443 45.0221L15.2583 45.9318L18.0688 44.9213C21.8123 43.5754 24.8135 40.6339 26.3074 36.8467C26.9791 35.1438 27.3245 33.3228 27.3245 31.4844V17.3698H3.83502Z" fill="#1A1A1C" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.38818 21.3414H17.2793C20.1505 21.3414 22.478 23.7601 22.478 26.7437C22.478 29.7274 20.1505 32.1461 17.2793 32.1461H8.38818V21.3414ZM12.275 25.3805V28.1069H17.2793C18.0038 28.1069 18.5911 27.4966 18.5911 26.7437C18.5911 25.9909 18.0038 25.3805 17.2793 25.3805H12.275Z" fill="#1A1A1C" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.38818 28.1071H14.0241C18.854 28.1071 22.7695 32.1759 22.7695 37.1951H18.8826C18.8826 34.4066 16.7074 32.1462 14.0241 32.1462H12.275V37.1951H8.38818V28.1071Z" fill="#1A1A1C" />
              <path d="M22.3811 3.29944V6.05845H18.0821L18.7299 2.84434L15.5792 0L12.5462 2.84434L13.1941 6.08689H8.89497V3.32788L3.59473 0.99552L4.53699 9.7561H15.6381H26.7391L27.5636 0.967077L22.3811 3.29944Z" fill="#F6B40C" />
            </svg>
          </Link>
        </div>
      </div>
      <div className="session-header-right">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left"></div>
            <ul className="navbar-nav header-right ">
              {user?.user?.user_subscription?.subscription && <>
                <li className="nav-item">
                  <Link
                    to={"/my-page"}
                    className="d-flex align-items-center"
                  >
                    <h4 className={`mb-0 fs-14 fw-600 me-3 text-${COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS[Number(user?.user?.user_subscription?.subscription?.plan_type)]}`}
                      style={{
                        paddingLeft: '14px',
                        paddingRight: '14px',
                        borderRadius: '17px',
                        boxSizing: 'border-box',
                        lineHeight: '31px',
                        verticalAlign: 'middle',
                        backgroundColor: `rgba(var(--bs-${COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS[Number(user?.user?.user_subscription?.subscription?.plan_type)]}-rgb), 0.1)`,
                      }}
                    >
                      {PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(user?.user?.user_subscription?.subscription?.plan_type))?.label}{' '}{t('Plan')}
                    </h4>
                  </Link>
                </li>
              </>}
              <li className="nav-item">
                <LanguageDropdown />
              </li>

              {!!user?.user?.email ? (
                <Dropdown as="li" className="nav-item header-profile2">
                  <Dropdown.Toggle
                    href={"" as any}
                    className="nav-link i-false me-0"
                    as="div"
                    aria-expanded="false"
                  >
                    <div className="header-info2 d-flex align-items-center">
                      <AccountCircleIcon
                        sx={{ height: "36px", width: "36px" }}
                      />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    align="end"
                    className="mt-3 dropdown-menu dropdown-menu-right"
                    style={{ width: "300px" }}
                  >
                    <Dropdown.Item className="dropdown-item ai-icon icon-bell-effect d-flex align-items-center">
                      <Link
                        to={"/my-page"}
                        className="d-flex align-items-center w-100"
                        onClick={() => { localStorage.setItem('TAB_ACTIVE_MY_PAGE', 'INFO_ACCOUNT') }}
                      >
                        {SVGICON.ProfileSvgIcon}
                        <div>
                          <div className="ms-2" style={{ fontSize: "13px" }}>
                            <strong>{user?.user?.name || "admin"}</strong>
                          </div>
                          <div className="ms-2" style={{ fontSize: "13px" }}>
                            {user?.user?.email || "rankify@gmail.com"}{" "}
                          </div>
                        </div>
                      </Link>
                    </Dropdown.Item>
                    <hr className="my-2"></hr>
                    {user?.user?.user_subscription?.subscription && <Dropdown.Item className="dropdown-item ai-icon">
                      <Link
                        to={"/my-page"}
                        className="d-flex align-items-center"
                      >
                        <button className="btn p-0" style={{ border: 0 }}>
                          <svg focusable="false" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" data-testid="BookmarkBorderIcon" aria-label="fontSize medium">
                            <path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2m0 15-5-2.18L7 18V5h10z" style={{ fill: `var(--bs-${COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS[Number(user?.user?.user_subscription?.subscription?.plan_type)]})` }}></path>
                          </svg>
                          <span className={`ms-2 text-${COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS[Number(user?.user?.user_subscription?.subscription?.plan_type)]}`} style={{ fontSize: "13px" }}>
                            {TYPE_SUBSCRIPTION_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(user?.user?.user_subscription?.subscription?.type))?.label}
                            {` - `}
                            {PLAN_TYPE_SUBSCRIPTION_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(user?.user?.user_subscription?.subscription?.plan_type))?.label}{' '}{t('Plan')}
                          </span>
                        </button>
                      </Link>
                    </Dropdown.Item>}

                    {(!!user?.user?.id && !user?.user?.parent_id) && <Dropdown.Item className="dropdown-item ai-icon">
                      <Link
                        to={"/account-management"}
                        className="d-flex align-items-center"
                        onClick={handleOnClickAccountManagement}
                      >
                        <button className="btn p-0" style={{ border: 0 }}>
                          <PeopleOutlineIcon
                            sx={{ width: 24, height: 24 }}
                          />
                          <span className="ms-2" style={{ fontSize: "13px" }}>
                            {t("Account Management")}
                          </span>
                        </button>
                      </Link>
                    </Dropdown.Item>
                    }

                    <Dropdown.Item className="dropdown-item ai-icon">
                      <Link
                        to={"/service-introduction"}
                        className="d-flex align-items-center"
                      >
                        <button className="btn p-0" style={{ border: 0 }}>
                          <ErrorOutlineOutlinedIcon
                            sx={{ width: 24, height: 24 }}
                          />
                          <span className="ms-2" style={{ fontSize: "13px" }}>
                            {t("Service Introduction")}
                          </span>
                        </button>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="dropdown-item ai-icon">
                      <Link
                        to={"/official-media"}
                        className="d-flex align-items-center"
                      >
                        <button className="btn p-0" style={{ border: 0 }}>
                          <VerifiedOutlinedIcon
                            sx={{ width: 24, height: 24 }}
                          />
                          <span className="ms-2" style={{ fontSize: "13px" }}>
                            {t("Official Media")}
                          </span>
                        </button>
                      </Link>
                    </Dropdown.Item>
                    <Logout />
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <>
                  <ButtonPrimary
                    onClick={goToSignUp}
                    variant="outlined"
                    text={t("Join the Membership")}
                    className="btn-login-on-header me-2 ms-2"
                    style={{
                      minWidth: `${i18n.language === "en" ? 84 : 74}px`,
                    }}
                  />
                  <ButtonPrimary
                    onClick={goToLogin}
                    variant="outlined"
                    text={t("Sign In")}
                    className="btn-login-on-header"
                    style={{
                      minWidth: `${i18n.language === "en" ? 84 : 74}px`,
                    }}
                  />
                </>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default HeaderPage;