import React from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'ui/components/buttons/ButtonPrimary';

const ModalGoToLogin = ({ categories = [], isShow = false, onClose, goToLogin }: any) => {
    const { t, i18n } = useTranslation();

    const onHide = () => {
        onClose && onClose();
    };

    const onAction = () => {
        goToLogin && goToLogin();
    };

    return (
        <>
            <Modal id="modal-go-to-login" className="fade bd-example-modal-lg" show={!!isShow} centered >
                <Modal.Header className="border-0 pt-4">
                    <Modal.Title className="text-center m-auto">
                        <div className="text-center">
                            {t('Would you like to log in?')}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-2 pt-2">
                    <Container className="px-0">
                        <Col sm="12" md="12" >
                            <div className="d-flex justify-content-around align-items-center py-2">
                                {t('You must log in to participate in the vote.')}
                            </div>
                        </Col>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="text-center border-0 pb-4 justify-content-center gap-3">
                    <ButtonPrimary
                        onClick={onHide}
                        variant="outlined"
                        text={t('Cancel')}
                        className=" m-0"
                        style={{ maxWidth: '150px', padding: '0.57rem 1rem' }}
                    />
                    <ButtonPrimary
                        onClick={onAction}
                        variant="contained"
                        text={t('Go to log in')}
                        className=" m-0"
                        style={{ maxWidth: '150px', padding: '0.57rem 1rem' }}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default React.memo(ModalGoToLogin);