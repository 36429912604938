import React from 'react';

const ArrowAnimation: React.FC = () => {
    return (
        <div className="arrow-tab-animation arrow-tab-animation-none-md">
            <span className="arrow-tab"> &gt; </span>
            <span className="arrow-tab"> &gt; </span>
            <span className="arrow-tab"> &gt; </span>
        </div>
    );
};

export default React.memo(ArrowAnimation);