
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { FREQUENCY_TYPE } from 'helpers/constans';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';

interface RangeDatePickerNormalProps {
    frequencyTypeSearch: { value: string } | null;
    dateSearch: any;
    handleChangeDatePicker: (dates: [Date | null, Date | null]) => void;
    className?: string,
}

registerLocale("en", en as any);
registerLocale("ko", ko as any);

const RangeDatePickerNormal: React.FC<RangeDatePickerNormalProps> = ({
    frequencyTypeSearch,
    dateSearch = [null, null],
    handleChangeDatePicker,
    className = 'form-control-sm form-height-38',
}) => {
    const { t, i18n } = useTranslation();

    const isWeekly = String(frequencyTypeSearch?.value) === String(FREQUENCY_TYPE.WEEKLY);
    const isMonthly = String(frequencyTypeSearch?.value) === String(FREQUENCY_TYPE.MONTHLY);

    // Calculate the maxDate based on frequency type and today's date
    let maxDate = moment().subtract(2, 'days').toDate();

    if (dateSearch[0]) {
        // If the frequency is weekly, set maxDate to 12 weeks from the start date
        if (isWeekly) {
            maxDate = moment(dateSearch[0]).add(12, 'weeks').toDate();
        }
        // If the frequency is monthly, set maxDate to 6 months from the start date
        else if (isMonthly) {
            maxDate = moment(dateSearch[0]).add(6, 'months').toDate();
        }
        // For other cases, set maxDate to 30 days from the start date
        else {
            maxDate = moment(dateSearch[0]).add(30, 'days').toDate();
        }

        // Compare maxDate with the allowed maximum date (2 days before today)
        // and choose the earlier date to ensure maxDate is not beyond this limit
        const maxAllowedDate = moment().subtract(2, 'days').toDate();
        maxDate = maxDate > maxAllowedDate ? maxAllowedDate : maxDate;
    }


    const getWeekOfMonth = (date: Date) => {
        const startWeek = moment(date).startOf('month').week();
        const currentWeek = moment(date).week();
        return currentWeek - startWeek + 1;
    };

    const getEndWeekOfMonth = (date: Date) => {
        const startWeek = moment(date).startOf('month').week();
        const currentWeek = moment(date).week();
        return currentWeek - startWeek;
    };

    const handleDateChange = (dates: [Date | null, Date | null]) => {
        let [start, end] = dates;

        if (isWeekly) {
            if (start) {
                start = moment(start || '').startOf('isoWeek').toDate();
            }
            if (end) {
                end = moment(end || '').endOf('isoWeek').toDate();
            }
        }
        handleChangeDatePicker([start, end]);
    };

    const handleWeekSelect = (date: Date) => {
        const startOfWeek = moment(date).startOf('isoWeek').toDate();
        const endOfWeek = moment(date).endOf('isoWeek').toDate();
        handleChangeDatePicker([startOfWeek, endOfWeek]);
    };

    const handleFormatDateValue = (dates: [Date | null, Date | null]) => {
        let [start, end] = dates;
        if (isWeekly) {
            const weekOfMonthStartDate = start ? getWeekOfMonth(new Date(start)) : '';
            const weekOfMonthEndDate = end ? getEndWeekOfMonth(new Date(end)) : '';
            return `${start ? `${moment(start).format('YYYY-MM')} ${weekOfMonthStartDate}${t('weeks')}` : ''}  ~  ${end ? `${moment(end).format('YYYY-MM')} ${weekOfMonthEndDate}${t('weeks')}` : ''}`;
        }
        if (isMonthly) {
            return `${start ? moment(start).format('YYYY-MM') : ''}  ~  ${end ? moment(end).format('YYYY-MM') : ''}`;
        } else {
            return `${start ? moment(start).format('YYYY-MM-DD') : ''}  ~  ${end ? moment(end).format('YYYY-MM-DD') : ''}`;
        }
    };

    return (
        <DatePicker
            className={`form-control bt-datepicker fs-14 ${className}`}
            popperClassName="form-control-popper-date-picker-range picker-2-column"
            placeholderText={t('Date')}
            selectedDates={dateSearch[0] && dateSearch[1] ? dateSearch : undefined}
            startDate={dateSearch[0]}
            endDate={dateSearch[1]}
            selectsRange
            dateFormat='yyyy-MM-dd'
            value={handleFormatDateValue(dateSearch)}
            calendarStartDay={1}
            showWeekNumbers={isWeekly}
            weekLabel={t('Week')}
            showMonthYearPicker={isMonthly}
            showFullMonthYearPicker={isMonthly}
            showFourColumnMonthYearPicker={isMonthly}
            isClearable={false}
            monthsShown={isMonthly ? 1 : 2}
            locale={i18n.language === 'ko' ? 'ko' : 'en'}
            onChange={(dates) => handleDateChange(dates as [Date | null, Date | null])}
            // minDate={minDate}
            maxDate={maxDate}
            formatWeekNumber={getWeekOfMonth}
            onWeekSelect={(firstDayOfWeek: Date, weekNumber: number, event: React.SyntheticEvent) => {
                handleWeekSelect(firstDayOfWeek);
            }}
        />
    );
};

export default RangeDatePickerNormal;
