import FormControlLabel from '@mui/material/FormControlLabel';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const Android12Switch = styled(Switch)(({ theme, isHaveIcon = true }: any) => ({
  padding: 8,
  transform: 'translateX(8px)',
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    ...(isHaveIcon ? {
      '&::before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.dark),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        left: 12,
      },
      '&::after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.dark),
        )}" d="M19,13H5V11H19V13Z" /><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.dark),
        )}" d="M13,5V19H11V5H13Z" /></svg>')`,
        right: 12,
      },
    } : {})

  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#fff',
    '&:hover': {
      backgroundColor: alpha('#fff', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#000',
    opacity: 1,
  },
}));

export default function SwitchPrimary({ label, placement = "start", defaultChecked, ...props }: any) {
  return (
    <FormControlLabel
      value={placement}
      labelPlacement={placement}
      control={<Android12Switch {...props} defaultChecked />}
      label={label}
      className="me-0 ms-0"
      sx={{
        '& .MuiFormControlLabel-label': {
          fontSize: '14px',
          transform: 'translateX(8px)'
        }
      }}
    />
  );
}
