
import axios from "axios";
import { LOGIN_TYPE } from "helpers/constans";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginSocial } from "store/thunks";
const NaverCallBack: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { t, i18n } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const state = urlParams.get("state");
  const clientId = process.env.REACT_APP_NAVER_REST_API_KEY || "";
  const clientSecret = process.env.REACT_APP_NAVER_CLIENT_SECRET || "";
  const redirectUri = `${window.location.origin}/navercallback`;
  const verifyKakao = async () => {
    if (!code || !state) {
      return;
    }
    const requestData = new URLSearchParams();
    requestData.append("grant_type", "authorization_code");
    requestData.append("client_id", clientId);
    requestData.append("client_secret", clientSecret);
    requestData.append("code", code);
    requestData.append("redirect_uri", encodeURI(redirectUri));
    requestData.append("state", state);
    try {
      const res_naver = await axios.post("https://nid.naver.com/oauth2.0/token", requestData);
      dispatch(loginSocial({
        token: res_naver?.data?.access_token,
        token_type: res_naver?.data?.token_type,
        platform: LOGIN_TYPE.NAVER
      }
        , navigate, '/'
      ));
    } catch (error) {
      console.log('error', error);
      //window.close();
    }
  };

  useEffect(() => {
    verifyKakao();
  }, []);

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center bg-white">
      <div className="text-center">
        <Spinner size="sm" style={{ color: "#03CF5D" }}></Spinner>
        <div style={{ color: '#0C0C0C' }}>
          {t('Processing authentication information, please wait.')}...
        </div>
      </div>
    </div>
  );
};

export default NaverCallBack;
