import { LOGIN_TYPE } from "helpers/constans";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginSocial } from "store/thunks";

const GoogleCallBack: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { t, i18n } = useTranslation();
  const verifyGoolge = async () => {
    try {
      const fragment = window.location.href.split("#")[1];
      const params = new URLSearchParams(fragment);
      const token = params.get("access_token");
      if (!token) {
        return;
      }
      dispatch(loginSocial({
        token,
        platform: LOGIN_TYPE.GOOGLE
      }
        , navigate, '/'));

    } catch (error) {
      //window.close();
    }
  };
  useEffect(() => {
    verifyGoolge();
  }, []);
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center bg-white">
      <div className="text-center">
        <Spinner size="sm" style={{ color: "#E1E1E1" }}></Spinner>
        <div style={{ color: '#0C0C0C' }}>
          {t('Processing authentication information, please wait.')}...
        </div>
      </div>
    </div>
  );
};

export default GoogleCallBack;
