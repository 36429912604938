import AddIcon from '@mui/icons-material/Add';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';


const CustomizedButtons = styled(Button)(({ variant }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '0.813rem',
  width: '20px',
  padding: '0',
  borderRadius: '0',
  flex: '1 0 auto',
  display: 'grid',
  alignItems: 'center',
  alignContent: 'center',
  fontFamily: [
    'NanumSquare',
    'BlinkMacSystemFont',
  ].join(','),
  ...(variant === 'contained' && {
    backgroundColor: '#878a99',
    borderColor: '#878a99',
    color: '#FFFFFF',
    '&:hover': {
      borderColor: '#878a99',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#878a99',
      borderColor: '#878a99',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem #878a99',
    },
  }),
  ...(variant === 'outlined' && {
    backgroundColor: 'transparent',
    borderColor: '#878a99',
    color: '#878a99',
    '&:hover': {
      borderColor: '#878a99',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      borderColor: '#878a99',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem #878a99',
    },
  }),
}));

export default function ButtonIconAdd({ text, isLoading, variant = 'outlined', isIcon = true, isArrow = false, ...props }: any) {
  return (
    <CustomizedButtons variant={variant} {...props}>
      {isArrow ? <QuestionMarkIcon fontSize="small" sx={{
        fontSize: '11px'
      }} /> :
        <AddIcon fontSize="small" sx={{
          fontSize: '13px'
        }} />
      }
    </CustomizedButtons>
  );
}
