import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient"
import { ResponseData } from "./types/_public";
import { IPaymentProcess, ISurvey, ITicket, IVote } from "./types/_vote";
const api = new APIClient();

const path = '/users/votes';
const voteApi = {
  postVoting(data: any = {}): Promise<AxiosResponse<ResponseData<IVote>, any>> {
    const url = `${path}`
    return api.post(url, data)
  },
  getTickets(params: any = {}): Promise<AxiosResponse<ResponseData<ITicket>, any>> {
    const url = `/users/tickets`
    return api.get(url, params)
  },
  postPaymentProcess(data: any = {}): Promise<AxiosResponse<ResponseData<IPaymentProcess>, any>> {
    const url = `/payment/danal/request`
    return api.post(url, data)
  },
  getSurveyForms(category_id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<ITicket>, any>> {
    const url = `/survey-forms/category/${category_id}`
    return api.get(url, params)
  },
  postSurvey(data: any = {}): Promise<AxiosResponse<ResponseData<ISurvey>, any>> {
    const url = `/users/answers`
    return api.post(url, data)
  },

}
export default voteApi;
