import { createSlice } from "@reduxjs/toolkit";
export interface IState {
  message: {
    type: string,
    content: string,
  }| null,
  error: any,
};

export const initialState: IState = {
  message: null,
  error: {},
};

const MessageSlice = createSlice({
  name: "Message",
  initialState,
  reducers: {
    saveMessageAction(state: any, action : any) {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {saveMessageAction} = MessageSlice.actions;

export default MessageSlice.reducer;