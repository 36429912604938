import React, { useContext, useEffect, useState } from "react";
/// Link
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/system';
import { useNavigate, useSearchParams } from "react-router-dom";


import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { CATEGORY_TYPE, CATEGORY_INDEX_RANK_TYPE, CATEGORY_RANK_TYPE, PATH_CATEGORY_GROUP_TYPE, CATEGORY_GROUP_TYPE } from "helpers/constans";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { toggleMenuLayout } from "store/layouts/thunk";
import { allCategoryMenu as onGetAllCategoryMenu, allMenu as onGetAllMenu, saveMenuSideBar } from "store/thunks";
import ButtonPrimary from "ui/components/buttons/ButtonPrimary";
import { ThemeContext } from "../../../context/ThemeContext";

const CustomListItemButton = styled(ListItemButton)(({ theme, isActive, isActiveText, isActiveStroke = false, isActiveFill = false }) => ({
  borderRadius: '10px',
  marginBottom: '10px',
  color: 'rgb(106, 105, 112)',
  fontSize: '15px',
  // maxHeight: '40px',
  '& .MuiTypography-root': {
    lineHeight: '24px',
    fontSize: '15px',
    verticalAlign: 'middle'
  },
  ...(isActive ? {
    ...(isActiveText ? {} : {
      backgroundColor: 'var(--point)'
    }),
    color: isActiveText ? 'var(--point)' : '#fff',
    ...(!isActiveText ? { boxShadow: '0 6px 10px 0 rgba(2, 2, 32, 0)', } : {}),
  } : {}),
  '& .text-item-menu span': {
    ...(isActive && isActiveText ? { fontWeight: 'bold' } : {})
  },
  '& svg.icon-item-menu path': {
    ...(isActive ? { ...(isActiveFill ? { fill: '#F6B40C' } : {}), ...(isActiveStroke ? { stroke: '#F6B40C' } : {}) } : {})
  },
  '&:hover': {
    ...(!isActiveText && isActive ? { backgroundColor: 'var(--point)', color: '#fff' } : {}),
  },
}));


const SideBar = () => {
  const {
    windowWidth,
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const isDark = document.body.getAttribute('data-theme-version') === 'dark';

  const LIST_MENU_NONE_SUB = ['/', '/service-introduction', '/subscription-introduction', '/official-media'];

  /// Path
  const path_full = window.location.pathname;
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  // const { rank_type } = useParams();

  const { t, i18n } = useTranslation();
  const ref = React.useRef();

  const [searchParams] = useSearchParams();
  const rank_type = searchParams.get('rank_type');
  const is_theme = searchParams.get('is_theme');
  const level_sub = searchParams.get('level_sub');
  const category_id = searchParams.get('category_id');
  const category_level_1 = searchParams.get('category_level_1');
  const category_level_2 = searchParams.get('category_level_2');
  const category_level_3 = searchParams.get('category_level_3');
  const category_level_4 = searchParams.get('category_level_4');

  const [open, setOpen] = React.useState(`${path_full || '/'}`);
  let navigate = useNavigate();

  const linkToRedirect = (category) => {
    const obj = {};
    if (category?.queries?.level_2_category_id && category?.queries?.level_3_category_id) {
      obj.category_level_1 = category?.queries?.level_2_category_id;
      obj.category_level_2 = category?.queries?.level_3_category_id;
    } else if (category?.queries?.level_2_category_id) {
      obj.category_level_1 = category?.queries?.level_2_category_id;
    }
    if (category?.queries?.rank_type) {
      obj.rank_type = category?.queries?.rank_type;
    }
    if (!!category?.level_sub) {
      obj.level_sub = category?.level_sub;
    }
    return `${category?.path}?category_id=${category?.queries?.level_1_category_id}&${new URLSearchParams(obj).toString()}`
  }

  const handleClick = (open_path, cate = '') => {
    setOpen((_prev) => open_path);
    if (LIST_MENU_NONE_SUB?.includes(open_path)) {
      navigate(open_path);
    } else {

      const cate_link = String(cate?.category_group_type) === String(CATEGORY_TYPE.TREND_INDEX) ? ({ ...cate, queries: { ...cate?.queries, rank_type: CATEGORY_RANK_TYPE.POPULAR } }) : { ...cate, queries: { ...cate?.queries, rank_type: CATEGORY_INDEX_RANK_TYPE.TREND_INDEX } };

      const _link = linkToRedirect(cate_link); // String(cate?.category_group_type) === String(CATEGORY_GROUP_TYPE.PEOPLE) ? cate?.subs[0] :
      navigate(_link);
    }
  };

  const dispatch = useDispatch();

  const { sideMenu } = useSelector(state => state.Layout);

  const selectLayoutState = (state) => state.Master;

  const MasterProperties = createSelector(
    selectLayoutState,
    (state) => ({
      menu: state.menu,
      isMenuSuccess: state.isMenuSuccess,
      isMenuLoading: state.isMenuLoading,
      menuNew: state.menuNew,
      isMenuNewSuccess: state.isMenuNewSuccess,
      isMenuNewLoading: state.isMenuNewLoading,
      menuSideBar: state.menuSideBar,
      error: state.error,
    })
  );
  const { menu, menuNew, menuSideBar, isMenuLoading } = useSelector(MasterProperties);

  const [hideOnScroll, setHideOnScroll] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )

  const handleCloseMenu = () => {
    if (windowWidth <= 1024) {
      dispatch(toggleMenuLayout());
    }
  }


  useEffect(() => {
    if (menuNew) {
      const formatSub = (subs, path_parent = '', category_group_type_parent = '', level_sub) => {
        return subs?.map((sub) => (
          sub?.subs?.length ? {
            ...sub,
            title: i18n?.language === 'en' ? sub?.name : sub?.kr_name,
            category_group_type: category_group_type_parent || sub?.group_type,
            path: sub?.name === 'Home' ? '/' : `/${PATH_CATEGORY_GROUP_TYPE[`${sub?.group_type}`]}`,
            level_sub: Number(level_sub || 0) + 1,
            subs: formatSub(sub?.subs, `${path_parent || (PATH_CATEGORY_GROUP_TYPE[`${sub?.group_type}`] ? `/${PATH_CATEGORY_GROUP_TYPE[`${sub?.group_type}`]}` : '')}`, category_group_type_parent || CATEGORY_GROUP_TYPE[String(sub?.name).toUpperCase()], Number(level_sub || 0) + 1),
            ...(!sub?.queries ? {
              queries: {
                level_1_category_id: sub?.id,
              }
            } : {})
          } : {
            ...sub,
            level_sub: Number(level_sub || 0) + 1,
            title: i18n?.language === 'en' ? sub?.name : sub?.kr_name,
            category_group_type: category_group_type_parent || sub?.group_type,
            path: sub?.name === 'Home' ? '/' : `${path_parent || (PATH_CATEGORY_GROUP_TYPE[`${sub?.group_type}`] ? `/${PATH_CATEGORY_GROUP_TYPE[`${sub?.group_type}`]}` : '')}`,
            ...(!sub?.queries ? {
              queries: {
                level_1_category_id: sub?.id,
              }
            } : {})
          }
        ));
      };
      const tmp_menu = formatSub(menuNew);
      dispatch(saveMenuSideBar(tmp_menu));
      // setMenuFullNew((_prev) => tmp_menu)
    }

  }, [JSON.stringify(menuNew), i18n?.language]);

  useEffect(() => {
    dispatch(onGetAllCategoryMenu({ depth: 1 }));
    dispatch(onGetAllMenu());
  }, []);

  React.useEffect(() => {
    const handleClick = (event) => {
      event.stopPropagation();
      if (ref.current && !ref.current.contains(event.target) && window.innerWidth < 800) {
        if (sideMenu) {
          handleCloseMenu();
        }
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, sideMenu]);

  return (
    <div
      ref={ref}
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`dlabnav ${path === "" || path === "dashboard" ? 'follow-info' : ''} ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        } `}
    >
      <div className="menu-scroll">
        <div className="dlabnav-scroll dlabnav-scroll-custom d-flex flex-column justify-content-between px-3">
          <ul className="metismenu flex-grow-1 pt-3 overflow-y-auto" id="menu">
            <List
              sx={{ width: '100%', bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {(menuSideBar || [])?.map((p) => (
                <>
                  {p?.subs?.length ? (
                    <>
                      <CustomListItemButton onClick={(e) => { e.stopPropagation(); handleClick(`${p?.path}`, p) }} sx={{ pl: 2 }} isActiveStroke={true} isActive={String(path_full).includes(`${p?.path}`) || path_full === `${p?.path}`} isActiveText={false}>
                        <ListItemText className="my-0" primary={t(`${p?.title || ''} `)} />
                        {String(path_full).includes(`${p?.path}`) ? <ExpandLess /> : <ExpandMore />}
                      </CustomListItemButton>
                      <Collapse in={String(path_full).includes(`${p?.path}`) && ([String(category_id), String(category_level_1), String(category_level_2)].some(num => p?.subs?.map((p_id) => String(p_id?.id)).includes(num)) || String(category_id) === String(p?.id))} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className="ps-2">
                          {
                            p?.subs?.map((m) => (
                              m?.subs?.length ? (
                                <>
                                  <CustomListItemButton className={String(path_full).includes(`${m?.path}`) && (Number(level_sub) >= Number(m?.level_sub)) && (String(category_level_1) === String(m?.id)) ? 'mb-0' : ''} onClick={(e) => { e.stopPropagation(); handleClick(`${m?.path}`, m) }} sx={{ pl: 2 }} isActiveText={true} isActive={String(path_full).includes(`${m?.path}`) && (Number(level_sub) >= Number(m?.level_sub)) && (String(category_level_1) === String(m?.id))}>
                                    <ListItemText className="text-item-menu my-0" primary={t(`${m?.title || ''}`)} />
                                    {(String(path_full).includes(`${m?.path}`) && (Number(level_sub) >= Number(m?.level_sub)) && (String(category_level_1) === String(m?.id))) ? <ExpandLess /> : <ExpandMore />}
                                  </CustomListItemButton>
                                  <Collapse in={String(path_full).includes(`${m?.path}`) && (Number(level_sub) >= Number(m?.level_sub)) && (String(category_level_1) === String(m?.id))} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding className="ps-2">
                                      {m?.subs?.map((o) => (
                                        <>
                                          <CustomListItemButton className="mb-1 ps-2" key={o?.title} sx={{ pl: 4 }} isActive={String(path_full).includes(`${o?.path}`) && (String(category_level_2) === String(o?.id)) && String(level_sub) !== '2'} isActiveText={true} onClick={(e) => { e.stopPropagation(); handleClick(o?.path, o); handleCloseMenu(); }}>
                                            <ListItemIcon style={{ minWidth: '10px' }}>
                                            </ListItemIcon>
                                            <ListItemText className="text-item-menu my-0" primary={o?.title || ''} />
                                          </CustomListItemButton>
                                        </>
                                      ))}
                                    </List>
                                  </Collapse>
                                </>
                              ) :
                                (<CustomListItemButton className="mb-1 ps-2" key={m?.title} sx={{ pl: 4 }} isActive={String(path_full).includes(`${m?.path}`) && (Number(level_sub) >= Number(m?.level_sub)) && ((String(String(m?.category_group_type) === String(CATEGORY_GROUP_TYPE.CORPORATION) ? category_level_2 : category_level_1) === String(m?.id)) || (!!is_theme && m?.name === 'Theme'))} isActiveText={true} onClick={(e) => { e.stopPropagation(); handleClick(m?.path, m); handleCloseMenu(); }}>
                                  <ListItemIcon style={{ minWidth: '10px' }}>
                                  </ListItemIcon>
                                  <ListItemText className="text-item-menu my-0" primary={m?.title || ''} />
                                </CustomListItemButton>)
                            ))
                          }
                        </List>
                      </Collapse>
                    </>
                  ) : (
                    <CustomListItemButton sx={{ pl: 2 }} isActive={`${path_full}` === `${p?.path}`} isActiveText={false} isActiveFill={true} onClick={(e) => { e.stopPropagation(); handleCloseMenu(); handleClick(p?.path, p); }}>
                      <ListItemText className="my-0" primary={t(`${p?.title || ''} `)} />
                    </CustomListItemButton >
                  )}
                </>
              ))}
            </List>
          </ul>
          <ul className="metismenu metismenu-bottom" id="menu" >
            <ButtonPrimary
              onClick={() => { handleCloseMenu(); handleClick('/subscription-introduction'); }}
              variant="outlined"
              text={t('Subscription')}
              className="py-2 mb-2"
              isIcon={true}
              Icon={<BookmarkBorderIcon fontSize="small" className="me-2" />}
              style={{ fontSize: '14px' }}
            />
            <ButtonPrimary
              onClick={() => { handleCloseMenu(); handleClick('/service-introduction'); }}
              variant="outlined"
              text={t('Service Introduction')}
              className="py-2"
              isIcon={true}
              Icon={<ErrorOutlineOutlinedIcon fontSize="small" className="me-2" />}
              style={{ fontSize: '14px' }}
            />
            <ButtonPrimary
              onClick={() => { handleCloseMenu(); handleClick('/official-media'); }}
              variant="outlined"
              text={t('Official Media')}
              className="py-2 mt-2"
              isIcon={true}
              Icon={<VerifiedOutlinedIcon fontSize="small" className="me-2" />}
              style={{ fontSize: '14px' }}
            />
          </ul>
        </div>
      </div>
    </div >
  );
};

export default React.memo(SideBar);
