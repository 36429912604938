import authApi from "api/authApi";
import { useFormik } from "formik";
import { useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { loginSuccess } from "store/auth/login/reducer";
import useToastNotifications from "ui/components/Hooks/useToastNotifications";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import * as Yup from "yup";
import { IMAGES } from '../../constant/theme';

const VerifyEmail: React.FC = (props) => {
	const { t, i18n } = useTranslation();
	const [isShow, setIsShow] = useState(false);
	const dispatch = useDispatch<any>();
	const navigate = useNavigate();
	const location = useLocation();
	const state = location?.state;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { notify } = useToastNotifications();

	const [query, setQuery] = useQueryParams({
		verify_token: withDefault(StringParam, ''),
	});

	const handleOpenInbox = () => {
		if (!state?.email) {
			return;
		}
		const emailDomain = String(state?.email || '').split('@')[1];
		let inboxUrl = '';
		if (!emailDomain) {
			return;
		}

		switch (emailDomain) {
			case 'gmail.com':
				inboxUrl = 'https://mail.google.com/mail/u/0/#inbox';
				break;
			case 'outlook.com':
			case 'hotmail.com':
				inboxUrl = 'https://outlook.live.com/mail/inbox';
				break;
			case 'yahoo.com':
				inboxUrl = 'https://mail.yahoo.com/d/folders/1';
				break;
			default:
				inboxUrl = `https://mail.google.com/mail/u/0/#inbox`;
				break;
		}

		window.open(inboxUrl, '_blank');
	};

	const handleReSendVerify = async (isShowNotify = true) => {
		if (!state || !!query?.verify_token) {
			return;
		}
		try {
			if (isLoading) {
				return;
			}
			setIsLoading((_prev) => true);
			const data = { ...state };
			const { protocol, hostname } = window.location;
			const domain = `${protocol}//${hostname}`;
			const response: any = await authApi.verifyEmailRequest({
				email: data?.email,
				redirect_url: `${domain}/verify-email`
			});
			setIsLoading((_prev) => false);
			if (response?.code === 200) {
				isShowNotify && notify('success', t('Verification email has been resent. Please check your email.'));
			}
		} catch (errors: any) {
			setIsLoading((_prev) => false);
			const mess = typeof errors === 'string' ? t(`${errors}`) : Object.entries(errors)?.map(([key, value]) => {
				return t(`${(value as any || []).join('')}`);
			}).join('') || '';
			notify('error', mess);
			return errors;
		}
	}

	useEffect(() => {
		const verifyEmailConfirm = async (token: any) => {
			try {
				if (isLoading) {
					return;
				}
				setIsLoading((_prev) => true);
				const response: any = await authApi.verifyEmailConfirm({ verify_token: token });
				setIsLoading((_prev) => false);
				if (response?.code === 200 && !!response?.data) {
					notify('success', t('SignUp success.'));
					let finalLogin: any = response?.data;
					localStorage.setItem("authUser", JSON.stringify(finalLogin));
					dispatch(loginSuccess(finalLogin));
					const referralUrl = localStorage.getItem('referralUrl') || '/';
					localStorage.removeItem('referralUrl'); // Clean up after use
					navigate(referralUrl);
				}
			} catch (errors: any) {
				setIsLoading((_prev) => false);
				const mess = typeof errors === 'string' ? t(`${errors}`) : Object.entries(errors)?.map(([key, value]) => {
					return t(`${(value as any || []).join('')}`);
				}).join('') || '';
				notify('error', mess);
				return errors;
			}
		};
		if (query?.verify_token) {
			verifyEmailConfirm(query?.verify_token);
		} else {
			handleReSendVerify(false);
		}
		return () => {
			setIsLoading((_prev) => false);
		};
	}, [query?.verify_token]);

	useEffect(() => {
		const handleMessage = (event: any) => {
			if (event.data.type === 'CLOSE_WINDOW_VERIFY_EMAIL') {
				window.close();
			}
		};
		window.addEventListener('message', handleMessage);
		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, []);

	return (
		<>
			<div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
				<div className="login-aside text-center  d-flex flex-column flex-row-auto justify-content-center">
					<div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
						<div className="text-center mb-lg-4 mb-2 pt-4 pt-md-0 logo">
							<Link to="/" className="d-block d-md-none" >
								<img src={IMAGES.LogoRankifyDark} alt="logo" style={{ width: 'auto' }} />
							</Link>
							<Link to="/" className="d-none d-md-block">
								<img src={IMAGES.LogoRankifyLight} alt="logo" style={{ width: 'auto' }} />
							</Link>
						</div>
						<h3 className="mb-2 text-white d-none d-md-block">{t('Welcome back!')}</h3>
						<p className="mb-4 d-none d-md-block">{t('Experience various indicators in different fields now')}</p>
					</div>
				</div>
				<div className="container flex-row-fluid d-flex flex-column justify-content-start justify-content-md-center position-relative overflow-hidden p-7 mx-auto">
					<div className="d-flex justify-content-center h-100 align-items-center">
						<div className="authincation-content style-2">
							<div className="row no-gutters">
								<div className="col-xl-12 tab-content">
									<div id="sign-up" className="auth-form tab-pane fade show active  form-validation">
										<div className="text-center mb-4">
											<h3 className="text-center mb-2 text-dark">“{state?.email}”{t('as')}</h3>
											<h3 className="text-center mb-2 text-dark">{t('A verification email has been sent!')}</h3>
										</div>
										<div className="new-account mt-3 mb-5 text-center">
											<p className="font-w400" dangerouslySetInnerHTML={{ __html: t("Click the 'Verify' button in the verification email to proceed with account creation.") }}></p>
										</div>
										<button className="btn btn-block btn-primary" type="button" disabled={isLoading} onClick={handleOpenInbox}>
											<div className="fs-14" style={{ lineHeight: '28px', fontWeight: '500' }}>
												{isLoading ? <Spinner size="sm"></Spinner> : t('Confirm')}
											</div>
										</button>
										<div className="new-account mt-3 text-center">
											<p className="font-w500">{t('If you have not received the verification email,')} <Link className="text-primary" to="/" onClick={(e) => { e?.preventDefault(); handleReSendVerify() }}>{t('click resend')}</Link></p>
										</div>
									</div>
									{/* <div className="d-flex align-items-center justify-content-center">
										<Link to={"#"} className="text-primary">{t('Terms')}</Link>
										<Link to={"#"} className="text-primary mx-5">{t('Plans')}</Link>
										<Link to={"#"} className="text-primary">{t('Contact Us')}</Link>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default VerifyEmail;
